import React from 'react';
import styles from './ReasonCard.module.css';

const ReasonCard = ({ image, title, description }) => {
  return (
    <div className={styles.reasonCard}>
      <img src={image} alt={title} className={styles.reasonImage} />
      <h3 className={styles.reasonTitle}>{title}</h3>
      {description && <p className={styles.reasonDescription}>{description}</p>}
    </div>
  );
};

export default ReasonCard;

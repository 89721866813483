import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

class BannerPrevArrow extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <a
        title="Previous"
        className="slide-arrow prev-arrow slick-arrow"
        onClick={this.props.onClick}
      >
        <i className="fa fa-angle-left" aria-hidden="true"></i>
      </a>
    );
  }
}

export default withTranslation()(withRouter(BannerPrevArrow));

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { getLanguage } from '../../Common/Util';
import ArrowDownImg from '../../assets/images/arrow-down.svg';
import CheveronImg from '../../assets/images/back-chevron.svg';
import closeXImg from '../../assets/images/closeX.svg';
import { branchInstance } from '../../axios/axiosInstance';

class ChooseDelivery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chooseCity: false,
      allStates: [],
      allBranches: [],
      branchTypes: [],
      baseBranches: [],
      overPickupBranch: {},
      deliveryState: '',
      deliveryCode: '',
      deliveryAddress: '',
      errorMessage: '',
      mobileViewAddress: false,
      mobileViewState: true,
    };
  }

  componentDidMount() {
    this.getStates();
    this.getBranches();
  }

  containsLocation = (locations, id) => {
    var i = -0;
    for (i = 0; i < locations.length; i++) {
      if (locations[i].locationId.toString() === id.toString()) return true;
    }
    return false;
  };

  getBranches = () => {
    let lngParam = getLanguage();

    const code = process.env.REACT_APP_COUNTRY_CODE;
    branchInstance.get(`all?countryCode=${code}`).then((response) => {
      const allBranches = response.data.result;

      if (allBranches.length > 0) {
        const locations = [];
        allBranches.forEach((branch) => {
          if (!this.containsLocation(locations, branch.BranchType.BranchTypeID)) {
            locations.push({
              locationId: branch.BranchType.BranchTypeID,
              name: branch.BranchType.Name,
              imageString: branch.BranchType.Attachment.ImageString,
            });
          }
        });
        this.setState({ branchTypes: locations });
        this.setState({ baseBranches: allBranches });
      }

      this.setState({
        allBranches: allBranches,
      });
    });
  };

  getStates = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    branchInstance.get(`deliveryMapping/allUI?countryCode=${code}`).then((response) => {
      const allStates = response.data.result;
      if (allStates.length > 0) {
        this.setState({
          allStates: allStates,
        });
      }
    });
  };

  pickupDeliveryLocationChange(value, code) {
    this.setState({ deliveryState: value });
    this.setState({ deliveryCode: code });
    var hasBranch = false;

    if (this.state.allBranches.length > 0) {
      branchInstance
        .get(`deliveryMapping/branchIdByStateCode?stateCode=${code}`)
        .then((response) => {
          const state = response.data.result;
          const branchId = state[0].toString();

          this.state.allBranches.forEach((branch) => {
            if (branch._id.toString() === branchId) {
              hasBranch = true;
              this.setState({ overPickupBranch: branch });
            }
          });

          if (!hasBranch) {
            this.setState({ overPickupBranch: this.state.allBranches[0] });
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.setState({ chooseCity: false });
        });
    } else {
      this.setState({ chooseCity: false });
    }
  }

  handleDeliverySubmit = (view) => {
    if (this.state.deliveryState === '') {
      return this.setState({ errorMessage: 'Please enter the state' });
    }
    if (view === 'mobile') {
      this.setState({ mobileViewState: false, mobileViewAddress: true });
      return true;
    }

    if (this.state.deliveryAddress === '') {
      return this.setState({ errorMessage: 'Please enter the address' });
    }

    this.state.overPickupBranch.Name = this.state.deliveryState + '-' + this.state.deliveryAddress;
    this.state.overPickupBranch.Address = this.state.deliveryAddress;
    this.state.overPickupBranch.CountryState.StateName = this.state.deliveryState;

    this.props.chooseDeliveryValue(
      this.state.deliveryState,
      this.state.deliveryAddress,
      this.state.deliveryCode,
    );
    this.props.handleChooseDelivery(false, 0);
    this.props.onPickupBranchSelected(this.state.overPickupBranch);
  };

  handleAddress = (e) => {
    this.setState({ deliveryAddress: e.target.value });
    if (e.target.value.length > 0) {
      if (this.state.allBranches.length > 0) {
        const newBaseLocationList = [];
        this.state.allBranches.forEach((branch) => {
          if (branch.Name.toLowerCase().includes(e.target.value.toLowerCase())) {
            newBaseLocationList.push(branch);
          }
        });
        if (newBaseLocationList.length > 0) {
          this.setState({ branchTypes: this.state.branchTypes });
          this.setState({ allBranches: newBaseLocationList });
        }
      }
    } else {
      this.setState({ allBranches: [] });
      this.setState({ allBranches: this.state.baseBranches });
    }
  };

  handleClose = () => {
    this.setState({
      errorMessage: '',
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="chooseDeliverToMePoppup">
          <div className="web-section">
            <div className="top-wrapper">
              <button
                className="back-btn"
                onClick={() => {
                  this.props.handleChooseDelivery(false, 1);
                  this.props.handleDeliveryCollection();
                }}
              >
                <div className="icon">
                  <img src={CheveronImg} alt="" />
                </div>
                {t('Back.1')}
              </button>
              <div className="breadcrumb-c">
                {this.props.popUpAirport === 2 ? (
                  <span>{t('Collect from me.1')}</span>
                ) : (
                  <span>{t('Deliver to me.1')}</span>
                )}
              </div>
            </div>
            <div className="form-wrapper">
              <div className="city">
                <label htmlFor="">{t('City.1')}</label>
                <div
                  className="input"
                  onClick={() => this.setState({ chooseCity: !this.state.chooseCity })}
                >
                  <input
                    type="text"
                    readOnly
                    placeholder="Choose City"
                    defaultValue={this.state.deliveryState}
                  />
                  <div className="action-icon">
                    <img src={ArrowDownImg} alt="" className="def" />
                    <img src={closeXImg} alt="" className="checked" />
                  </div>
                </div>
                {this.state.chooseCity && (
                  <div className="city-dropdown">
                    <ul>
                      {this.state.allStates.map((state, index) => (
                        <li
                          key={index}
                          value={this.state.deliveryState}
                          onClick={() =>
                            this.pickupDeliveryLocationChange(state.StateName, state.StateCode)
                          }
                        >
                          {state.StateName}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className="address">
                <label htmlFor="">{t('Address.1')}</label>
                <div className="input">
                  <input
                    type="text"
                    maxLength={210}
                    placeholder="Write down your address here"
                    defaultValue={this.state.deliveryAddress}
                    onChange={this.handleAddress}
                  />
                </div>
              </div>
              <button className="primary-button" onClick={this.handleDeliverySubmit}>
                {t('Submit.1')}
              </button>
            </div>
          </div>

          {/* mobileView */}
          <div className="mobile-section">
            {this.state.mobileViewState && (
              <div className="choose-city-popup">
                <div className="header-section">
                  <div className="back-btn">
                    <button className="icon">
                      <img
                        src={CheveronImg}
                        alt=""
                        onClick={() => {
                          this.props.handleChooseDelivery(false, 1);
                          this.props.handleDeliveryCollection();
                        }}
                      />
                    </button>
                    {this.props.popUpAirport === 2 ? (
                      <span>{t('Collect from me.1')}</span>
                    ) : (
                      <span>{t('Deliver to me.1')}</span>
                    )}
                  </div>
                  <div className="breadCrumbSection">
                    <ul>
                      <li>
                        <a>{t('Choose City.1')}</a>
                      </li>
                      <li>
                        {t('Your Address.1')} / {t('Location.1')}
                      </li>
                    </ul>
                  </div>
                </div>
                <ul className="list-section">
                  {this.state.allStates.map((state, index) => (
                    <li key={index}>
                      <input
                        type="radio"
                        name="deliverToMe"
                        id={index}
                        key={index}
                        defaultValue={this.state.deliveryState}
                        onClick={() =>
                          this.pickupDeliveryLocationChange(state.StateName, state.StateCode)
                        }
                      />
                      <label htmlFor={index}>
                        <span className="mappingState">{state.StateName}</span>
                        <span className="selectC"></span>
                      </label>
                    </li>
                  ))}
                </ul>
                <div className="footer-btn-section">
                  <button
                    className="primary-button"
                    onClick={() => this.handleDeliverySubmit('mobile')}
                  >
                    {t('Next.1')}
                  </button>
                </div>
              </div>
            )}

            {this.state.mobileViewAddress && (
              <div className="address-popup">
                <div className="header-section">
                  <div className="back-btn">
                    <button
                      className="icon"
                      onClick={() =>
                        this.setState({
                          mobileViewAddress: false,
                          mobileViewState: true,
                        })
                      }
                    >
                      <img src={CheveronImg} alt="" />
                    </button>
                    {this.props.popUpAirport === 2 ? (
                      <span>{t('Collect from me.1')}</span>
                    ) : (
                      <span>{t('Deliver to me.1')}</span>
                    )}
                  </div>
                  <div className="breadCrumbSection">
                    <ul>
                      <li>
                        <a
                          onClick={() => {
                            this.setState({
                              mobileViewState: true,
                              mobileViewAddress: false,
                            });
                          }}
                          style={{ fontSize: '3.88vw', color: '#6B6B6B' }}
                        >
                          {t('Choose City.1')}
                        </a>
                      </li>
                      <li
                        style={{
                          fontSize: '3.88vw',
                          color: '#231F20',
                          fontFamily: 'ProductSansBold',
                        }}
                      >
                        {t('Your Address.1')} / {t('Location.1')}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="address-wrapper">
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    maxLength={210}
                    placeholder="Write down your address here"
                    onChange={(e) => this.setState({ deliveryAddress: e.target.value })}
                  ></textarea>
                </div>
                <div className="footer-btn-section">
                  <button className="primary-button" onClick={() => this.handleDeliverySubmit()}>
                    {t('Continue.1')}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* error modal */}
        <div className="col-xl-3">
          {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
            <Dialog
              open={this.state.errorMessage.length > 0}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
                {'Dollar'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {this.state.errorMessage}
                </DialogContentText>
              </DialogContent>
              <DialogActions style={{ justifyContent: 'center' }}>
                <Button
                  ref={this.props.errorRef}
                  onClick={this.handleClose}
                  variant="outlined"
                  color="error"
                  style={{
                    background: '#E31A37',
                    color: 'white',
                    justifyContent: 'center',
                    width: '100%',
                    margin: '0px 47px',
                  }}
                >
                  {t('OK.1')}
                </Button>
              </DialogActions>
            </Dialog>
          ) : null}
        </div>
      </>
    );
  }
}

export default withTranslation()(ChooseDelivery);

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';

class FindLocation extends Component {
  onFindLocationClick = () => {
    localStorage.setItem('locationid', 'null');
    this.props.history.push('/locations');
    scroll.scrollToTop();
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dollar Car Rental UAE | Rent a Car in Dubai at Best Prices</title>
          <meta
            name="description"
            content="Rent a Car in Dubai & Across UAE with Dollar Car Rental. Explore the best of monthly, weekly, daily & long-term car rentals - and avail big discounts and unmissable offers. "
          ></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental UAE | Rent a Car in Dubai at Best Prices"
          />
          <meta property="og:url" content="https://www.dollaruae.com" />
          <meta
            property="og:description"
            content="Rent a Car in Dubai & Across UAE with Dollar Car Rental. Explore the best of monthly, weekly, daily & long-term car rentals - and avail big discounts and unmissable offers. "
          ></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta
            property="og:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
          <meta
            name="title"
            content="Dollar Car Rental UAE | Rent a Car in Dubai at Best Prices"
          ></meta>
          <meta
            name="description"
            content="Rent a Car in Dubai & Across UAE with Dollar Car Rental. Explore the best of monthly, weekly, daily & long-term car rentals - and avail big discounts and unmissable offers. "
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/"></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental UAE | Rent a Car in Dubai at Best Prices"
          ></meta>
          <meta
            property="og:description"
            content="Rent a Car in Dubai & Across UAE with Dollar Car Rental. Explore the best of monthly, weekly, daily & long-term car rentals - and avail big discounts and unmissable offers. "
          ></meta>
          <meta
            property="og:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta property="twitter:url" content="https://www.dollaruae.com/"></meta>
          <meta
            property="twitter:title"
            content="Dollar Car Rental UAE | Rent a Car in Dubai at Best Prices"
          ></meta>
          <meta
            property="twitter:description"
            content="Rent a Car in Dubai & Across UAE with Dollar Car Rental. Explore the best of monthly, weekly, daily & long-term car rentals - and avail big discounts and unmissable offers. "
          ></meta>
          <meta
            property="twitter:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
        </Helmet>
        <section
          className={
            this.props.isMobileView == false ? 'find-location-section' : 'bg-grey-location'
          }
        >
          <div className="container">
            <div className="location-wrap text-center text-lg-left">
              <div className="location-wrap-left">
                <h1 className="border-botom" style={{ color: 'white' }}>
                  Dollar Car Rental
                </h1>
                <h2 className="border-botom">{t('Your Ultimate Car Rental Destination.1')}</h2>
                <h5> {t('Locations across the UAE.1')}</h5>
              </div>
              <div className="location-wrap-right mt-4 mt-xl-0">
                <a
                  onClick={this.onFindLocationClick}
                  title={t('Find a Location.1')}
                  className="find-location-bttn"
                >
                  {t('Find a Location.1')}
                </a>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default withTranslation()(withRouter(FindLocation));

import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import CopyRightFooter from '../../Common/CopyRightFooter';
import Footer from '../../Common/Footer';
import Header from '../../Common/Header';
import LeaseThankYou from '../Lease/LeaseThankYou';
import CarSelection from './CarSelection';
import EnquiryForm from './EnquiryForm';
import RequiredDocuments from './RequiredDocuments';
import TeacherBenefits from './TeacherBenefits';
import styles from './TeacherPage.module.css';
import WhyRentFromDollar from './WhyRentFromDollar';

const TeacherPage = () => {
  const [thanksPopUp, setThanksPopUp] = useState(false);
  const [isEnquiryFormVisible, setIsEnquiryFormVisible] = useState(false);
  const [formPrefillData, setFormPrefillData] = useState(null);
  const [isMobileView, setIsMobileView] = useState(false);
  const [isTabletView, setIsTabletView] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 576);
      setIsTabletView(window.innerWidth > 576 && window.innerWidth <= 991);
    };

    handleResize(); // Set initial value
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleEnquireNowClick = (vehicle) => {
    setFormPrefillData(vehicle);
    setIsEnquiryFormVisible(true);
  };

  const handleCloseForm = () => {
    setIsEnquiryFormVisible(false);
    setFormPrefillData(null);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dollar Car Rental UAE | Exclusive Offers and Best Deals</title>
        <link rel="canonical" href="https://www.dollaruae.com/teachers-offer" />
        <meta
          name="description"
          content="Explore exciting offers on your next car rental with Dollar Car Rental UAE. With discounts,deals and offers running all year round - we are your ultimate car rental destination."
        ></meta>
        <meta
          property="og:title"
          content="Dollar Car Rental UAE | Exclusive Offers and Best Deals"
        />
        <meta property="og:url" content="https://www.dollaruae.com/teachers-offer" />
        <meta
          property="og:description"
          content="Explore exciting offers on your next car rental with Dollar Car Rental UAE. With discounts,deals and offers running all year round - we are your ultimate car rental destination."
        ></meta>
      </Helmet>
      <Header />
      <div className={styles.teacherPageContainer}>
        <main>
          <title>Teacher Car Rental Offers in Dubai | Best Deals and Discounts across UAE</title>
          <section className={styles.heroSection}>
            <h1 className={styles.heroTitle}>
              Teacher Car Rental Offers In Dubai, UAE
              <span className={styles.heroSubtitle}>Exclusive Rates for Teachers</span>
            </h1>
            <h2>Back to School, Back on the Road</h2>
            <h2>Exclusive Car Rental Rates for Teachers</h2>
            <img
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/68e3317b3ab08c3ff942afa80026869ce022fafe1cf6ccb4c423fd89f7c21196?apiKey=ac85e0a3444a46c7baa2de216eef8e21"
              alt="Teacher driving a car"
              className={styles.heroImage}
            />
            <p className={styles.heroDescription}>
              Teachers Car Rental Discounts and Offers: Suzuki Ciaz AED1,299/Month, Suzuki Baleno
              AED1,399/Month, Nissan Sunny AED1,425/Month, MG5 AED1,475/Month, Mazda CX-3
              AED1,649/Month.
              <br />
              <br />
              Whether you're commuting to work, planning a road trip, or simply need a reliable
              vehicle, our special rates are designed to make your journey more affordable. Thank
              you for all that you do, and enjoy the ride!
            </p>
            <button
              className={styles.enquireButton}
              onClick={() =>
                handleEnquireNowClick({
                  name: 'Suzuki Ciaz',
                  price: '1,299',
                  imageUrl:
                    'https://cdn.builder.io/api/v1/image/assets/TEMP/1fb36229dddc9e836c4dfca23d9befbefa7a8b2aa708a780fe16ab11879da2d8?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
                })
              }
            >
              Enquire Now
            </button>
          </section>

          {isEnquiryFormVisible && (
            <EnquiryForm
              onClose={handleCloseForm}
              prefillData={{
                carName: formPrefillData?.carName || '',
                carImage: formPrefillData?.carImage || '',
                carPrice: formPrefillData?.carPrice || '',
              }}
              setThanksPopUp={setThanksPopUp}
              thanksPopUp={thanksPopUp}
            />
          )}

          {thanksPopUp && <LeaseThankYou handleThankYouPopup={setThanksPopUp} />}
          <CarSelection isMobileView={isMobileView} handleEnquireNowClick={handleEnquireNowClick} />
          <TeacherBenefits isMobileView={isMobileView} />
          <RequiredDocuments isMobileView={isMobileView} />
          <WhyRentFromDollar isMobileView={isMobileView} isTabletView={isTabletView} />
        </main>
      </div>
      {!isMobileView ? <Footer /> : <CopyRightFooter isMobileView={isMobileView} />}
    </>
  );
};

export default TeacherPage;

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import closeImg from '../../assets/images/closeX.svg';
import PromoImg from '../../assets/images/promo.svg';

class AddPromocode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoCode: this.props.promoCode || '',
    };
    this.handlePromoCode = this.handlePromoCode.bind(this);
    this.handleSubmitCode = this.handleSubmitCode.bind(this);
    this.inputFocusRef = React.createRef();
  }

  componentDidMount() {
    if (this.inputFocusRef.current) this.inputFocusRef.current.focus();
  }

  handlePromoCode = (value) => {
    const sanatizedValue = value.trim().replace(/\s+/g, '');
    this.setState({ promoCode: sanatizedValue });
  };

  handleSubmitCode = (e) => {
    this.props.handlePromoCode(this.state.promoCode);
    this.props.handleClose(e);
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <div className="overly-hidden-black-shade"></div>
        <div className="pop-up-small">
          <div className="header">
            <h6>{t(`Add ${!this.props.isShukranId ? 'Promo Code  /' : ''}Shukran Id`)}</h6>
            <div className="close-btn">
              <img src={closeImg} alt="" onClick={this.props.handleClose} />
            </div>
          </div>
          <div className="input-section">
            <div className="input">
              <div className="icon">
                <img src={PromoImg} alt="" />
              </div>
              <input
                type="text"
                ref={this.inputFocusRef}
                value={this.state.promoCode}
                placeholder={`${!this.props.isShukranId ? 'Put your Promo Code / ' : ''}Shukran Id here`}
                maxLength={19}
                minLength={1}
                onChange={(e) => this.handlePromoCode(e.target.value)}
              />
            </div>
            <button className="primary-button" onClick={(e) => this.handleSubmitCode(e)}>
              {t('Submit.1')}
            </button>
          </div>
          <div className="footer-section">
            <p>
              {t(
                `Maximize your savings with our exclusive ${!this.props.isShukranId ? 'promo code! /' : ''} Shukran Id!`,
              )}
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(AddPromocode);

import React from 'react';
import styles from './DocumentSection.module.css';

const DocumentSection = ({ title, documents, note }) => {
  return (
    <div className={styles.documentSection}>
      <h3 className={styles.sectionTitle}>{title}</h3>
      <div className={styles.documentList}>
        {documents.map((doc, index) => (
          <React.Fragment key={index}>
            <div className={styles.documentItem}>
              <img src={doc.icon} alt={doc.text} className={styles.documentIcon} />
              <span className={styles.documentText}>{doc.text}</span>
            </div>
            {index < documents.length - 1 && <div className={styles.documentTextDivider}></div>}
          </React.Fragment>
        ))}
      </div>
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/008293e8bc89d017762bbc8a23b96f829cf431a2e1f3b7efc0366b2a0dfec9a1?apiKey=ac85e0a3444a46c7baa2de216eef8e21"
        alt="Divider"
        className={styles.divider}
      />
      <p className={styles.note}>{note}</p>
    </div>
  );
};

export default DocumentSection;

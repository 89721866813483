import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import StartBooking from '../MonthlySubscription/Tabs/StartBooking';
class MonthlyRentals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerActive: true,
    };
  }
  componentDidMount = () => {
    scroll.scrollToTop();
  };

  handleHeaderActive = (value) => {
    this.setState({ headerActive: value });
  };

  handleBanner = (value) => {};

  render() {
    const { t } = this.props;
    <Helmet>
      <meta charSet="utf-8" />
      <title>Monthly Car Rental - Explore unmissable deals, offers and discounts </title>
      <link rel="canonical" href="www.dollaruae.com/monthly-rentals" />
      <meta
        name="description"
        content="Dollar Car Rental offers best deals on monthly car rentals in Dubai and across UAE. Explore exclusive offers, unmissable deals and book your next rental today."
      ></meta>
      <meta
        property="og:title"
        content="Monthly Car Rental - Explore unmissable deals, offers and discounts "
      ></meta>
      <meta property="og:site_name" content="Dollar Car Rental"></meta>
      <meta property="og:url" content="https://www.dollaruae.com/"></meta>
      <meta
        property="og:description"
        content="Dollar Car Rental offers best deals on monthly car rentals in Dubai and across UAE. Explore exclusive offers, unmissable deals and book your next rental today."
      ></meta>
      <meta property="og:type" content="business.business"></meta>
      <meta
        property="og:image"
        content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
      ></meta>
      <meta
        name="title"
        content="Monthly Car Rental - Explore unmissable deals, offers and discounts "
      ></meta>
      <meta
        name="description"
        content="Dollar Car Rental offers best deals on monthly car rentals in Dubai and across UAE. Explore exclusive offers, unmissable deals and book your next rental today."
      ></meta>
      <meta property="og:type" content="website"></meta>
      <meta property="og:url" content="https://www.dollaruae.com/mont hlyrentals"></meta>
      <meta
        property="og:title"
        content="Monthly Car Rental - Explore unmissable deals, offers and discounts "
      ></meta>
      <meta
        property="og:description"
        content="Dollar Car Rental offers best deals on monthly car rentals in Dubai and across UAE. Explore exclusive offers, unmissable deals and book your next rental today."
      ></meta>
      <meta
        property="og:image"
        content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee08d93356c2d1.jpeg"
      ></meta>
      <meta property="twitter:card" content="summary_large_image"></meta>
      <meta property="twitter:url" content="https://www.dollaruae.com/monthlyrentals"></meta>
      <meta
        property="twitter:title"
        content="Monthly Car Rental - Explore unmissable deals, offers and discounts "
      ></meta>
      <meta
        property="twitter:description"
        content="Dollar Car Rental offers best deals on monthly car rentals in Dubai and across UAE. Explore exclusive offers, unmissable deals and book your next rental today."
      ></meta>
      <meta
        property="twitter:image"
        content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee08d93356c2d1.jpeg"
      ></meta>
    </Helmet>;
    return (
      <>
        {this.state.headerActive && <ListingHeader />}
        <div className="minH100 monthlyrentalPage">
          <div className="bg-grey">
            {/* <section className="position-relative search-widget-module pt-5 pb-4 search-widget-title-show-module"> */}
            {/* <SearchWidgetNew page={"H"} isMonthlyRental={true}/> */}
            {/* </section > */}
            <section className="sort-c1 reservationHeight">
              <div className="inner-wrapper" style={{ background: 'white' }}>
                <div className="container">
                  <div className="row">
                    <div className="col-12">
                      <div className="tab-content " id="nav-tabContent">
                        <StartBooking
                          manageBanner={this.handleBanner}
                          handleHeaderActive={this.handleHeaderActive}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row flex-reversea">
                    <div className="col-sm-12 col-lg-12 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <h1 className="border-botom">{t('Monthly Car Rentals.1')}</h1>
                        <h2 className="border-botom">{t('Book your wheels.1')}</h2>
                        {/* <p>Already got your Dollar Car Reserved?</p> */}
                        <p className="text_p mt-2 text-black">
                          {t(
                            'Save more when you choose monthly car rentals over daily & weekly rentals. With our easy & flexible payment modes, enjoy monthly prices cheaper than conventional car purchasing budgets.1',
                          )}
                        </p>
                        <p className="text_p mt-2 text-black">
                          {t(
                            'Dollar promises great prices for your choice of dream car, without the hassles of frequent paperwork, maintenance & long-term financial commitments.1',
                          )}
                        </p>
                        <p className="text_p mt-2 text-black">
                          {t(
                            'Instead, reap the benefits of up-to-date vehicle performance, fuel economy, 24x7 dedicated premium support, roadside & emergency assistance for the best prices in the UAE.1',
                          )}
                        </p>
                        <p className="text_p mt-2 text-black">
                          {t(
                            'Perfect for new residents, young professionals, and working families.1',
                          )}
                        </p>
                        <p className="text_p mt-2 text-black">
                          {t(
                            'Book Now or Contact us to know more or better yet visit any of our neighborhood locations to experience Dollar today.1',
                          )}
                        </p>
                        <span></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="box-shadow-white-box-section grey-bg pb-4">
            <div className="container-fluid">
              <div className="box-shadow-white-box">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="p-3 mr-6">
                      <h5 className="font-weight-bold">{t('Benefits Of Monthly Rentals.1')}</h5>
                      <br />
                      <ul className="check-box-listing round-checkmark-wrap">
                        <li>
                          <span>
                            <strong>{t('ALL-INCLUSIVE.1')}</strong>
                            <br />
                            <br />
                            {t(
                              'No more lengthy paperwork, insurances, maintenance & services. It’s all on us.1',
                            )}
                            <br />
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            <strong> {t('NO COMMITMENTS.1')} </strong>
                            <br />
                            <br />
                            {t('Stop. Pause. Restart. No questions asked. No strings attached.1')}
                            <br />
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            <strong>{t('GRADE AS YOU LIKE.1')}</strong>
                            <br />
                            <br />
                            {t(
                              'Upgrade, downgrade or continue with your choice of wheels as you like.1',
                            )}
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            <strong>{t('SAVE MORE.1')}</strong>
                            <br />
                            <br />
                            {t(
                              'Our smart, practical plans help you make the wise choice of renting a vehicle without the financial commitments of owning one.1',
                            )}
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            <strong>{t('HASSLE-FREE ONBOARDING.1')}</strong>
                            <br />
                            <br />
                            {t('One-time verifications for all rental activities.1')}
                          </span>
                        </li>
                        <br />
                        <li>
                          <span>
                            <strong>{t('KEY IN & GO.1')}</strong>
                            <br />
                            <br />
                            {t('Best car reserved for you or choose your own.1')}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(MonthlyRentals);

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

class SpecialOfferBigTile extends Component {
  constructor(props) {
    super(props);
  }

  onSpecialOfferLinkClick = (ID) => {
    this.props.history.push(`/offers/${ID}`);
  };

  render() {
    return (
      <div className="special-offer-big special-offer-box-wrap">
        <a href="#" className="special-offer-link"></a>
        {this.props.bigSpecialOffer.FavouriteNote != '' ? (
          <div className="discount-sticker">{this.props.bigSpecialOffer.FavouriteNote}</div>
        ) : (
          ''
        )}
        <div
          className="special-offer-image"
          style={{
            background: `url(${this.props.bigSpecialOffer.ThumbnailAttachment != null ? this.props.bigSpecialOffer.ThumbnailAttachment.ImageString : ''})`,
          }}
        ></div>
        <a
          className="special-offer-link"
          onClick={() => this.onSpecialOfferLinkClick(this.props.bigSpecialOffer.SpecialOfferID)}
        >
          <figcaption className="special-offer-figcaption">
            <p className="special-offer-content">
              <i className="">
                {this.props.bigSpecialOffer != null &&
                this.props.bigSpecialOffer.Description != null
                  ? this.props.bigSpecialOffer.Description.substring(0, 198)
                  : ''}
              </i>
              <i className="fa fa-angle-right" aria-hidden="true"></i>
            </p>
          </figcaption>
        </a>
      </div>
    );
  }
}

export default withTranslation()(withRouter(SpecialOfferBigTile));

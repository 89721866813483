import success from './assets/images/lease-to-town/form/success.jpg';
import bgThumb from './assets/images/lease-to-town/hro-bg/bg-thump.png';
import nextButton from './assets/images/lease-to-town/th-f4/next.svg';
import prevButton from './assets/images/lease-to-town/th-f4/prev.svg';
import thump1 from './assets/images/lease-to-town/th-f4/thump-1.svg';
import thump2 from './assets/images/lease-to-town/th-f4/thump-2.svg';
import thump3 from './assets/images/lease-to-town/th-f4/thump-3.svg';
import thump4 from './assets/images/lease-to-town/th-f4/thump-4.svg';
import thump5 from './assets/images/lease-to-town/th-f4/thump-5.svg';
import thump6 from './assets/images/lease-to-town/th-f4/thump-6.svg';
import thump7 from './assets/images/lease-to-town/th-f4/thump-7.svg';
import thump8 from './assets/images/lease-to-town/th-f4/thump-8.svg';
import thump9 from './assets/images/lease-to-town/th-f4/thump-9.svg';

export const images = {
  bgThumb,
  success,
  thump1,
  thump2,
  thump3,
  thump4,
  thump5,
  thump6,
  thump7,
  thump8,
  thump9,
  nextButton,
  prevButton,
};

export const tableData = [
  { feature: 'Initial Cash Outflow/Down Payment', bankFinance: '20%', dollarCarRental: 'Zero' },
  { feature: 'Processing Fee', bankFinance: '1.05%', dollarCarRental: 'Zero' },
  { feature: 'Monthly EMI’s', bankFinance: 'Higher', dollarCarRental: 'Lower' },
  { feature: 'Maintenance Cost', bankFinance: 'Customer to Pay', dollarCarRental: 'Free' },
  { feature: 'Registration Cost', bankFinance: 'Customer to Pay', dollarCarRental: 'Free' },
  { feature: 'Insurance Cost', bankFinance: 'Customer to Pay', dollarCarRental: 'Free' },
  { feature: 'Financial Risk', bankFinance: 'High', dollarCarRental: 'Moderate' },
  { feature: 'Vehicle Ownership', bankFinance: 'Mandatory', dollarCarRental: 'Optional' },
  { feature: 'Replacement Vehicle', bankFinance: 'No', dollarCarRental: 'Optional' },
  { feature: 'Financial Charges / Interest', bankFinance: '3-5%', dollarCarRental: 'Zero' },
  { feature: 'Breakdown Assistance', bankFinance: 'No', dollarCarRental: 'Free' },
];

export const options = [
  {
    value: '+971',
    label: 'UAE (+971)',
    flag: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/ae.svg',
  },
  {
    value: '+1',
    label: 'USA (+1)',
    flag: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/us.svg',
  },
  {
    value: '+44',
    label: 'UK (+44)',
    flag: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/gb.svg',
  },
  {
    value: '+91',
    label: 'Ind (+91)',
    flag: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/in.svg',
  },
  {
    value: '+61',
    label: 'Aus (+61)',
    flag: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/au.svg',
  },
  {
    value: '+81',
    label: 'Jap (+81)',
    flag: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/jp.svg',
  },
  // Add more countries as needed
];

export const countriesOptions = [
  { value: 'AUH', label: 'Abu Dhabi' },
  { value: 'AJM', label: 'Ajman' },
  { value: 'AIN', label: 'Al Ain' },
  { value: 'DXB', label: 'Dubai' },
  { value: 'FUJ', label: 'Fujairah' },
  { value: 'RAK', label: 'Ras Al Khaimah' },
  { value: 'SHJ', label: 'Sharjah' },
];

import axios from 'axios';
import hoistStatics from 'hoist-non-react-statics';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import VehicleClassifications from '../Hire/VehicleClassifications';
import { bookingInstance, branchInstance } from '../axios/axiosInstance';

// Brand details object based on Excel data
const brandDetails = {
  audi: {
    h1: 'Audi Rentals in Dubai, UAE',
    title: 'Audi Rent in Dubai | Best Audi Rental Offers in Dubai | Dollar UAE',
    description:
      'Rent an Audi in Dubai for luxury and performance. Enjoy hassle-free booking, best prices, and 24/7 delivery. Experience the thrill of driving an Audi in the UAE.',
  },
  bmw: {
    h1: 'BMW Rental Dubai, UAE',
    title: 'Rent BMW in Dubai | Best Rates and Easy Booking | Dollar UAE',
    description:
      'Rent a BMW in Dubai at best affordable prices with Dollaruae. Get best deals and offers for BMW car rental in Dubai, UAE for daily, weekly or monthly rental as per your needs.',
  },
  chevrolet: {
    h1: 'Rent Chevrolet Car in Dubai, UAE',
    title:
      'Chevrolet Car Rental in Dubai | Beast Deals and Offers to rent Chevrolet in Dubai | Dollar UAE',
    description:
      'Rent Chevrolet car in Dubai at cheap affordable prices with Dollaruae. Get best deals and offers to hire a Chevrolet car in Dubai and across UAE for daily, weekly or monthly rental as per your needs.',
  },
  dodge: {
    h1: 'Dodge Car Rent in Dubai, UAE',
    title: 'Rent Dodge in Dubai | Hire Dodge Challenger in Dubai | Dollar UAE',
    description:
      'Rent a Dodge in Dubai with Dollaruae for an unforgettable driving experience. Enjoy reliable car hire services and explore the city in style and comfort.',
  },
  ford: {
    h1: 'Ford Rental in Dubai, UAE',
    title: 'Rent Ford Car in Dubai | Best Ford Rental Offers in Dubai | Dollar UAE',
    description:
      'Rent a Ford car in Dubai at best affordable prices with Dollaruae. Get best deals and offers for Ford rental in Dubai, UAE for daily, weekly or monthly rental as per your needs.',
  },
  gmc: {
    h1: 'Rent GMC Car in Dubai, UAE',
    title: 'GMC Rental in Dubai | Hire a GMC Car in Dubai | Dollar UAE',
    description:
      'Rent a GMC in Dubai at affordable prices with Dollaruae. Get best deals and offers to hire GMC cars in Dubai, UAE for daily, weekly or monthly rental as per your needs.',
  },
  honda: {
    h1: 'Honda Rental in Dubai, UAE',
    title: 'Rent Honda Car in Dubai | Best Honda Car Rental in Dubai | Dollar UAE',
    description:
      'Rent a Honda in Dubai at cheap affordable prices with Dollaruae. Get best deals and offers for Honda rental in Dubai and across UAE for daily, weekly or monthly rental as per your needs.',
  },
  hyundai: {
    h1: 'Rent Hyundai in Dubai, UAE',
    title: 'Rent Hyundai in Dubai | Best Hyundai Car Rental in Dubai | Dollar UAE',
    description:
      'Rent Hyundai in Dubai at cheap affordable prices at Dollaruae. Get best deals and offers for Hyundai rental in Dubai and across UAE for daily, weekly or monthly rental as per your needs.',
  },
  infiniti: {
    h1: 'Infiniti Rental in Dubai, UAE',
    title: 'Rent Infiniti Car in Dubai | Best Infiniti Rental Offers in Dubai | Dollar UAE',
    description:
      'Rent a Infiniti in Dubai at cheap affordable prices with Dollaruae. Get best deals and offers for Infiniti rental in Dubai, UAE for daily, weekly or monthly rental as per your needs.',
  },
  jeep: {
    h1: 'Jeep Rental Dubai, UAE',
    title: 'Rent Jeep in Dubai | Jeep Wrangler Rental in Dubai | Dollar UAE',
    description:
      'Rent a Jeep in Dubai at cheap affordable prices with Dollaruae. Get best deals and offers for Jeep Wrangler rental in Dubai, UAE for daily, weekly or monthly rental as per your needs.',
  },
  kia: {
    h1: 'Kia Car Rental Dubai, UAE',
    title: 'Rent Kia in Dubai | Best Rates and Affordable Prices | Dollar UAE',
    description:
      'Looking for Kia rental in Dubai? Hire your car from Dollaruae for the best deals, seamless booking, and reliable service in the UAE.',
  },
  lexus: {
    h1: 'Lexus Car Rental Dubai, UAE',
    title: 'Rent Lexus in Dubai | Best Rates and Affordable Prices | Dollar UAE',
    description:
      'Looking for Lexus rental in Dubai? Hire your car from Dollaruae for the best deals, seamless booking, and reliable service in the UAE.',
  },
  mazda: {
    h1: 'Mazda Car Rental Dubai, UAE',
    title: 'Rent Mazda in Dubai | Best Rates and Affordable Prices | Dollar UAE',
    description:
      'Looking for Mazda rental in Dubai? Hire your car from Dollaruae for the best deals, seamless booking, and reliable service in the UAE.',
  },
  mercedes: {
    h1: 'Mercedes Car Rental Dubai, UAE',
    title: 'Rent Mercedes in Dubai | Beast Deals and Offers | Dollar UAE',
    description:
      'Rent a Mercedes in Dubai with Dollar UAE for unmatched luxury. Enjoy top-tier car hire services and affordable rental rates for a seamless driving experience.',
  },
  mg: {
    h1: 'Rent MG Car in Dubai, UAE',
    title: 'MG Car Rental in Dubai | Flexible and Affordable Prices | Dollar UAE',
    description:
      'Rent an MG car in Dubai with Dollar UAE for unbeatable prices. Hire your ideal car rental today and enjoy a smooth driving experience in the UAE',
  },
  mini: {
    h1: 'Rent Mini Cooper in Dubai, UAE',
    title: 'Mini Cooper Rental in Dubai | Flexible and Affordable Rates | Dollar UAE',
    description:
      'Experience the thrill of a Mini Cooper rental in Dubai with Dollar UAE. Hire your perfect car for the city and enjoy affordable rates with seamless booking.',
  },
  mitsubishi: {
    h1: 'Rent a Mitsubishi in Dubai, UAE',
    title: 'Mitsubishi Rental in Dubai | Best Rates Guaranteed! at Dollar UAE',
    description:
      'Experience luxury with Mitsubishi rental in Dubai. Hire premium cars with Dollar UAE for affordable rates and seamless booking for your next adventure.',
  },
  nissan: {
    h1: 'Nissan Car Rental in Dubai, UAE',
    title: 'Rent Nissan in Dubai | Best Affordable Prices | Dollar UAE',
    description:
      'For reliable Nissan rental in Dubai, hire from Dollar UAE. Experience top-notch service and affordable rates for your car rental needs in the UAE',
  },
  peugeot: {
    h1: 'Rent a Peugeot Car in Dubai, UAE',
    title: 'Peugeot Car Rental in Dubai | Top Car Hire Deals with Dollar UAE',
    description:
      'Rent a Peugeot in Dubai with Dollar UAE for a smooth driving experience. Enjoy affordable hire and rental options with easy booking and reliable service.',
  },
  polestar: {
    h1: 'Rent Polestar in Dubai, UAE',
    title: 'Rent Polestar in Dubai | Best Rates Affordable Prices | Dollar UAE',
    description:
      'Rent a Polestar in Dubai with Dollaruae for a premium experience. Hire your electric car today with affordable rental rates and seamless booking options.',
  },
  renault: {
    h1: 'Renault Car Rental in Dubai, UAE',
    title: 'Rent a Renault Car in Dubai | Best Rental Offers | Dollar UAE',
    description:
      'Rent a Renault in Dubai for a smooth ride with Dollaruae. Choose the best rental and hire deals with affordable rates and convenient booking options.',
  },
  suzuki: {
    h1: 'Rent a Suzuki Car in Dubai, UAE',
    title: 'Rent Suzuki Car in Dubai | Best Deals and Offers | Dollar UAE',
    description:
      'Experience Suzuki rental in Dubai with Dollar UAE. Hire your ideal Suzuki for comfort and convenience. Explore the city with affordable rates today!',
  },
  toyota: {
    h1: 'Toyota Rental in Dubai, UAE',
    title: 'Rent Toyota in Dubai | Flexible and Affordable Prices | Dollar UAE',
    description:
      'Experience convenience with Toyota rental in Dubai. Hire from Dollar UAE for reliable vehicles and great deals to explore the UAE hassle-free.',
  },
  volkswagen: {
    h1: 'Volkswagen Rental in Dubai, UAE',
    title: 'Rent Volkswagen in Dubai | Best Rates Guaranteed | Dollar UAE',
    description:
      'Volkswagen rental in Dubai offers a range of stylish and reliable vehicles to suit your needs. With competitive rates and excellent service, your journey in the UAE starts here with Dollar UAE.',
  },
  volvo: {
    h1: 'Rent a Volvo Car in Dubai, UAE',
    title: 'Volvo Car Rental in Dubai | Flexible and Affordable Prices | Dollar UAE',
    description:
      'Rent a Volvo in Dubai with Dollar UAE for a premium driving experience. Hire your perfect vehicle today and enjoy seamless rental services across the city!',
  },
};

class CarBrands extends Component {
  state = {
    vehicalModels: [],
    PickUpDate: moment().format('MM/DD/YYYY h:mm:ss A'),
    ReturnDate: moment().format('MM/DD/YYYY h:mm:ss A'),
    BranchId: '',
    h1: '',
    title: '',
    description: '',
    iPAddress: '127.0.0.1',
    customerCountryCode: 'UAE',
    customerCountryName: 'United_Arab_Emirate',
    brand: 'audi',
  };

  componentDidMount() {
    this.initPageData();
  }

  initPageData = async () => {
    scroll.scrollToTop();
    const brand = this.getBrandFromPath();
    this.setBrandDetails(brand);
    await this.fetchIPAddress();
    this.fetchBranches();
    this.fetchVehicleModels(brand);
  };

  getBrandFromPath = () => {
    const { pathname } = this.props.location;
    const brandSegment = pathname.split('/')[2];
    return brandSegment.split('-')[0].toLowerCase() || 'audi';
  };

  setBrandDetails = (brand) => {
    const brandData = brandDetails[brand] || brandDetails['audi'];
    this.setState({
      h1: brandData.h1,
      title: brandData.title,
      description: brandData.description,
    });
  };

  fetchIPAddress = async () => {
    try {
      const res = await axios.get('https://geolocation-db.com/json/');
      this.setState({
        iPAddress: res.data.IPv4,
        customerCountryCode: res.data.country_code,
        customerCountryName: res.data.country_name,
      });
    } catch {
      // Handle error if needed
    }
  };

  fetchBranches = async () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    try {
      const response = await branchInstance.get(`all?countryCode=${code}`);
      const branches = response.data.result;
      if (branches.length) {
        this.setState({ BranchId: branches[0]._id });
        this.fetchHoursToReady(branches[0]._id);
      }
    } catch {
      // Handle error if needed
    }
  };

  fetchHoursToReady = async (branchId) => {
    try {
      const response = await branchInstance.get(
        `/hrsToGetReady?branchId=${branchId}&isDeliverToMe=${false}`,
      );
      const timeReady = response.data.result;
      this.setState({
        PickUpDate: moment.tz(timeReady.PickupOn, 'Asia/Dubai').format('MM/DD/YYYY h:mm:ss A'),
        ReturnDate: moment.tz(timeReady.ReturnOn, 'Asia/Dubai').format('MM/DD/YYYY h:mm:ss A'),
      });
    } catch {
      // Handle error if needed
    }
  };

  fetchVehicleModels = async (brand) => {
    const countryCode = process.env.REACT_APP_COUNTRY_CODE;
    const searchParam = { Classifications: [brand] };
    try {
      const response = await bookingInstance.post(
        `classificationReservation?countryCode=${countryCode}`,
        searchParam,
      );
      this.setState({ vehicalModels: response.data.result.data });
    } catch {}
  };

  handleBookNow = () => {
    const {
      PickUpDate,
      ReturnDate,
      BranchId,
      iPAddress,
      customerCountryCode,
      customerCountryName,
    } = this.state;
    const BookingInfo = [
      {
        CheckOutLocationId: BranchId,
        CheckInLocationId: BranchId,
        PickUpDate: moment(PickUpDate).format('DD MMM YYYY, h:mm A'),
        ReturnDate: moment(ReturnDate).format('DD MMM YYYY, h:mm A'),
        IPLocation: {
          IPAddress: iPAddress,
          Country_code: customerCountryCode,
          Country_name: customerCountryName,
        },
      },
    ];

    localStorage.setItem('BookingInfo', JSON.stringify(BookingInfo));
    localStorage.setItem('VehicleTypeParam', this.getBrandFromPath());
    window.location = '/booking';
  };

  render() {
    const { t } = this.props;
    const { h1, title, description, vehicalModels } = this.state;
    const canonicalUrl = brandDetails[this.getBrandFromPath()].canonicalUrl;

    return (
      <>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <link rel="canonical" href={canonicalUrl} />
        </Helmet>
        <ListingHeader />
        <div className="minH100">
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a">
                <div className="row flex-reversea align-items-center">
                  <div className="col-lg-4">
                    <div className="pickup-location-box pr-0">
                      <h5 className="font-bold mb-3 text-red">{h1}</h5>
                      <p>{title}</p>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="pickup-location-box">
                      <p className="text_p text-black">{description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="offer-box-section pb-4 grey-bg">
            <div className="container-fluid">
              <div className="offer-box-wrapper mt-0 vehicle_type_wrap">
                {vehicalModels.map((model, index) => (
                  <div key={index} className="vehicle-box-design">
                    <h3>{model.Name}</h3>
                    <span className="similar-text">{t('or Similar | Dollar Prestige.1')}</span>
                    <div className="vehicle-box-design-img-wrap">
                      <img
                        src={model.VehicleImages[0]}
                        alt={model.Name}
                        className="vehicle-image-main"
                      />
                    </div>
                    <div className="prestige-car-box-bttn text-center w-100">
                      <a
                        className="vehicle-box-design-book"
                        title="Book Now"
                        onClick={this.handleBookNow}
                      >
                        {t('Book Now.1')}
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>

          <VehicleClassifications />
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(CarBrands)), CarBrands);

import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

class FAQSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.handleSelect(0);
  }

  handleSelect = (index) => {
    this.setState({ avaliableindex: index });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <section className="box-shadow-white-box-section  grey-bg py-4">
          <div className="container-fluid">
            <div className="">
              <div className="pickup-location-box">
                <h2 className="border-botom">{t('FAQ.1')}</h2>
              </div>
              <div className="faq-wrapper">
                <div className="">
                  <div
                    className="panel-group"
                    id="accordion"
                    role="tablist"
                    aria-multiselectable="true"
                  >
                    <div className="box-shadow-white-box py-3 px-4">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          <a
                            style={{ color: 'black' }}
                            className="accordion-toggle collapsed font-bold"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseThirteen"
                            onClick={() => {
                              this.handleSelect(13);
                            }}
                          >
                            {t('Can I add additional drivers for the same car rental?.1')}
                          </a>
                        </h4>
                      </div>
                      <div id="collapseThirteen" className="panel-collapse collapse">
                        <div className="panel-body">
                          <span>
                            {t(
                              'Yes, you may add an extra driver, for a minimal charge, provided he/she fills in all the relevant details.1',
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="box-shadow-white-box py-3 px-4">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          <a
                            style={{ color: 'black' }}
                            className="accordion-toggle collapsed font-bold"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseFourteen"
                            onClick={() => {
                              this.handleSelect(14);
                            }}
                          >
                            {t('What is the policy on off-road driving?.1')}
                          </a>
                        </h4>
                      </div>
                      <div id="collapseFourteen" className="panel-collapse collapse">
                        <div className="panel-body">
                          <span>
                            {t(
                              'Off-road use is a violation of the rental agreement and is not allowed.1',
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="box-shadow-white-box py-3 px-4">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          <a
                            style={{ color: 'black' }}
                            className="accordion-toggle collapsed font-bold"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseFifteen"
                            onClick={() => {
                              this.handleSelect(15);
                            }}
                          >
                            {t('What is your Policy on driving to Oman?.1')}
                          </a>
                        </h4>
                      </div>
                      <div id="collapseFifteen" className="panel-collapse collapse">
                        <div className="panel-body">
                          <span>
                            {t(
                              'Our cars may be driven into Oman with prior purchase of an Oman Insurance Certificate along with the No Objection Letter available at the rental desk. Charges vary based on the vehicles, time and period. However, certain vehicle groups may not be allowed to travel into Oman territory. Driving to Bahrain, Saudi Arabia, Qatar, Yemen, Kuwait, etc. is strictly forbidden.1',
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="box-shadow-white-box py-3 px-4">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          <a
                            style={{ color: 'black' }}
                            className="accordion-toggle collapsed font-bold"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseSixteen"
                            onClick={() => {
                              this.handleSelect(16);
                            }}
                          >
                            {t('What are the expected additional charges?.1')}
                          </a>
                        </h4>
                      </div>
                      <div id="collapseSixteen" className="panel-collapse collapse">
                        <div className="panel-body">
                          <span>
                            {t(
                              'Additional charges related to the rental include Salik (toll fees), any driving/traffic fine or violation charges imposed by RTA/Police/Municipality, Re-fueling Service Charge, additional driver charge, baby seats and GPS, Airport Return Charges, and for any Dubai registered rented or leased vehicle a Vehicle License Fee will be applied accordingly as implemented/mandated by the Roads and Transport Authority (RTA) of Dubai.1',
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="box-shadow-white-box py-3 px-4">
                      <div className="panel-heading">
                        <h4 className="panel-title">
                          <a
                            style={{ color: 'black' }}
                            className="accordion-toggle collapsed font-bold"
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href="#collapseSeventeen"
                            onClick={() => {
                              this.handleSelect(17);
                            }}
                          >
                            {t('How can I avail the best offers at Dollar Car Rental UAE?.1')}
                          </a>
                        </h4>
                      </div>
                      <div id="collapseSeventeen" className="panel-collapse collapse">
                        <div className="panel-body">
                          <span>
                            {t(
                              'Dollar Car Rental guarantees the best prices, assured seasonal offers and surprise deals, 365 days a year! You can follow us on social media to stay tuned or ask our representative to update you on the ongoing promotions.1',
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(FAQSection)), FAQSection);

import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { bookingInstance } from '../axios/axiosInstance';
import { getLanguage } from '../Common/Util';
import ListingCard from './ListingCard';

class UpgradeOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      upgradeModels: [],
    };
  }

  componentDidMount() {
    this.getUpgradeOptions();
  }

  getUpgradeOptions = () => {
    let searchParam = this.props.searchParam;
    searchParam.VehicleModelID = this.props.model.VehicleModelID;
    searchParam.TariffGroupID = this.props.model.TariffGroupID;

    const code = process.env.REACT_APP_COUNTRY_CODE;
    bookingInstance
      .get(`upgrade/${this.props.bid}?countryCode=${code}`, searchParam)
      .then((response) => {
        const upgradeModels = response.data;

        this.setState({
          upgradeModels: upgradeModels,
        });
      })
      .catch((error) => {});
  };

  onBookNowClickHandler = () => {
    this.props.BookShow();
  };

  onBookUpgradeClickHandler = (upgradeModel) => {
    this.props.BookUpgrade(
      upgradeModel,
      this.props.model.VehicleModelID,
      this.props.model.TariffGroup.TariffGroupID,
    );
  };

  onClosePopupHandler = () => {
    this.props.CloseUpgradePopup(false);
  };

  render() {
    let countOfUpgrade = this.state.upgradeModels.length >= 3 ? 3 : this.state.upgradeModels.length;

    const settings = {
      infinite: true,
      slidesToShow: countOfUpgrade,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
          },
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          },
        },
      ],
    };
    const { t } = this.props;

    return (
      <div className="upgrade-option-modal-content">
        <div className="d-flex justify-content-between">
          <h2 className="border-botom">{t('Upgrade Options.1')}</h2>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={this.onClosePopupHandler}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <Slider {...settings}>
          {this.state.upgradeModels.map((model, index) => {
            return (
              <div
                key={index}
                className="offer-box-wrapper mb-0 mt-4 slick-initialized slick-slider"
              >
                <div className="slick-list draggable">
                  <div
                    className="slick-track"
                    style={{
                      opacity: '1',
                      width: '1089px',
                      transform: 'translate3d(0px, 0px, 0px)',
                    }}
                  >
                    <div
                      className="upgrade-slider-box-wrap slick-slide slick-current slick-active"
                      data-slick-index="0"
                      aria-hidden="false"
                      style={{ width: '337 px' }}
                      tabIndex="0"
                      key={model.VehicleModelID}
                    >
                      <div className="vehicle-design-upgrade-box">
                        <ListingCard
                          model={model}
                          hideUpgradeOptions={1}
                          hideInclude={0}
                          showDiffPrice={1}
                          vehicleIndex={index + 1}
                          selectedVehicleIndex={99}
                          rateDifference={Math.round(model.UpgradeExtraRateTotal * 100) / 100}
                        />
                      </div>
                      <div className="text-center mt-3">
                        <a
                          onClick={() => this.onBookUpgradeClickHandler(model)}
                          title={t('Book Now.1')}
                          className="vehicle-box-design-book"
                          tabIndex="0"
                        >
                          {t('Book Now.1')}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
        <div className="w-100 text-center border-top pt-lg-3 pt-2 mt-lg-0 mt-2">
          <a
            onClick={this.onBookNowClickHandler}
            title="Continue with [Vehicle Name]"
            className="book-vehicle-name-btn"
          >
            {t('Continue with.1')} {this.props.model.Name}
          </a>
        </div>
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(UpgradeOption), UpgradeOption);

import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { bookingInstance } from '../axios/axiosInstance';
import { getLanguage } from '../Common/Util';
import ListingCard from './ListingCard';

class ListingUpgradeOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      upgradeModels: [],
      UpgradPlanId: null,
    };
  }

  componentDidMount() {
    this.getUpgradeOptions();
  }

  getUpgradeOptions = () => {
    let searchParam = this.props.searchParam;
    searchParam.VehicleModelID = searchParam.VehicleModelID;
    searchParam.TariffGroupID = searchParam.TariffGroupID;

    const code = process.env.REACT_APP_COUNTRY_CODE;
    bookingInstance
      .post(`upgrade/${this.props.bid}?countryCode=${code}`, searchParam)
      .then((response) => {
        const upgradeModels = response.data.result.data;
        this.setState({
          upgradeModels: upgradeModels.SearchVehicleModels,
          UpgradPlanId: upgradeModels.BookingId,
        });
      })
      .catch((error) => {});
  };

  onBookNowClickHandler = () => {
    this.props.BookShow();
  };

  onBookUpgradeClickHandler = (upgradeModel) => {
    upgradeModel.PaymentStatus = this.props.searchParam.PaymentStatus;
    this.props.BookUpgrade(
      upgradeModel,
      this.props.searchParam.VehicleModelID,
      this.props.searchParam.TariffGroupID,
      this.props.searchParam.TariffRateID,
    );

    setTimeout(() => {
      this.props.onUpdateCalculation(upgradeModel, this.state.UpgradPlanId);
    }, 0);
  };

  onClosePopupHandler = () => {
    this.props.CloseUpgradePopup(false);
  };

  render() {
    let countOfUpgrade =
      this.state.upgradeModels?.length >= 3 ? 3 : this.state.upgradeModels?.length;

    const settings = {
      infinite: true,
      slidesToShow: countOfUpgrade,
      slidesToScroll: 1,

      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            dots: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          },
        },
      ],
    };
    const { t } = this.props;

    return (
      <div className="upgrade-option-modal-content p-0" style={{ visibility: 'auto' }}>
        <div className="d-flex justify-content-between box-title-icon-border">
          <h2 className="border-botom pb-0">
            <b>{t('Upgrade Options.1')}</b>
          </h2>
        </div>
        <Slider {...settings}>
          {this.state.upgradeModels?.map((model, index) => {
            return (
              <div key={index} className="offer-box-wrapper mb-0 mt-4 upgrade-option-boxx-wrapper">
                <div className="upgrade-slider-box-wrap" key={model.VehicleModelID}>
                  <div className="vehicle-design-upgrade-box compact-box-wrap">
                    <ListingCard
                      model={model}
                      hideUpgradeOptions={1}
                      showDiffPrice={1}
                      rateDifference={Math.round(model.UpgradeExtraRateTotal * 100) / 100}
                    />
                  </div>
                  <div className="text-center mt-3">
                    <a
                      onClick={() => this.onBookUpgradeClickHandler(model)}
                      title="Book Now"
                      className="vehicle-box-design-book"
                      tabIndex="0"
                    >
                      {t('Book Now.1')}
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(ListingUpgradeOption), ListingUpgradeOption);

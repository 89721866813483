import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

class ContactUsBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  render() {
    const { t } = this.props;
    return (
      <section className="box-shadow-white-box-section grey-bg pb-4">
        <div className="container-fluid">
          <div className="box-shadow-white-box">
            <div className="row">
              <div className="col-lg-12">
                <div className="p-3 contact-us-quick-box">
                  <h5>{this.props.heading}</h5>
                  <p>{this.props.subHeading}</p>
                  <div className="contact-us-quick-call">
                    <a className="phone-quick" href={'tel:+97143365065'}>
                      <i className="fa fa-phone"></i>"971 4 336 5065"
                    </a>
                    <a className="email-quick" href="mailto:customer.care@dollaruae.ae">
                      {t('Email address.1')}: customer.care@dollaruae.ae
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default withTranslation()(withRouter(withRouter(ContactUsBox)));

import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import rentalpolicy from '../assets/images/rental-policies-icon.svg';
import ViewIncludeLongText from './ViewIncludeLongText';

class RentalPolicies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showExtrasDetails: false,
      loadingText: '',
      LongTextShow: '',
    };
  }

  componentDidMount = () => {};

  toogleViewIncludeLongTextPopUp = (showHide, LongText) => {
    this.setState({
      showExtrasDetails: showHide,
    });
    this.setState({
      LongTextShow: LongText,
    });
  };

  toogleViewExtrasPopUp = (showHide, extrasDesriptionToShow) => {
    this.setState({
      showExtrasDetails: showHide,
    });
    this.setState({
      LongTextShow: extrasDesriptionToShow,
    });
  };

  onAdditionalDetails = (type) => {
    let textToShow = '';
    this.setState({
      showExtrasDetails: true,
    });

    if (type == 'DCR') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<p>All drivers must carry a locally accepted driving license.</p>';
      textToShow +=
        '<p>All renters must be able to provide a Physical valid driver’s license, Physical passport with visa page and authorized credit card under the main Driver Name.</p>';
      textToShow += '<h5>UAE RESIDENTS</h5>';
      textToShow +=
        '<p>A valid UAE driving license is a must. Please note that driving without a Valid UAE driving license when on residence or employment visa is a violation of the LAW.</p>';
      textToShow += '<h5>NON-RESIDENTS</h5>';
      textToShow +=
        "<p>Visitors are encouraged to have an International Driving License/Permit prior from arrival to the UAE. Customers from the following countries can drive in the United Arab Emirates without an International Driving Permit (IDP); however, must hold the passport and driver’s license from the same country: <strong>GCC Countries: </strong>Saudi Arabia, Oman, Bahrain, Kuwait, Qatar <strong>European Countries:</strong> Italy, Germany, Switzerland, Poland, Slovakia, Finland, Spain, Netherlands, Greece, Sweden, Belgium, Ireland, Turkey, Denmark, Portugal, Austria, France, United Kingdom, Norway, Romania, Luxemburg, Estonia, Latvia, Serbia, Hungary,  Lithuania, Cyprus, Iceland, Holland, Malta, Albania, Bulgaria, The Republic of Montenegro <strong>North American Countries:</strong> United States of America, Canada. <strong>East Asian Countries:</strong> Japan, Australia, Singapore, People's Republic of China, South Korea, New Zealand, Hong Kong, <strong>African Country:</strong> South Africa.</p>";
      textToShow +=
        '<p>International or National Driving license in any other language other than Arabic and English should be translated into Arabic or English. The translation should be attested by the Embassy.</p>';
      textToShow += '</div>';
    } else if (type == 'ADD') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Additional Driver</h5>';
      textToShow +=
        '<p>The hirer may put on an extra driver with minimal cost, provided that he fulfills all necessary requirements. <strong>Daily:</strong> AED 21.00; <strong>Weekly:</strong> AED 126.00; <strong>Monthly:</strong> Free of Cost.</p>';
      textToShow += '</div>';
    } else if (type == 'INS') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Insurance Excess</h5>';
      textToShow +=
        '<p>Deductible amount charged where hirer is at fault or provided a police report under hit and run case:</p>';
      textToShow +=
        '<p><strong>•	AED 1500.00 (Mini to Standard Vehicles), AED 2500 to AED 5000 (Compact SUV to Dollar Prestige Vehicles).</strong></p>';
      textToShow += '</div>';
    } else if (type == 'MAL') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Age Restrictions</h5>';
      textToShow += '<p>Minimum age requirement is 22 years.</p>';
      textToShow +=
        '<p>Minimum age requirement for Luxury Vehicles in Abu Dhabi & Al Ain Locations is 25 Years Old.</p>';
      textToShow += '</div>';
    } else if (type == 'FP') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Fuel Policy</h5>';
      textToShow +=
        '<p>The hirer should return the vehicle with the same fuel level as given by the company. If not, the hirer will be charged the cost of fuel plus a service charge.</p>';
      textToShow += '</div>';
    } else if (type == 'ST') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Salik (Toll Fee)</h5>';
      textToShow +=
        '<p>•	All of our cars are installed with SALIK sticker which will be AED 5 Excl. VAT per passage at the SALIK toll gates.</p>';
      textToShow += '</div>';
    } else if (type == 'ORC') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Off-Road Liability/Charges</h5>';
      textToShow +=
        '<p>Insurance does not cover off road usage of the vehicle and the customer will be liable for damage caused and charges arising out of such use.</p>';
      textToShow += '</div>';
    } else if (type == 'TV') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Traffic Violations</h5>';
      textToShow +=
        '<p>Drivers are expected to abide with the traffic rules and regulations. For every traffic fine incurred you will be charged 10% extra to cover administration charges and Knowledge Fee as imposed by UAE Government.</p>';
      textToShow += '</div>';
    } else if (type == 'DCC') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Delivery/Collection charges</h5>';
      textToShow += '<p>Delivery and Collection within the same emirates will be Dhs. 52.50/-.</p>';
      textToShow +=
        '<p>Delivery and Collection in Different Emirate a one way fee will apply :</p>';
      textToShow +=
        '<p>From Dubai & Northern Emirates Locations to Abu Dhabi & Al Ain Locations will be Dhs 210.00/-.</p>';
      textToShow +=
        '<p>From Dubai & Sharjah to Northern Emirates will be Dhs 210.00/- (Vice Versa).</p>';
      textToShow +=
        '<p>From Abu Dhabi & Al Ain Locations to Dubai & Northern Emirates Locations will be Dhs 420.00/-AED to 210.00/-AED.</p>';
      textToShow += '<p>From Abu Dhabi to Al Ain will be Dhs. 210.00/-.</p>';
      textToShow += '<p>From Al Ain to Abu Dhabi will beDhs. 210.00/-.</p>';
      textToShow += '</div>';
    } else if (type == 'LR') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Late Return</h5>';
      textToShow +=
        '<p>Only 1 hour grace period is allowed. Beyond 1 hour, full-day charges as per the tariff card will be charged directly from the hirer.</p>';
      textToShow += '</div>';
    } else if (type == 'M') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<p><strong>Abu Dhabi & Al Ain KM Policy</strong> </p>';
      textToShow += '<p><strong>Mini to SUV Vehicles:</strong></p>';
      textToShow += '<p>Unlimited kilometers for DAILY and WEEKLY rentals.</p>';
      textToShow += '<p>5000kms for MONTHLY rentals, AED 0.35 fils for every extra km.</p>';
      textToShow += '<p><strong>Dollar Prestige Vehicles</strong></p>';
      textToShow += '<p>Limited kilometers for DAILY and WEEKLY rentals.</p>';
      textToShow += '<p>Daily: From 250kms up to 350kms</p>';
      textToShow += '<p>Weekly:  From 1,750kms up to 2,100kms</p>';
      textToShow += '<p>Monthly: From 2,500kms up to 3,500kms</p>';
      textToShow += '<p>Up to 1 AED for every extra kms. </p>';
      textToShow += '<p><strong>Dubai & Sharjah KM Policy</strong></p>';
      textToShow += '<p><strong>Daily & Weekly Rentals</strong></p>';
      textToShow += '<p>Polestar 2: Daily 200Kms & 1AED for every extra kms.</p>';
      textToShow +=
        '<p>Unlimited kilometers for DAILY and WEEKLY rentals. For rest of the vehicles</p>';
      textToShow += '<p><strong>Monthly</strong></p>';
      textToShow += '<p>Limited kilometers for MONTHLY rentals</p>';
      textToShow += '<p>From 2500kms up to 4000kms</p>';
      textToShow += '<p>Up to 1 AED for every extra kms. Depending on Vehicle Category</p>';
      textToShow += '</div>';
    } else if (type == 'CP') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Cancellation:</h5>';
      textToShow +=
        '<p>1. Cancellations of rentals with less than 48 hours’ notice from Pick up Date, Dollar Car Rental UAE will charge an Administration fee of AED: 105.</p>';
      textToShow +=
        '<p>2. Cancellations of rentals with more than 48 hours’ notice from Pick up Date are Free of Charge.</p>';
      textToShow +=
        '<p>3. Refunds will be made to the credit card that the original booking was made on within 21 working days.</p>';
      textToShow +=
        '<p>4. Any booking that is cancelled on the same day of pick-up due to invalid documents, etc., Dollar Car Rental UAE will charge an Administration fee of AED: 157.50.</p>';

      textToShow += '</div>';
    } else if (type == 'ER') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Early Return/ No Shows</h5>';
      textToShow +=
        '<p>There will be no refund for unused car rental days on a Prepaid/Pay On Arrival rental, once the rental has started.</p>';
      textToShow +=
        '<p>There will be no refund if the customer fails to show up for a rental that has not been cancelled prior to the pickup date.</p>';
      textToShow += '</div>';
    } else if (type == 'ET') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Early Termination</h5>';
      textToShow +=
        '<p>Incase of Early Termination, a Penalty Fee equivalent to a 1 Month Rental will be applied.</p>';
      textToShow +=
        '<p>There will be no refund for unused car rental days on a Prepaid/Pay on Arrival rentals, once the rental has started.</p>';
      textToShow += '</div>';
    } else if (type == 'BP') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Breakdown Procedure</h5>';
      textToShow +=
        '<p>Please try to park the vehicle in safe and secure place. Try to know exactly where you are. Contact the nearest Dollar Car Rental UAE office or Toll Free 800-4710 (800 Dollar) and provide the details of breakdown type. A replacement vehicle will be sent. A Service Fee will apply for replacement of the spare tyre. </p>';
      textToShow += '</div>';
    } else if (type == 'VAT') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Value Added Tax (VAT)</h5>';
      textToShow +=
        '<p>Dollar Car Rental UAE reserves the right to add 5% VAT as confirmed and implemented by UAE Government with effect on Jan 2018.</p>';
      textToShow += '</div>';
    } else if (type == 'SD') {
      textToShow += "<div className='tab-term-content'>";
      textToShow +=
        '<p>A pre-authorization is taken from the hirer’s credit card at the time of rental. Pre-authorization amount is between 1500/-AED to 5000/-AED depending on the vehicle type. We keep pre-authorization amount for 14 Working Days from the Start Date of the Rental.</p>';
      textToShow +=
        '<p>Credit card on the name of card holder must be available at pick up location. This is a mandatory requirement. The credit card must have enough funds to cover the security deposit.</p>';
      // textToShow +=
      //   '<p>Customers can avail the option of no deposit for monthly rentals with a one-time subscription fee. This offer is available to all residential customers across UAE.</p>';
      textToShow += '<h5>Method of Payment</h5>';
      textToShow +=
        '<p>We accept all major <u>Credit Cards</u> (MASTER, VISA, JCB, DINERS). Credit card should be valid for a minimum of 3 months from the day of rental and must be physically available at time of vehicle collection.</p>';

      textToShow += '</div>';
    } else if (type == 'OI') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Oman Insurance</h5>';
      textToShow +=
        '<p>Driving to Oman territory is allowed with prior purchase of an Oman Insurance Certificate along with the No Objection Letter available at the rental desk. <strong>Oman Insurance charges are as follows (these rates are excluding the 5% VAT):</strong></p>';
      textToShow +=
        '<p>Note: <strong>Abu Dhabi Rentals Can Issue Oman Insurance up to 31st December only.</strong></p>';
      textToShow +=
        "<p style='margin-left: 40px;'><strong>Dubai and Sharjah Rentals are not Issuing Oman insurance until further notice.</strong></p>";
      textToShow += "<table className='mt-4'>";
      textToShow += '<tr>';
      textToShow += "<th width='30%'>Car Type or Similar</th>";
      textToShow += '<th>1-3 days</th>';
      textToShow += '<th>7 days</th>';
      textToShow += '<th>7+ days</th>';
      textToShow += '<th>30 days</th>';
      textToShow += '<th>Extra KM Charged</th>';
      textToShow += '</tr>';
      textToShow += '<tr>';
      textToShow +=
        '<td>▪ Nissan Sunny ▪ Nissan Micra ▪ Kia Picanto ▪ Chevrolet Spark ▪ Hyundai Elantra ▪ Toyota Yaris ▪ Honda Civic ▪ Toyota Corolla ▪ Nissan Altima ▪ Toyota Camry ▪ Mazda 6 ▪ Kia Optima ▪ Nissan Kicks ▪ Hyundai Creta.</td>';
      textToShow +=
        '<td>350 Dhs/-<br></br> (with 600 km for 3 days).<br></br>( Each Day extra + 75 Dhs/- ).</td>';
      textToShow += '<td>600 Dhs/-<br></br> ( 250 Km per day ).</td>';
      textToShow += '<td>75 Dhs /-</td>';
      textToShow += '<td>1000 Dhs /-</td>';
      textToShow += '<td>1 AED</td>';
      textToShow += '</tr>';
      textToShow += '<tr>';
      textToShow +=
        '<td>▪ Toyota Previa ▪ Toyota Fortuner ▪ Mazda CX-9 ▪ Mazda CX-5 ▪ Nissan X-Trail.</td>';
      textToShow +=
        '<td>450 Dhs/- <br></br>(with 600 km for 3 days). <br></br> (Each Day extra 100 Dhs/-).</td>';
      textToShow += '<td>750 Dhs/- <br></br>( 350 Km per day ).</td>';
      textToShow += '<td>100 Dhs/-</td>';
      textToShow += '<td>1500 Dhs/-</td>';
      textToShow += '<td>1 AED</td>';
      textToShow += '</tr>';
      textToShow += '</table>';
      textToShow +=
        "<p className='my-4'>LIMITED MILEAGE provided when driving the car in Oman. Following vehicles are <strong><u>NOT</u></strong> allowed to be driven to Oman: Dodge Charger, BMW, VW Golf GTI, Mercedes C200, Lexus, Audi A6, Audi A8, Nissan Patrol, Dodge Challenger and Jeep Wrangler. </p>";
      textToShow += '</div>';
    } else if (type == 'VLF') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Vehicle License Fee</h5>';
      textToShow +=
        '<p>A vehicle monitoring device fee also known as the Vehicle License Fee (VRF) will be charged as implemented/mandated by the Roads and Transport Authority of Dubai. <strong>Daily:</strong> AED 5.25; <strong>Weekly:</strong> AED 31.50; <strong>Monthly:</strong> AED 78.75.</p>';
      textToShow += '</div>';
    } else if (type == 'VAA') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Value Added Assets</h5>';
      textToShow +=
        '<p>Child Seats/Baby Seats, GPS and Wi-Fi Devices are available on request and subject to availability only. GPS Daily-AED 36.75/-, Car Seats Daily-AED 36.75, Wifi-Device Daily-AED 42.00/-.</p>';
      textToShow +=
        '<p>The hirer shall be fully responsible in case of loss, stolen or damages for the usage of Wi-Fi Service.</p>';
      textToShow +=
        '<p>Dollar Car Rental UAE is not liable for any errors, inaccuracies, omissions, or for any loss or damage suffered by users arising out of usage of any of the information published on any pages /maps on the GPS. The hirer is liable to pay for charges of AED 1000 for the loss of the Unit. In case of any damages all charges as determined by Dollar are payable.</p>';
      textToShow += '</div>';
    } else if (type == 'RC') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Airport Return Charges</h5>';
      textToShow +=
        '<p>All vehicles off hired/returned in any of our airport counters will be charged an airport drop off fees. If you wish to avoid this drop off fees, rented vehicles can be returned to any non-airport branch locations between 08:00H to 17:00H (Saturday to Thursday). Presently the airport drop off fees is as follows: Dubai Airport Terminal 1- AED 52.50/-, Dubai Airport Terminal 3-AED 52.50/-, Sharjah Airport- AED 21/- and Abu Dhabi Airport- AED 52.50/-.</p>';
      textToShow += '<h5>Non Airport Return</h5>';
      textToShow +=
        '<p>Drop-offs at any Non-Airport locations will be free of cost based on the working hours as mentioned on Locations.</p>';
      textToShow += '<h5>Oman Off-hire/Return Charges</h5>';
      textToShow += '<p>Muscat/Sohar: AED 1050.00; Salalah: AED 1575.00.</p>';

      // textToShow += "<p>Discount rates on the website and on counter given cannot be clubbed with any Loyalty discounts or promotion running</p>";
      textToShow += '<h5>Extensions</h5>';
      textToShow +=
        '<p>If a rental needs to be extended once the car has been picked up, local rental rates on 25% discount from the normal tariff will apply for the additional days and the additional charges will be settled by customer’s credit card.</p>';

      textToShow += '</div>';
    } else if (type == 'OWF') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>One Way Fees</h5>';
      textToShow +=
        '<p>If you arrange to pick the car up in one location and drop it off in different Emirate One Way Fee will apply as below.</p>';
      textToShow +=
        '<p>From Dubai & Northern Emirates Locations to Abu Dhabi & Al Ain Locations will be Dhs 210.00/- per one-way drop.</p>';
      textToShow +=
        '<p>From Abu Dhabi & Al Ain Locations to Dubai & Northern Emirates Locations will be Dhs 210.00/- per one-way drop.</p>';
      textToShow += '<p>From Abu Dhabi to Al Ain will be Dhs 210.00/- per one-way drop.</p>';
      textToShow += '<p>From Al Ain to Abu Dhabi will be Dhs 210.00/- per one-way drop.</p>';
      textToShow += '</div>';
    } else if (type == 'EXT') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Extensions</h5>';
      textToShow +=
        '<p>If a rental needs to be extended once the car has been picked up, local rental rates on 25% discount from the normal tariff will apply for the additional days and the additional charges will be settled by customer’s credit card.</p>';
      textToShow += '</div>';
    } else if (type == 'CR') {
      textToShow += "<div className='tab-term-content'>";
      textToShow += '<h5>Change of Reservation</h5>';
      textToShow +=
        '<p>1. Any change to a booking made online or through a Dollar Car Rental UAE Representative will be recalculated based on the availability and prices at the time the change is made. This may be greater or lesser than the price originally booked. This applies to changes made to:</p>';
      textToShow +=
        '<p>a. Drop off time and date | d. Vehicle group | e. All other additional products.</p>';
      textToShow +=
        '<p>Any modification for changes made online would have an amendment fee of AED: 52.50 if changes made with less than 48 hrs from Pick up Date</p>';
      textToShow +=
        '<p>3. The primary customer name and the pick-up location on a reservation cannot be changed. If you wish to change the primary name or the pick-up location, then you must cancel the reservation (see below “Cancellations”) and make a new reservation.</p>';
      textToShow +=
        '<p>4. You will be asked to enter a valid credit card number at the end of a change to a prepaid reservation. This MUST be the same credit card that was used for the original reservation. If you wish to change the credit card, then the original reservation must be canceled (see below “Cancellations”) and a new reservation to be made.</p>';
      textToShow +=
        '<p>5. Administration charges will not apply to amended bookings, unless the amendment significantly changes the original booking, in which case it will be subject to the cancellation fee detailed below. All requests to amend a booking will be subject to normal availability of the requested vehicle. Prices may change between the date of the original booking and the amended booking, in which case the later prices will apply.</p>';
      textToShow += '</div>';
    }

    this.setState({
      LongTextShow: textToShow,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <section className="box-shadow-white-box-section pb-4">
          <div className="container-fluid">
            <div className="box-shadow-white-box mt-0 mb-0">
              <div className="rental-policies-wrap">
                <div className="box-title-icon-border d-flex align-items-center mb-4 mb-0 mt-0">
                  <span className="box-title-icon-rounded mt-0">
                    <img src={rentalpolicy} alt="Dollar Rental Policies" width="18" height="18" />
                  </span>
                  <h4 className="text-16 text-red">{t('Rental Policies.1')}</h4>
                </div>
                <div className="row">
                  <div className="col-12">
                    <p className="text-16 mb-3">
                      {t(
                        'All renters must be able to provide a valid drivers license, passport with visa page and authorized credit card under the main Driver Name. All drivers must carry a locally accepted driving license.1',
                      )}
                    </p>
                    <p className="text-16 mb-3">
                      {t(
                        'UAE RESIDENTS A valid UAE driving license is a must. Please note that driving without a Valid UAE driving license when on residence or employment visa is a violation of the LAW.1',
                      )}{' '}
                    </p>
                    <p className="text-16 mb-3">
                      {t(
                        'NON-RESIDENTS Visitors are encouraged to have an International Driving License/Permit prior from arrival to the UAE.1',
                      )}{' '}
                    </p>
                    <div className="collapse" id="readmore">
                      <p className="text-16 mb-3">
                        {t(
                          'Customers from the following countries can drive in the United Arab Emirates without an International Driving Permit (IDP); however, must hold the passport and drivers license from the same country.1',
                        )}{' '}
                        <b>{t('GCC Countries.1')} </b>
                        {t('Saudi Arabia, Oman, Bahrain, Kuwait, Qatar.1')}{' '}
                        <b>{t('European Countries.1')}:</b>{' '}
                        {t(
                          'Italy, Germany, Switzerland, Poland, Slovakia, Finland, Spain, Netherlands, Greece, Sweden, Belgium, Ireland, Turkey, Denmark, Portugal, Austria, France, United Kingdom, Norway, Romania, Luxemburg, Estonia, Latvia, Serbia, Hungary,  Lithuania, Cyprus, Iceland, Holland, Malta, Albania, Bulgaria, The Republic of Montenegro.1',
                        )}{' '}
                        <b>{t('North American Countries.1')}</b>{' '}
                        {t('United States of America, Canada.1')}{' '}
                        <strong>{t('East Asian Countries.1')}</strong>{' '}
                        {t(
                          'Japan, Australia, Singapore, Peoples Republic of China, South Korea, New Zealand, Hong Kong.1',
                        )}{' '}
                        <b>{t('African Country.1')}:</b> {t('South Africa.1')}
                      </p>
                      {t(
                        'International or National Driving license in any other language other than Arabic and English should be translated into Arabic or English. The translation should be attested by the Embassy.1',
                      )}{' '}
                      <br />
                      <br />
                      <p className="text-16 mb-3">
                        {t(
                          'Discount rates on the website and on counter given cannot be clubbed with any Loyalty discounts or promotion running.1',
                        )}
                      </p>
                    </div>
                    <a
                      className="square-bttn"
                      data-toggle="collapse"
                      href="#readmore"
                      role="button"
                      aria-expanded="false"
                      aria-controls="readmore"
                    >
                      {t('READ MORE.1')}
                    </a>

                    <ul className="rental-policies-list text-15 opacity-50">
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('DCR')}
                      >
                        {t('Documents Required.1')}
                      </li>
                      {this.props.rentalType == 3 ? null : (
                        <li
                          style={{ cursor: 'pointer' }}
                          onClick={() => this.onAdditionalDetails('ADD')}
                        >
                          {t('Additional Driver.1')}
                        </li>
                      )}
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('INS')}
                      >
                        {t('Insurance.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('MAL')}
                      >
                        {t('Minimum Age Limit.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('FP')}
                      >
                        {t('Fuel Policy.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('ST')}
                      >
                        {t('Salik (Toll Fee).1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('ORC')}
                      >
                        {t('Off-Road Charges.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('TV')}
                      >
                        {t('Traffic Violations.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('DCC')}
                      >
                        {t('Delivery Collection Charges.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('LR')}
                      >
                        {t('Late Return.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('M')}
                      >
                        {t('Mileage.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('CP')}
                      >
                        {t('Cancellation Policy.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('ER')}
                      >
                        {t('Early Return/No Shows.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('ET')}
                      >
                        {t('Early Termination.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('BP')}
                      >
                        {t('Breakdown Procedure.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('VAT')}
                      >
                        {t('Value Added Tax(VAT).1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('SD')}
                      >
                        {t('Security Deposits.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('OWF')}
                      >
                        {t('One Way Fees.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('EXT')}
                      >
                        {t('Extensions.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('RC')}
                      >
                        {t('Return Charges.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('CR')}
                      >
                        {t('Change of Reservation.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('OI')}
                      >
                        {t('Oman Insurance.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('VLF')}
                      >
                        {t('Vehicle License Fees.1')}
                      </li>
                      <li
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.onAdditionalDetails('VAA')}
                      >
                        {t('Value Added Assets.1')}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {this.state.showExtrasDetails ? (
          <div>
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <Modal
                show={this.state.showExtrasDetails}
                onHide={() => this.toogleViewIncludeLongTextPopUp(false)}
                dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap"
              >
                <ViewIncludeLongText LongTextShow={this.state.LongTextShow} IsTextWithHtml={1} />
              </Modal>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default withTranslation()(withRouter(RentalPolicies));

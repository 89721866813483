import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import appstore from '../assets/images/app-store.png';
import arencologo from '../assets/images/arenco-logo.png';
import dollarlogo from '../assets/images/dollar.svg';
import googleplay from '../assets/images/google-play-store.png';

class CopyRightFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CookiePolicyAccepted: '',
    };
  }

  onFooterMenuLinkClick = (path) => {
    this.props.history.push(path);
  };

  componentDidMount() {
    const CookiePolicyAccepted = localStorage.getItem('CookiePolicyAccepted');
    this.setState({ CookiePolicyAccepted: CookiePolicyAccepted });
  }

  onAcceptContinue = () => {
    var cookiesPolicy = 'accepted';
    localStorage.setItem('CookiePolicyAccepted', cookiesPolicy);
    const CookiePolicyAccepted = localStorage.getItem('CookiePolicyAccepted');
    this.setState({ CookiePolicyAccepted: CookiePolicyAccepted });
    //window.location.reload();
  };

  onMobileAppClick = () => {
    this.setState({ errorMessage: 'Mobile App is under construction.  Check back soon.' });
    return false;
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="footer-bottom-wrap">
          <div className="container">
            <div className="row justify-content-md-between justify-content-center">
              <div className="col-lg-auto col-md-6 col-sm-6 col-12">
                <a href="/" className="footer-logo" title="Dollar">
                  <img src={dollarlogo} width="105" height="29" alt="Dollar Car Rental UAE" />
                </a>

                <div>
                  {/* <span>Corporate Address (Head Office):</span><br></br>
                                    Zabeel Rent A Car (L.L.C) <br></br>
                                    201, Block A,<br></br>
                                    Arenco Building<br></br>
                                    Al Karama, Zabeel Street,<br></br>
                                    Dubai , United Arab Emirates<br></br><br></br><br></br>
                                    <a href={"tel:+97143365065"} className="footer-mobile" style={{marginBottom : 0}} title="Call">Phone:
                                    +971 4 336 5065
                                    </a><br></br> */}
                  {/* <a href="mailto:marketing@dollaruae.com" className="footer-mobile footer-email" title="Mail">Email:
                                    marketing@dollaruae.com
                                    </a> */}

                  <div>
                    <span>Dubai:</span>
                    <a
                      href="mailto:makeabooking@dollaruae.ae"
                      className="footer-mobile footer-email"
                      title="Mail"
                    >
                      makeabooking@dollaruae.ae
                    </a>
                    <a href={'tel:800 4710'} className="footer-mobile" title="Call">
                      Toll Free: 800 4710
                      <br />
                    </a>
                  </div>
                  <div>
                    <span>Abu Dhabi:</span>
                    <a
                      href="mailto:adreservations@dollaruae.ae"
                      className="footer-mobile footer-email"
                      title="Mail"
                    >
                      adreservations@dollaruae.ae
                    </a>
                    <a href={'tel:800 9100'} className="footer-mobile" title="Call">
                      Toll Free: 800 9100
                    </a>
                  </div>
                </div>
              </div>

              {this.props.isMobileView == false ? (
                <div className="col-lg-auto col-md-6 col-sm-6 col-12 mt-4 mt-sm-0">
                  <h5>{t('Download App.1')}</h5>
                  <ul className="footer-nav-wrap">
                    <li>
                      <a
                        title="Google Play Store"
                        className="download-store-app"
                        onClick={this.onMobileAppClick}
                      >
                        <img src={googleplay} alt="Google Play Store" />
                      </a>
                    </li>
                    <li>
                      <a
                        title="App Store"
                        className="download-store-app"
                        onClick={this.onMobileAppClick}
                      >
                        <img src={appstore} alt="App Store" />
                      </a>
                    </li>
                  </ul>
                </div>
              ) : null}

              <div className="col-lg-auto col-md-6 col-sm-6 col-12 mt-4 mt-lg-0">
                <h5>{t('The Group.1')}</h5>
                <ul className="footer-nav-wrap">
                  <li>
                    <a href="https://www.aaagroup.com/" target="_blank" title="Arenco">
                      <img src={arencologo} width="80" height="86" alt="Arenco" />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-auto col-md-6 col-sm-6 col-12 mt-4 mt-lg-0">
                <h5 className="empty">&nbsp;</h5>
                <ul className="list-unstyled social-media-footer justify-content-lg-end">
                  <li>
                    <a href="https://www.linkedin.com/company/dollar-rent-a-car-uae">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/dollar.uae/">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>

                  <li>
                    <a href="https://www.instagram.com/dollar.uae/">
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-footer">
          <div className="container">
            <div className="info-wrapper">
              <p className="order-lg-0 order-1 mt-lg-0 mt-2">
                &copy;{' '}
                <a href="/" title="Dollar">
                  {t('Dollar.')}
                </a>
                {t('All Rights Reserved.1')}.
              </p>
              <ul className="list-unstyled info-list-wrap">
                <li>
                  <a href="https://blog.dollaruae.com/" title="Blogs" target="_blank">
                    {t('Blogs.1')}
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    title="Terms and conditions"
                    onClick={() => this.onFooterMenuLinkClick('/termsandconditions')}
                  >
                    {t('Terms and conditions.1')}
                  </a>
                </li>

                <li>
                  <a href="" title="Sitemap" onClick={() => this.onFooterMenuLinkClick('/sitemap')}>
                    {t('Sitemap.1')}
                  </a>
                </li>

                <li>
                  <a
                    href=""
                    title="Privacy Policy"
                    onClick={() => this.onFooterMenuLinkClick('/privacypolicy')}
                  >
                    {t('Privacy Policy.1')}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {this.state.CookiePolicyAccepted ? (
          ''
        ) : (
          <div className="save-cookies-section">
            <div className="container">
              <div className="save-cookies-content">
                <p>
                  {t(
                    'Our website uses cookies to ensure a better experience for you as a guest on our digital platform. Your consent will help us understand your preferences. Whoever thought of cookies road trippin’ together!.1',
                  )}{' '}
                </p>
                <a onClick={this.onAcceptContinue}>{t('Accept & Continue.1')}</a>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(CopyRightFooter)), CopyRightFooter);

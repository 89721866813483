import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import docattachment from '../assets/images/document-attachments-icon.svg';

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <ListingHeader></ListingHeader>
        <div className="my-5">
          <div className="terms-page">
            <div className="container">
              <div className="terms-title-wrap row">
                <div className="col-6">
                  <h2 className="border-botom">{t('Privacy Policy.1')}</h2>
                  {/* <p>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet.</p> */}
                </div>

                <div className="col-12">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pre-paid-term"
                      role="tabpanel"
                      aria-labelledby="pre-paid-term-tab"
                    >
                      {/* <div className="tab-title">
                                                PRE-PAID GENERAL TERMS AND CONDITIONS
                                            </div> */}
                      <div className="tab-term-content">
                        <h5>{t('What information do we collect.1')}?</h5>
                        <p>{t('What information do we collect ans1.1')}</p>
                      </div>
                      <div className="tab-term-content">
                        <p>{t('What information do we collect ans2.1')}</p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t('What do we use your information for.1')}? </h5>
                        <p>
                          {t(
                            'Any of the information we collect from you may be used in one of the following ways.1',
                          )}
                          :
                        </p>
                        <p>
                          {t(
                            'Any of the information we collect from you may be used in one of the following ways response1.1',
                          )}
                        </p>
                        <p>
                          {t(
                            'Any of the information we collect from you may be used in one of the following ways response2.1',
                          )}
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t('How do we protect your information.1')}?</h5>
                        <p>{t('How do we protect your information response.1')}</p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t('Cookies and Online Advertising Choices.1')}? </h5>
                        <p>{t('Cookies and Online Advertising Choices p1.1')}</p>
                        <div className="tab-term-content">
                          <p>{t('Cookies and Online Advertising Choices p2.1')}</p>
                        </div>
                        <p>
                          {t('Cookies and Online Advertising Choices p3.1')}:
                          http://optout.networkadvertising.org
                        </p>
                        <p>
                          {t('Cookies and Online Advertising Choices p4.1')}:
                          http://www.youronlinechoices.eu
                        </p>

                        <p>
                          {t('Cookies and Online Advertising Choices p51.1')}:
                          https://policies.google.com/technologies.{' '}
                          {t('Cookies and Online Advertising Choices p52.1')}
                        </p>
                        <p>{t('Cookies and Online Advertising Choices p6.1')}</p>
                        <p>{t('Cookies and Online Advertising Choices p7.1')}:</p>
                        <p>
                          <strong>{t('Uninstalling the app.1')}</strong>{' '}
                          {t('Uninstalling the app response.1')}
                        </p>
                        <p>
                          <strong>{t('Opt out of interest-based advertising.1')}</strong>{' '}
                          {t('Opt out of interest-based advertising response.1')}:
                        </p>
                        <p>
                          <strong>{t('For Apple users.1')}</strong>,{' '}
                          {t('For Apple users response1.1')}:{' '}
                          <strong>
                            {t('Settings.1')} > {t('Privacy.1')} > {t('Advertising.1')}.{' '}
                            {t('iOS 6.1')}
                          </strong>
                          , {t('For Apple users response2.1')}:{' '}
                          <strong>
                            {t('Settings.1')} > {t('General.1')} > {t('About.1')} >{' '}
                            {t('Advertising.1')}.
                          </strong>{' '}
                          {t('For Apple users response3.1')}: www.apple.com
                        </p>
                        <p>
                          <strong>{t('For other major mobile platforms.1')}</strong>{' '}
                          {t('For other major mobile platforms response.1')}
                        </p>
                        <p>
                          <strong>{t('Opt out of location data.1')}</strong>{' '}
                          {t('Opt out of location data response.1')}
                        </p>
                        <p>
                          <strong>{t('Opt out of cross-app data collection.1')}</strong>{' '}
                          {t('Opt out of cross-app data collection response.1')}{' '}
                          www.aboutads.info/appchoices).{' '}
                        </p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t('Do we disclose any information to outside parties.1')}? </h5>
                        <p>{t('Do we disclose any information to outside parties response.1')}</p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t('Online Privacy Policy Only.1')}</h5>
                        <p>{t('Online Privacy Policy Only response.1')}</p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t('Your Consent.1')}</h5>
                        <p>{t('Your Consent response.1')}</p>
                      </div>
                      <div className="tab-term-content">
                        <h5>{t('Changes to our Privacy Policy.1')}</h5>
                        <p>{t('Changes to our Privacy Policy response.1')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ListingFooter></ListingFooter>
      </>
    );
  }
}

export default withTranslation()(PrivacyPolicy);

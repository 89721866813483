import moengage from '@moengage/web-sdk';
import { getMonth, getYear } from 'date-fns';
import hoistStatics from 'hoist-non-react-statics';
import range from 'lodash/range';
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ErrorAlert from '../Common/AlertError/ErrorAlert';
import SuccessAlert from '../Common/AlertError/SuccessAlert';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getLanguage, getUserInfo } from '../Common/Util';
import VehicleFeatureBoxVertical from '../Common/VehicleFeatureBoxVertical';
import FAQSection from '../Other/FAQSection';
import catKeyIcon from '../assets/images/car-key.svg';
import documentattachments from '../assets/images/document-attachments-icon.svg';
import driverdetails from '../assets/images/driver-details-icon.svg';
import historyIcon from '../assets/images/history.svg';
import { bookingInstance, branchInstance, userInstance } from '../axios/axiosInstance';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCheked: true,
      isMyProfileClicked: 0,
      access_token: '',
      profileDetails: [],
      countryList: [],
      dob: moment(Date())._d,
      Rental: 0,
      RentalDays: 0,
      NewPassword: '',
      ConfirmPassword: '',
      errorMessage: '',
      successMessage: '',
      driverSuccessMessage: '',
      driverErrorMessage: '',
      firstname: '',
      lastname: '',
      contactno: '',
      email: '',
      countrycode: '',
      nationalitycode: '',
      nationalityname: '',
      PickUpDate: Date().toLocaleString(),
      ReturnDate: Date().toLocaleString(),
      BranchId: '',
      VehicleClassificationID: '',
      Name: '',
      ImageString: '',
      Address: '',
      showDocAttachement: 1,
      selectedFile: null,
      AttachmentID: 0,
      passportSelectedFile: null,
      PassportAttachmentID: 0,
      licenseImg: '',
      passportImg: '',
    };
  }

  onStartBooking = () => {
    this.props.history.push('/');
    this.getAllBranches();
  };

  onFileChange = (e) => {
    this.setState({ selectedFile: e.target.files[0] });
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    userInstance
      .post('ui/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        this.setState({ licenseImg: response.data.result });
      })
      .catch((error) => {});
  };

  onPassportFileChange = (e) => {
    this.setState({ passportSelectedFile: e.target.files[0] });
    const file = e.target.files[0];

    const formData = new FormData();
    formData.append('file', file);
    userInstance
      .post('ui/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        this.setState({ passportImg: response.data.result });
      })
      .catch((error) => {});
  };

  getAllBranches = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    branchInstance
      .get(`all?countryCode=${code}`)
      .then((response) => {
        const allBranches = response.data.result;
        if (response.data.length > 0) {
          this.getHoursToReady(
            allBranches[0]?._id,
            allBranches[0].Name,
            allBranches[0].Address,
            allBranches[0].Message,
            allBranches[0].Description,
          );
        }
      })
      .catch((error) => {});
  };

  getHoursToReady = (timeId, Name, Address, Message, Description) => {
    var isDeliver = this.state.isDeliverToMe || false;
    branchInstance
      .get(`/hrsToGetReady?branchId=${timeId}&isDeliverToMe=${isDeliver}`)
      .then((response) => {
        let timeToReady = response.data.result;
        let pickupDate = moment(moment.tz(timeToReady.PickupOn, 'Asia/Dubai')).format(
          'DD MMM YYYY, h:mm A',
        );
        let returnDate = moment(moment.tz(timeToReady.PickupOn, 'Asia/Dubai')).format(
          'DD MMM YYYY, h:mm A',
        );

        var BookingInfo = [
          {
            CheckOutLocationId: timeId,
            CheckInLocationId: timeId,
            PickUpDate: pickupDate,
            ReturnDate: returnDate,
            pickupLocationName: Name,
            dropOffLocationName: Name,
            pickupLocationAddress: Address,
            dropOffLocationAddress: Address,
            pickupLocationMessage: Message,
            dropOffLocationMessage: Message,
            pickupLocationBranchDescription: Description,
            dropOffLocationBranchDescription: Description,
            promocode: '',
          },
        ];

        localStorage.setItem('BookingInfo', JSON.stringify(BookingInfo));
        this.props.history.push('/booking');
      })
      .catch((error) => {});
  };

  onChangeCountryCode = (e) => {
    let index = e.nativeEvent.target.selectedIndex;
    let label = e.nativeEvent.target[index].text;

    this.setState({
      nationalitycode: e.target.value,
      nationalityname: label,
    });
  };

  onViewDetailsClick = (Id) => {
    this.props.history.push(`/reservationconfirm/${Id}/1`);
  };

  onCancelClick = (Id) => {
    this.props.history.push(`/reservationconfirm/${Id}/0`);
  };

  onChangePickUpDate = (date) => {
    this.setState({ dob: date });
  };

  onChangeValue = (e) => {
    const isCheked = this.state.isCheked;
    this.setState({
      isCheked: !isCheked,
    });
    if (e.target.value == 'atcounter') {
      this.setState({ showDocAttachement: 0 });
    } else {
      this.setState({ showDocAttachement: 1 });
    }
  };

  componentDidMount() {
    this.getCustomerProfileDetails();
    this.getCountryList();
  }

  onClickMyProfile = () => {
    this.setState({
      isMyProfileClicked: 1,
    });
  };

  onClickMyRental = () => {
    this.setState({
      isMyProfileClicked: 0,
    });
  };

  getCustomerProfileDetails = () => {
    const userinfo = getUserInfo();
    if (userinfo !== null) {
      userInstance
        .get(`ui/${userinfo.CustomerID}`, {
          headers: {
            Authorization: `Bearer ${userinfo.access_token}`,
          },
        })
        .then((response) => {
          let rentalDays = 0;
          const responseData = response.data.result;

          if (responseData?.CurrentReservations) {
            responseData.CurrentReservations.forEach((element) => {
              if (element.RentalIn !== null) {
                rentalDays = rentalDays + element.RentalIn.RentalDays;
              }
            });
          }

          if (responseData?.PastReservations) {
            responseData.PastReservations.forEach((element) => {
              if (element.RentalIn !== null) {
                rentalDays = rentalDays + element.RentalIn.RentalDays;
              }
            });
          }
          moengage.add_email(responseData.EmailId);
          this.setState({
            profileDetails: responseData,
            firstname: responseData.FirstName,
            lastname: responseData.LastName,
            contactno: responseData.ContactNo,
            email: responseData.EmailId,
            dob: moment(responseData.BirthDate)._d,
            nationalitycode: responseData?.NationalityCode,
            nationalityname: responseData?.NationalityName,
            RentalDays: rentalDays,
            Rental:
              responseData?.CurrentReservations?.length + responseData?.PastReservations?.length ||
              0,
          });
        })
        .catch((error) => {});
    } else {
      window.location = '/login';
    }
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  validate = () => {
    const lngParam = getLanguage();
    const { NewPassword: newPassword, ConfirmPassword: confirmPassword } = this.state;
    const trimmedNewPassword = newPassword.trim();
    const trimmedConfirmPassword = confirmPassword.trim();

    this.setState({ errorMessage: '', successMessage: '' });

    const errorMessages = {
      requiredNewPassword: {
        en: 'The new password field is required',
        ar: 'مطلوب حقل كلمة المرور الجديدة',
      },
      length: {
        en: 'Password must be at least 8 characters long',
        ar: 'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل',
      },
      specialChar: {
        en: 'Password must contain at least one special character',
        ar: 'يجب أن تحتوي كلمة المرور على حرف خاص واحد على الأقل',
      },
      upperCase: {
        en: 'Password must contain at least one uppercase letter',
        ar: 'يجب أن تحتوي كلمة المرور على حرف كبير واحد على الأقل',
      },
      lowerCase: {
        en: 'Password must contain at least one lowercase letter',
        ar: 'يجب أن تحتوي كلمة المرور على حرف صغير واحد على الأقل',
      },
      number: {
        en: 'Password must contain at least one number',
        ar: 'يجب أن تحتوي كلمة المرور على رقم واحد على الأقل',
      },
      requiredConfirmPassword: {
        en: 'The confirm password field is required',
        ar: 'مطلوب حقل تأكيد كلمة المرور',
      },
      mismatch: {
        en: 'Passwords do not match',
        ar: 'كلمات المرور غير متطابقة',
      },
    };

    const validations = [
      { regex: /.{8,}/, errorKey: 'length' },
      { regex: /[!@#$%^&*(),.?":{}|<>]/, errorKey: 'specialChar' },
      { regex: /[A-Z]/, errorKey: 'upperCase' },
      { regex: /[a-z]/, errorKey: 'lowerCase' },
      { regex: /\d/, errorKey: 'number' },
    ];

    if (trimmedNewPassword === '') {
      this.setState({ errorMessage: errorMessages.requiredNewPassword[lngParam] });
      return false;
    }

    for (const validation of validations) {
      if (!validation.regex.test(trimmedNewPassword)) {
        this.setState({ errorMessage: errorMessages[validation.errorKey][lngParam] });
        return false;
      }
    }

    if (trimmedConfirmPassword === '') {
      this.setState({ errorMessage: errorMessages.requiredConfirmPassword[lngParam] });
      return false;
    }

    if (trimmedNewPassword !== trimmedConfirmPassword) {
      this.setState({ errorMessage: errorMessages.mismatch[lngParam] });
      return false;
    }

    return true;
  };

  validateDriver = () => {
    let lngParam = getLanguage();
    this.setState({
      errorMessage: '',
    });
    let valid = true;
    if (this.state.firstname == undefined || this.state.firstname.trim() == '') {
      if (lngParam == 'en') {
        this.setState({
          driverErrorMessage: 'The first name field is required',
          driverSuccessMessage: '',
        });
      } else {
        //ar
        this.setState({
          driverErrorMessage: 'مطلوب حقل الاسم الأول',
          driverSuccessMessage: '',
        });
      }

      return false;
    }
    if (this.state.lastname == undefined || this.state.lastname.trim() == '') {
      if (lngParam == 'en') {
        this.setState({
          driverErrorMessage: 'The last name field is required',
          driverSuccessMessage: '',
        });
      } else {
        //ar
        this.setState({
          driverErrorMessage: 'حقل الاسم الأخير مطلوب',
          driverSuccessMessage: '',
        });
      }

      return false;
    }
    if (this.state.contactno == undefined || this.state.contactno.trim() == '') {
      if (lngParam == 'en') {
        this.setState({
          driverErrorMessage: 'The contact number field is required',
          driverSuccessMessage: '',
        });
      } else {
        //ar
        this.setState({
          driverErrorMessage: 'مطلوب مجال ContactNo',
          driverSuccessMessage: '',
        });
      }
      return false;
    }
    if (this.state.email == undefined || this.state.email.trim() == '') {
      if (lngParam == 'en') {
        this.setState({ driverErrorMessage: 'The email is required', driverSuccessMessage: '' });
      } else {
        //ar
        this.setState({
          driverErrorMessage: 'مطلوب حقل البريد الإلكتروني',
          driverSuccessMessage: '',
        });
      }

      return false;
    }

    return valid;
  };

  onSave = () => {
    if (this.validate()) {
      const userinfo = getUserInfo();
      if (userinfo !== null) {
        userInstance
          .put(
            `changePassword/${userinfo.CustomerID}`,
            { password: this.state.NewPassword },
            {
              headers: {
                Authorization: `Bearer ${userinfo.access_token}`,
              },
            },
          )
          .then((response) => {
            if (response.data.statusCode == 200) {
              this.setState({
                successMessage: response.data.result,
                NewPassword: '',
                ConfirmPassword: '',
                errorMessage: '',
              });
            } else {
              this.setState({
                errorMessage: response.data.Message,
                successMessage: '',
              });
            }
          })
          .catch((error) => {});
      } else {
        window.location = '/login';
      }
    }
  };

  onUpdate = () => {
    if (localStorage.getItem('userinfo') !== null) {
      if (this.validateDriver()) {
        const userinfo = getUserInfo();
        var data = {
          identity: {
            code: userinfo.CustomerID,
          },
          titleType: 0,
          firstName: this.state.firstname,
          userName: this.state.firstname,
          lastName: this.state.lastname,
          contactNo: this.state.contactno,
          emailId: this.state.email,
          dateOfBirth: this.state.dob,
          countryCode: this.state.nationalitycode,
          countryName: this.state.nationalityname,
          document: [
            {
              type: 'passport',
              image: this.state.passportImg,
              country: 'UAE',
              issuedOn: new Date(),
            },
            {
              type: 'license',
              image: this.state.licenseImg,
              country: 'UAE',
              issuedOn: new Date(),
            },
          ],
        };

        userInstance
          .put(`ui/${data.identity.code}`, { ...data })
          .then((response) => {
            if (response.data.statusCode == 200) {
              window.location.reload();
              this.setState({
                driverSuccessMessage: 'Driver details updated sucessfully...!!!',
                driverErrorMessage: '',
              });
            }
          })
          .catch((error) => {});
      }
    }
  };

  getCountryList = () => {
    bookingInstance
      .get(`countries`)
      .then((response) => {
        this.setState({
          countryList: response.data.result,
        });
      })
      .catch((error) => {});
  };

  render() {
    const years = range(1950, getYear(new Date()) + 1, 1);
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    {
      var initial =
        this.state.profileDetails.Renter != null
          ? this.state.profileDetails.Renter.FirstName.charAt(0)
          : '';
    }

    const { t } = this.props;
    return (
      <>
        <ListingHeader />

        <section className="box-shadow-white-box-section grey-bg py-2">
          <div className="container-fluid">
            <div className="box-shadow-white-box">
              <div className="card" style={{ border: 'none' }}>
                <div className="row no-gutters main-row">
                  <div
                    style={{
                      width: '90px',
                      height: '90px',
                      borderRadius: '50%',
                      background: '#512DA8',
                      fontSize: '35px',
                      color: '#fff',
                      textAlign: 'center',
                      lineHeight: '90px',
                      margin: '20px',
                    }}
                  >
                    <span>{initial}</span>
                  </div>
                  <div className="col-md-6">
                    <div className="card-body card-body1">
                      <h4>Welcome </h4>
                      <h3>
                        {this.state.profileDetails.FirstName != null
                          ? this.state.profileDetails.FirstName
                          : ''}
                      </h3>
                      <p className="card-text">
                        <small className="text-muted">
                          {this.state.profileDetails.Renter != null
                            ? this.state.profileDetails.Renter.Address
                            : ''}
                        </small>
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4 third-col" style={{ textAlign: 'right' }}>
                    <div className="card-body card-body2">
                      <button
                        type="button"
                        className="rounded-bttn color-red ml-3 "
                        onClick={this.onStartBooking}
                      >
                        {t('Start Booking.1')}
                      </button>
                      <p className="card-text account-text">{t('Account Summary.1')}</p>
                      <p className="card-text rent-text">
                        <span>
                          {' '}
                          {t('Rental.1')}: {this.state.Rental}
                        </span>
                        <span className="card-text rent-days">
                          {' '}
                          {t('Rental Days.1')}: {this.state.RentalDays}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="">
                <section className="box-shadow-white-box-section">
                  <div className="container-fluid">
                    <ul
                      className="nav nav-pills mb-0 pb-0 product-nav-tabs"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className="nav-link active show"
                          id="pills-home-tab"
                          data-toggle="pill"
                          href="#pills-home"
                          role="tab"
                          aria-controls="pills-home"
                          onClick={this.onClickMyRental}
                          aria-selected="true"
                        >
                          Rentals
                        </a>
                      </li>
                      <li className="nav-item mr-1 ml-1">
                        <a
                          className="nav-link"
                          id="pills-profile-tab"
                          data-toggle="pill"
                          href="#pills-home"
                          role="tab"
                          aria-controls="pills-profile"
                          onClick={this.onClickMyProfile}
                          aria-selected="false"
                        >
                          Profile
                        </a>
                      </li>
                    </ul>
                    <div className="pt-0 pb-2">
                      <hr></hr>
                    </div>
                  </div>
                </section>
                {this.state.isMyProfileClicked == 0 ? (
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active in"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                    >
                      <div className="box-shadow-white-box-section">
                        <div className="container-fluid">
                          <div className="box-shadow-white-box">
                            <div>
                              <div className="box-title-icon-border d-flex align-items-center mb-4">
                                <span className="box-title-icon-rounded">
                                  <img src={catKeyIcon} alt="Dollar Rentals" />
                                </span>
                                <h4 className="text-16 text-red">
                                  {t('Current & Upcoming Rentals.1')}{' '}
                                </h4>
                              </div>

                              {this.state.profileDetails.CurrentReservations != null
                                ? this.state.profileDetails.CurrentReservations.map((model) => {
                                    return (
                                      <div key={model.Id}>
                                        <div className="row-style" key={model.Id}>
                                          <div className="row">
                                            <div className="col-xl-4 col-lg-6 col-sm-6 col-12">
                                              <h5 className="font-bold mb-1">
                                                {model.VehicleModel.Name}
                                              </h5>
                                              <p className="text-16 opacity-50 text-black">
                                                {t('or Similar.1')} |{' '}
                                                {model.VehicleModel.VehicleClassification != null
                                                  ? model.VehicleModel.VehicleClassificationName
                                                  : ''}
                                              </p>
                                              <div className="img-details-wrap">
                                                <img
                                                  src={
                                                    model.VehicleModel.VehicleImages != null
                                                      ? model.VehicleModel.VehicleImages
                                                      : ''
                                                  }
                                                  alt="Vehicle image"
                                                  width="389"
                                                  height="245"
                                                />
                                              </div>
                                            </div>
                                            <div className="col-xl-2 col-lg-6 col-sm-6 col-12  mt-sm-0 mt-4">
                                              <h5 className="text-16 text-red font-bold mb-3">
                                                {t('Features.1')}
                                              </h5>
                                              <VehicleFeatureBoxVertical
                                                model={model.VehicleModel}
                                              />
                                            </div>
                                            <div className="col-xl-6 col-12 mt-md-0 mt-4 border-xl-left pt-4 pt-xl-0">
                                              <div className="pickup-location-box box-border-style">
                                                <span className="pickup-sm-title ">
                                                  {t('Pickup Location.1')}
                                                </span>
                                                <div className="pickup-location-flex">
                                                  <div className="pickup-location-flex-img">
                                                    <img
                                                      src="./assets/images/dubai-logo.png"
                                                      alt="Dollar Dubai"
                                                    />
                                                  </div>
                                                  <div className="pickup-location-flex-content">
                                                    <h3>
                                                      {model.OutDetail != null
                                                        ? model.OutDetail.Name
                                                        : ''}
                                                    </h3>
                                                    <address>
                                                      {model.OutDetail != null
                                                        ? model.OutDetail.Address
                                                        : ''}
                                                      {model.OutDetail != null
                                                        ? moment(model.OutDetail.Timestamp).format(
                                                            'DD MMM YYYY, h:mm A',
                                                          )
                                                        : ''}
                                                    </address>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="pickup-location-box box-border-style top-border">
                                                <span className="pickup-sm-title mt-4">
                                                  Return Location
                                                </span>
                                                <div className="pickup-location-flex">
                                                  <div className="pickup-location-flex-img">
                                                    <img
                                                      src="./assets/images/dubai-logo.png"
                                                      alt="Dollar Dubai"
                                                    />
                                                  </div>
                                                  <div className="pickup-location-flex-content">
                                                    <h3>
                                                      {model.InDetail != null
                                                        ? model.InDetail.Name
                                                        : ''}
                                                    </h3>
                                                    <address>
                                                      {model.InDetail != null
                                                        ? model.InDetail.Address
                                                        : ''}
                                                      {model.InDetail != null
                                                        ? moment(model.InDetail.Timestamp).format(
                                                            'DD MMM YYYY, h:mm A',
                                                          )
                                                        : ''}
                                                    </address>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="container container-six">
                                                <div className="btn-group flex-wrap">
                                                  <button
                                                    type="button"
                                                    className="rounded-bttn rounded-bttn-transparent ml-3 mt-3"
                                                    onClick={() => {
                                                      this.onViewDetailsClick(model.Id);
                                                    }}
                                                  >
                                                    {t('View Details.1')}
                                                  </button>
                                                  {/* <button type="button" className="rounded-bttn color-red ml-3 mt-3" onClick={() => { this.onCancelClick(model.Id) }}>{t('Modify/Cancel.1')}</button> */}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <br />
                                      </div>
                                    );
                                  })
                                : ''}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="box-shadow-white-box-section">
                        <div className="container-fluid">
                          <div className="box-shadow-white-box">
                            <div className="box-title-icon-border d-flex align-items-center mb-4">
                              <span className="box-title-icon-rounded">
                                <img src={historyIcon} alt="Dollar Rentals" />
                              </span>
                              <h4 className="text-16 text-red">{t('Past Rentals.1')}</h4>
                            </div>
                            {this.state.profileDetails.PastReservations != null
                              ? this.state.profileDetails.PastReservations.map((model) => {
                                  return (
                                    <div key={model.Id}>
                                      <div className="row-style" key={model.Id}>
                                        <div className="row">
                                          <div className="col-xl-3 col-lg-6 col-sm-6 col-12">
                                            <h5 className="font-bold mb-1">
                                              {model.VehicleModel.Name}
                                            </h5>
                                            <p className="text-16 opacity-50 text-black">
                                              {t('or Similar.1')} |{' '}
                                              {model.VehicleModel.VehicleClassification != null
                                                ? model.VehicleModel.VehicleClassificationName
                                                : ''}
                                            </p>
                                            <div className="img-details-wrap">
                                              <img
                                                src={
                                                  model.VehicleModel.VehicleImages != null
                                                    ? model.VehicleModel.VehicleImages
                                                    : ''
                                                }
                                                alt="Vehicle image"
                                                width="300"
                                                height="200"
                                              />
                                            </div>
                                          </div>

                                          <div className="col-xl-3 col-lg-6 col-sm-6 col-12">
                                            <h5
                                              className="font-bold mb-1"
                                              style={{ fontSize: '20px' }}
                                            >
                                              {t('No.1')} #{model.Code != null ? model.Code : ''}
                                            </h5>
                                            <p className=" text-black">
                                              {t('Rental Days.1')} :{' '}
                                              <span>
                                                {model.RentalIn != null
                                                  ? model.RentalIn.RentalDays
                                                  : ''}
                                              </span>
                                            </p>
                                          </div>
                                          <div className="col-xl-3 col-lg-6 col-sm-6 col-12  mt-sm-0 mt-4">
                                            <div className="pickup-location-box box-border-style m-0 pb-2">
                                              <span className="pickup-sm-title ">
                                                {t('Pickup Location.1')}
                                              </span>
                                              <div className="pickup-location-flex">
                                                <div className="pickup-location-flex-content">
                                                  <h3>
                                                    {model.InDetail != null
                                                      ? model.InDetail.Name
                                                      : ''}
                                                  </h3>
                                                  <address>
                                                    {model.InDetail != null
                                                      ? model.InDetail.Address
                                                      : ''}
                                                    {model.InDetail != null
                                                      ? moment(model.InDetail.Timestamp).format(
                                                          'DD MMM YYYY, h:mm A',
                                                        )
                                                      : ''}
                                                  </address>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="col-xl-3 col-lg-6 col-sm-6 col-12 border-xl-left   mt-sm-0 mt-4">
                                            <div className="pickup-location-box box-border-style m-0 pb-2">
                                              <span className="pickup-sm-title ">
                                                Return Location
                                              </span>
                                              <div className="pickup-location-flex">
                                                <div className="pickup-location-flex-content">
                                                  <h3>
                                                    {model.OutDetail != null
                                                      ? model.OutDetail.Name
                                                      : ''}
                                                  </h3>
                                                  <address>
                                                    {model.OutDetail != null
                                                      ? model.OutDetail.Address
                                                      : ''}
                                                    {model.OutDetail != null
                                                      ? moment(model.OutDetail.Timestamp).format(
                                                          'DD MMM YYYY, h:mm A',
                                                        )
                                                      : ''}
                                                  </address>
                                                </div>
                                              </div>

                                              <div className="container container-six">
                                                <div className="btn-group flex-wrap">
                                                  <button
                                                    type="button"
                                                    className="rounded-bttn rounded-bttn-transparent ml-3 mt-3"
                                                    onClick={() => {
                                                      this.onViewDetailsClick(model.Id);
                                                    }}
                                                  >
                                                    {t('View Details.1')}
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <br />
                                    </div>
                                  );
                                })
                              : ''}
                          </div>
                        </div>
                      </div>
                      <FAQSection></FAQSection>
                    </div>
                  </div>
                ) : (
                  <>
                    <section className="box-shadow-white-box-section">
                      <div className="container-fluid">
                        <div className="box-shadow-white-box">
                          <div className="box-title-icon-border d-flex align-items-center mb-4">
                            <span className="box-title-icon-rounded">
                              <img src={driverdetails} alt="Dollar User" width="16" height="16" />
                            </span>
                            <h4 className="text-16 text-red">{t('Driver Details.1')}</h4>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12">
                              <div className="custom-input-type-wrap">
                                <label htmlFor="first-name">{t('First Name.1')}</label>
                                <input
                                  type="text"
                                  placeholder={t('Enter First Name.1')}
                                  id="firstname"
                                  name="firstname"
                                  onChange={this.onChange}
                                  value={this.state.firstname}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 mt-sm-0 mt-4">
                              <div className="custom-input-type-wrap">
                                <label htmlFor="last-name">{t('Last Name.1')}</label>
                                <input
                                  type="text"
                                  placeholder={t('Enter Last Name.1')}
                                  id="lastname"
                                  name="lastname"
                                  onChange={this.onChange}
                                  value={this.state.lastname}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 mt-sm-0 mt-4">
                              <div className="custom-input-type-wrap">
                                <label htmlFor="contact-number">{t('Contact Number.1')}</label>
                                <input
                                  type="text"
                                  placeholder={t('Enter Contact Number.1')}
                                  id="contactno"
                                  name="contactno"
                                  onChange={this.onChange}
                                  value={this.state.contactno}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 mt-4">
                              <div className="custom-input-type-wrap">
                                <label htmlFor="first-name">{t('Email Address.1')}</label>
                                <input
                                  type="text"
                                  placeholder={t('Enter Email Address.1')}
                                  id="email"
                                  name="email"
                                  onChange={this.onChange}
                                  value={this.state.email}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 mt-4">
                              <div className="custom-input-type-wrap">
                                <label htmlFor="date-of-birth">{t('Date Of Birth.1')}</label>
                                <br></br>
                                <DatePicker
                                  renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled,
                                  }) => (
                                    <div
                                      style={{
                                        margin: 10,
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      <button
                                        onClick={decreaseMonth}
                                        disabled={prevMonthButtonDisabled}
                                      >
                                        {'<'}
                                      </button>
                                      <select
                                        value={getYear(date)}
                                        onChange={({ target: { value } }) => changeYear(value)}
                                      >
                                        {years.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>

                                      <select
                                        value={months[getMonth(date)]}
                                        onChange={({ target: { value } }) =>
                                          changeMonth(months.indexOf(value))
                                        }
                                      >
                                        {months.map((option) => (
                                          <option key={option} value={option}>
                                            {option}
                                          </option>
                                        ))}
                                      </select>

                                      <button
                                        onClick={increaseMonth}
                                        disabled={nextMonthButtonDisabled}
                                      >
                                        {'>'}
                                      </button>
                                    </div>
                                  )}
                                  selected={this.state.dob}
                                  onChange={(date) => this.onChangePickUpDate(date)}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 mt-4">
                              <div className="custom-input-type-wrap">
                                <label htmlFor="first-name">{t('Nationality.1')}</label>
                                <br></br>

                                <select
                                  className="filter-box"
                                  onChange={this.onChangeCountryCode}
                                  value={this.state.nationalitycode}
                                >
                                  {this.state.countryList.map((CountryDetail, index) => (
                                    <option value={CountryDetail.code} key={index}>
                                      {CountryDetail.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className="box-title-icon-border d-flex align-items-center mb-4">
                            <span className="box-title-icon-rounded">
                              <img
                                src={documentattachments}
                                alt="Dollar User"
                                width="14"
                                height=""
                              />
                            </span>
                            <h4 className="text-16 text-red">{t('Verification Documents')}</h4>
                          </div>
                          {this.state.showDocAttachement == 1 ? (
                            <div className="row justify-content-lg-start justify-content-center align-items-center">
                              <div className="col-lg-6 col-12">
                                <div className="custom-input-type-file-wrap">
                                  <label>
                                    {t('Drivers License.1')}

                                    {this.state.profileDetails.Renter != null &&
                                    this.state.profileDetails.Renter.DrivingLicense != null &&
                                    this.state.profileDetails.Renter.DrivingLicense.Attachement !=
                                      null &&
                                    this.state.profileDetails.Renter.DrivingLicense.Attachement
                                      .length > 0 ? (
                                      <div className="input-type-file-image-view">
                                        <img
                                          src={
                                            this.state.profileDetails.Renter.DrivingLicense
                                              .Attachement[0].ImageString
                                          }
                                          alt="Dollar User"
                                          onChange={this.onFileChange}
                                          type="file"
                                        />
                                      </div>
                                    ) : (
                                      <span>{t('Upload.1')}</span>
                                    )}
                                    <input
                                      type="file"
                                      placeholder="Upload"
                                      onChange={this.onFileChange}
                                    />
                                    <label>
                                      {this.state.selectedFile != null
                                        ? this.state.selectedFile.name
                                        : ''}
                                    </label>
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-6 col-12 mt-4 mt-lg-0">
                                <div className="custom-input-type-file-wrap">
                                  <label>
                                    {t('Drivers Passport.1')}
                                    {this.state.profileDetails.Renter != null &&
                                    this.state.profileDetails.Renter.Passport != null &&
                                    this.state.profileDetails.Renter.Passport.Attachment != null &&
                                    this.state.profileDetails.Renter.Passport.Attachment.length >
                                      0 ? (
                                      <div className="input-type-file-image-view">
                                        <img
                                          src={
                                            this.state.profileDetails.Renter.Passport.Attachment[0]
                                              .ImageString
                                          }
                                          alt="Dollar User Passport"
                                          onChange={this.onPassportFileChange}
                                          type="file"
                                        />
                                      </div>
                                    ) : (
                                      <span>{t('Upload.1')}</span>
                                    )}

                                    <input
                                      type="file"
                                      placeholder="Upload"
                                      onChange={this.onPassportFileChange}
                                    />

                                    <label>
                                      {this.state.passportSelectedFile != null
                                        ? this.state.passportSelectedFile.name
                                        : ''}
                                    </label>
                                  </label>
                                </div>
                              </div>
                            </div>
                          ) : (
                            ''
                          )}
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12 mt-4">
                              <div className="custom-input-type-wrap">
                                <button
                                  type="button"
                                  className="rounded-bttn rounded-bttn-transparent ml-3 mt-3"
                                  onClick={() => this.onUpdate()}
                                >
                                  {t('Update.1')}
                                </button>
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 mt-4">
                              <div className="custom-input-type-wrap">
                                <br />
                                {this.state.driverSuccessMessage != null &&
                                this.state.driverSuccessMessage.length > 0 ? (
                                  <SuccessAlert successMessage={this.state.driverSuccessMessage} />
                                ) : null}
                                {this.state.driverErrorMessage != null &&
                                this.state.driverErrorMessage.length > 0 ? (
                                  <ErrorAlert errorMessage={this.state.driverErrorMessage} />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="box-shadow-white-box-section">
                      <div className="container-fluid">
                        <div className="box-shadow-white-box">
                          <div className="box-title-icon-border d-flex align-items-center mb-4">
                            <span className="box-title-icon-rounded">
                              <img src={driverdetails} alt="Dollar User" width="16" height="16" />
                            </span>
                            <h4 className="text-16 text-red">{t('Change Password.1')}</h4>
                          </div>
                          <div className="row">
                            <div className="col-lg-4 col-sm-6 col-12 mt-sm-0 mt-3">
                              <div className="custom-input-type-wrap">
                                <label htmlFor="first-name">{t('Enter New Password.1')}</label>
                                <input
                                  type="password"
                                  placeholder={t('Enter New Password.1')}
                                  id="NewPassword"
                                  name="NewPassword"
                                  onChange={this.onChange}
                                  value={this.state.NewPassword}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 mt-sm-0 mt-3">
                              <div className="custom-input-type-wrap">
                                <label htmlFor="last-name">{t('Enter Confirm Password.1')}</label>
                                <input
                                  type="password"
                                  placeholder={t('Enter Confirm Password.1')}
                                  id="ConfirmPassword"
                                  name="ConfirmPassword"
                                  onChange={this.onChange}
                                  value={this.state.ConfirmPassword}
                                />
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 mt-sm-0 mt-3 div-bottom-aligned">
                              <div className="custom-input-type-wrap">
                                <button
                                  type="button"
                                  className="rounded-bttn rounded-bttn-transparent ml-3 mt-4"
                                  onClick={() => this.onSave()}
                                >
                                  {t('Change Password.1')}
                                </button>
                              </div>
                            </div>
                            <div className="col-lg-4 col-sm-6 col-12 mt-sm-0 mt-3">
                              <div className="custom-input-type-wrap">
                                <br />
                                {this.state.successMessage != null &&
                                this.state.successMessage.length > 0 ? (
                                  <SuccessAlert successMessage={this.state.successMessage} />
                                ) : null}
                                {this.state.errorMessage != null &&
                                this.state.errorMessage.length > 0 ? (
                                  <ErrorAlert errorMessage={this.state.errorMessage} />
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>

        <ListingFooter />
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(Profile)), Profile);

import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { tableData } from './constant';

class WhyDollar extends Component {
  render() {
    return (
      <div className="table-wrapper">
        <h6 className="fz-30-black">
          Why Dollar <span>?</span>
        </h6>

        <div className="oz-table">
          <ul className="oz-row">
            <li className="oz-h">Features</li>
            <li className="oz-h">Bank Finance</li>
            <li className="oz-h">Dollar Car Rental</li>
          </ul>
          {tableData.map((value, index) => (
            <ul className="oz-row" key={index}>
              <li className="oz-td">{value.feature}</li>
              <li className="oz-td">{value.bankFinance}</li>
              <li className="oz-td">{value.dollarCarRental}</li>
            </ul>
          ))}
        </div>
      </div>
    );
  }
}

export default withTranslation()(WhyDollar);

export const options = [
  {
    value: '+971',
    label: 'UAE (+971)',
    flag: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/ae.svg',
  },
  {
    value: '+1',
    label: 'USA (+1)',
    flag: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/us.svg',
  },
  {
    value: '+44',
    label: 'UK (+44)',
    flag: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/gb.svg',
  },
  {
    value: '+91',
    label: 'Ind (+91)',
    flag: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/in.svg',
  },
  {
    value: '+61',
    label: 'Aus (+61)',
    flag: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/au.svg',
  },
  {
    value: '+81',
    label: 'Jap (+81)',
    flag: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/jp.svg',
  },
  // Add more countries as needed
];

export const countriesOptions = [
  { value: 'DXB', label: 'Dubai' },
  { value: 'AUH', label: 'Abu Dhabi' },
  { value: 'AJM', label: 'Ajman' },
  { value: 'AIN', label: 'Al Ain' },
  { value: 'FUJ', label: 'Fujairah' },
  { value: 'RAK', label: 'Ras Al Khaimah' },
  { value: 'SHJ', label: 'Sharjah' },
];

export const durationOptions = [
  { value: 'monthly', label: 'Monthly' },
  { value: '12_months', label: 'Yearly' },
];

import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll, scroller } from 'react-scroll';
import documentfile1 from '../assets/documents/CFC Sticker.pdf';
import document1 from '../assets/documents/document11.png';
import award1 from '../assets/images/awards/1.png';
import award2 from '../assets/images/awards/2.png';
import award3 from '../assets/images/awards/3.jpg';
import isoCertificate from '../assets/images/awards/ISO-9001-2015-logo-1.png';
import awardwinnig from '../assets/images/Business/1215166825.jfif';
import dollarredCommunity from '../assets/images/Business/221715247.jfif';
import dollarHunt from '../assets/images/Business/Dollar D Logo.png';
import globalpartner from '../assets/images/Business/know_us1.jfif';
import valueIcon from '../assets/images/core-values.png';
import redCarpet from '../assets/images/dollar-red-carpet.png';
import knowbanner from '../assets/images/Know_us_1.jpg';
import largestwidest from '../assets/images/largest-widest.png';
import missionIcon from '../assets/images/mission-icon.png';
import redPostcard from '../assets/images/redPOstcard.png';
import visionIcon from '../assets/images/vision-icon.png';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import ContactUsBox from './ContactUsBox';
import SocialMediaBox from './SocialMediaBox';
var qs = require('qs');
class KnowUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
  }

  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;
    if (params.path != 'top') {
      scroller.scrollTo(params.path, {
        smooth: 'easeInOutQuint',
      });
    } else {
      scroll.scrollToTop();
    }
    this.setState({ name: params.path });
  };

  onMenuLinkClick = (path) => {
    this.props.history.push(path);
    scroll.scrollToTop();
  };

  render() {
    const { t } = this.props;
    return (
      <>
        {this.state.name == 'top' ? (
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Dollar Car Rental UAE | About Us</title>
              <meta
                name="description"
                content="Everyday, hundreds of tourists & residents in UAE choose Dollar Car Rental. We also takes pride in being associated with leading brands as their total transportation solutions partner. "
              ></meta>
              <meta property="og:title" content="Dollar Car Rental UAE | About Us"></meta>
              <meta property="og:url" content="https://www.dollaruae.com/knowus/top"></meta>
              <meta
                property="og:description"
                content="Everyday, hundreds of tourists & residents in UAE choose Dollar Car Rental. We also takes pride in being associated with leading brands as their total transportation solutions partner. "
              ></meta>
            </Helmet>
          </>
        ) : this.state.name == 'vision' ? (
          <>
            <Helmet>
              <title>Dollar Car Rental UAE | Our Vision & Mission </title>
              <meta
                name="description"
                content="Dollar Car Rental UAE is a Global Brand with a Global Presence. Thousands of people choose us everyday for their own everyday adventures."
              ></meta>
              <meta
                property="og:title"
                content="Dollar Car Rental UAE | Our Vision & Mission "
              ></meta>
              <meta property="og:url" content="https://www.dollaruae.com/knowus/top"></meta>
              <meta
                property="og:description"
                content="Dollar Car Rental UAE is a Global Brand with a Global Presence. Thousands of people choose us everyday for their own everyday adventures."
              ></meta>
            </Helmet>
          </>
        ) : this.state.name == 'whydollar' ? (
          <>
            <Helmet>
              <title>Dollar Car Rental UAE | Why Choose Dollar? </title>
              <meta
                name="description"
                content="Dollar Car Rental UAE is a Global Brand with a Global Presence. Thousands of people choose us everyday for their own everyday adventures!"
              ></meta>
              <meta
                property="og:title"
                content="Dollar Car Rental UAE | Why Choose Dollar? "
              ></meta>
              <meta property="og:url" content="https://www.dollaruae.com/knowus/top"></meta>
              <meta
                property="og:description"
                content="Dollar Car Rental UAE is a Global Brand with a Global Presence. Thousands of people choose us everyday for their own everyday adventures!"
              ></meta>
            </Helmet>
          </>
        ) : this.state.name == 'awardreco' ? (
          <>
            <Helmet>
              <title>Dollar Car Rental UAE | Awards and Recognition </title>
              <meta
                name="description"
                content="We pride ourselves in being a customer-centric brand, a committed employer and a responsible corporation. Have a look at our recognitions across the industry where we continue to leave an imprint!"
              ></meta>
              <meta
                property="og:title"
                content="Dollar Car Rental UAE | Awards and Recognition "
              ></meta>
              <meta property="og:url" content="https://www.dollaruae.com/knowus/top"></meta>
              <meta
                property="og:description"
                content="We pride ourselves in being a customer-centric brand, a committed employer and a responsible corporation. Have a look at our recognitions across the industry where we continue to leave an imprint!"
              ></meta>
            </Helmet>
          </>
        ) : null}
        <ListingHeader />
        {/* <div>
                <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
                    <div className="container-fluid">
                        <div className="box-shadow-white-box-a ">
                            <div className="">
                                <div className="row flex-reversea">
                                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                                        <div className="pickup-location-box">
                                            <h5 className="font-bold mb-3 mt-2 ">Know Us</h5>
                                            <div className="pickup-location-flex">

                                                <div className="pickup-location-flex-content">
                                                    <h3 className="mb-4">Need content for this page, please provide!
                                                    <br/>
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                                        <img src={image} alt="" className="img-fluid rounded-xl" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="box-shadow-white-box-section grey-bg pb-4">
                    <div className="container-fluid">
                        <div className="box-shadow-white-box">
                            <div className="row">
                                <div className="col-lg-12 p-3">
                        <h5 className="font-weight-bold">Need Content</h5>
                        <p className="text_p mt-4">
                        The next time you find yourself at one of Dollar UAE counters, skip the queues and get on the
road faster with the DollarXpress services.
                        </p>
                        <br/>
                        <p className="text_p mt-4">
                        Contact us to know more or better, visit any of our 26+ neighborhood locations to experience one
today
                        </p>
                        <br/>
                        
                    </div>
                  
                            </div>

                        </div>
                    </div>
                </section>

            </div> */}
        <div className="know-us-page py-md-5 py-3  bg-grey">
          <div className="container">
            <div className="border-titlec-content mb-5">
              <h2 className="mb-1">{t('Dollar Car Rental UAE.1')}</h2>
              <p className="font-18 text-grey">
                {t(
                  'A customer-centric brand, a committed employer and a responsible corporation.1',
                )}
              </p>
            </div>
            <div className="row pb-5">
              <div className="col-lg-4 col-md-8 col-12 mx-auto mx-lg-0">
                <img src={knowbanner} className="rounded-xl" alt="Dollar Care Rental UAE"></img>
              </div>
              <div className="col-lg-8 col-12">
                <div className="pl-lg-4 pt-4 pt-lg-0">
                  <p className="font-18 text-grey">
                    {t(
                      'A globally established brand - over 640 worldwide locations across 53 countries, and a fleet size of approximately 200,000 vehicles - the foundation of Dollar Car Rental is built on a philosophy of exceptional customer service. Our attention to customer experience and our high quality fleet of rental vehicles helps make both leisure vacation travel and business travel easier for our customers.1',
                    )}
                  </p>
                  <p className="font-18 text-grey mt-4">
                    {t('In the UAE, Dollar is a franchised operation owned by the prestigious.1')}{' '}
                    <a href="https://www.aaagroup.com/" target="_blank" className="text-red">
                      {t('A.A.Al Moosa Enterprises.1')},
                    </a>{' '}
                    {t(
                      'under the license of Zabeel Rent a Car LLC, since 2004. Today, with locations across the UAE, Dollar Car Rental is one of the regions favorite and leading car rental agency, with multiple awards & recognitions to its name.1',
                    )}
                  </p>
                  <p className="font-18 text-grey mt-4">
                    {t(
                      'Everyday, hundreds of tourists & residents in the UAE choose Dollar Car Rental for their mobility needs. Dollar Car Rental UAE also takes pride in being associated with leading brands in the UAE like Pepsi, Mai Dubai, Pran Foods, NFPC, Aramex, Carrefour, etc. as their total transportation solutions partner, by helping them achieve reduced fleet management costs, improved fleet compliance, customized vehicle-builds, and other business-specific goals.1',
                    )}
                  </p>
                  <p className="font-18 text-grey mt-4">
                    {t(
                      'Our special rental car offers and low everyday rates assist you in keeping your travel plans budget-friendly. Dollar Car Rental has convenient national and worldwide car rental locations to  serve you. In addition, our Dollar Hunt program offers 365 days of assured deals, seasonal benefits & surprise rewards - both online & offline.1',
                    )}
                  </p>
                  <p className="font-18 text-grey mt-4">
                    {t(
                      'Dollar Car Rental UAE has neighborhood and airport car rental locations in Dubai, Abu Dhabi, Sharjah, & Al Ain. To know your nearest location.1',
                    )}{' '}
                    <a onClick={() => this.onMenuLinkClick('/locations')} className="text-red">
                      {t('click here.1')}
                    </a>
                  </p>
                  <p className="font-18 text-grey mt-4">
                    {t(
                      'As an employer, our work culture amongst as a team remains the same as towards our clients - one that of ensuring meaningful experiences, authenticity and joyful relationships. We are an equal opportunity employer and promote innovation, creativity, talent and collective growth. To know about the latest job opportunities in the UAE.1',
                    )}{' '}
                    <a onClick={() => this.onMenuLinkClick('/jobposting')} className="text-red">
                      {t('click here.1')}
                    </a>
                  </p>
                  <p className="font-18 text-grey mt-4">
                    {t('We look forwarding to serving you soon.1')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <section className="bg-grey py-5" id="vision">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-12">
                  <div className="knowUs-featured-wrap">
                    <div className="knowUs-featured-image">
                      <img src={visionIcon} alt="Dollar Vision"></img>
                    </div>
                    <div className="knowUs-featured-content">
                      <h4>{t('Vision.1')}</h4>
                      <p>
                        {t(
                          'Our vision is to become a customer centric company with focus on creating a consistent and sustainable business growth.1',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12 mt-4 mt-lg-0">
                  <div className="knowUs-featured-wrap">
                    <div className="knowUs-featured-image">
                      <img src={missionIcon} alt="Dollar Mission"></img>
                    </div>
                    <div className="knowUs-featured-content">
                      <h4>{t('Mission.1')}</h4>
                      <p>
                        {t(
                          'Our mission is to provide hassle free renting experience to our customers through constant innovation and consistent delivery of quality product and a great customer experience.1',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12 mt-4 mt-lg-0">
                  <div className="knowUs-featured-wrap">
                    <div className="knowUs-featured-image">
                      <img src={valueIcon} alt="Dollar Core Values"></img>
                    </div>
                    <div className="knowUs-featured-content">
                      <h4>Core Values</h4>
                      <p className="text-left">
                        <span className="text-red">●</span>{' '}
                        {t('Committed to give customer a delightful renting experience.1')}{' '}
                        <br></br>
                        <span className="text-red">●</span> {t('Keep it easy and simple.1')}{' '}
                        <br></br>
                        <span className="text-red">●</span> {t('Integrity.1')} <br></br>
                        <span className="text-red">●</span> {t('Innovative.1')}
                        <br></br>
                        <span className="text-red">●</span> {t('Humble.1')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="py-5" id="whydollar">
            <div className="container">
              <h2 className="width-50">
                {t('What makes Dollar your ultimate.1')}{' '}
                <span className="text-red" style={{ display: 'inline' }}>
                  {t('R.1')}
                </span>
                {t('ental.1')}{' '}
                <span className="text-red" style={{ display: 'inline' }}>
                  {t('E.1')}
                </span>
                {t('xperience.1')}{' '}
                <span className="text-red" style={{ display: 'inline' }}>
                  {t('D.1')}
                </span>
                {t('estination.1')}?
              </h2>
              <div className="width-75 pt-md-5 pt-4 pb-0 mx-auto">
                <div className="row align-items-center">
                  <div className="col-lg-4 col-md-5 col-12 mb-4 mb-md-0">
                    <img
                      src={dollarHunt}
                      className="rounded-xl"
                      alt="Dollar Road Trip Partner"
                    ></img>
                  </div>
                  <div className="col-lg-8 col-md-7 col-12">
                    <div className="oddEven-content pl-lg-5 pl-0">
                      <h3 className="font-24">{t('We are your Global Road Trip Partner.1')}</h3>
                      <p className="font-18 text-grey pt-3">
                        {t(
                          'Dollar Car Rental is a Global Brand with a Global Presence. Thousands of people choose us everyday for their own everyday adventures. Be it business trips, a squad on the road, family staycations or even a long drive getaway, our wheels has seen it all.1',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-8 col-md-7 col-12 order-1 order-md-0">
                    <div className="oddEven-content pr-lg-5 pr-0">
                      <h3 className="font-24">{t('Award-winning Customer Experience.1')}</h3>
                      <p className="font-18 text-grey pt-3">{t('We are talking.1')};</p>
                      <ul className="check-box-listing round-checkmark-wrap">
                        <li className="align-items-center">
                          <p className="font-18 text-black">
                            {t(
                              '24*7 Customer Support including Call Centre, Customer & Reservations Services.1',
                            )}
                          </p>
                        </li>
                        <li className="align-items-center mt-3">
                          <p className="font-18 text-black">
                            {t(
                              'Delivery & Pickup of your bookings at your time & location convenience.1',
                            )}
                          </p>
                        </li>
                        <li className="align-items-center mt-3">
                          <p className="font-18 text-black">
                            {t('Roadside Breakdown & Accidental Assistance.1')}
                          </p>
                        </li>
                        <li className="align-items-center mt-3">
                          <p className="font-18 text-black">
                            {t('Multiple locations around UAE for Ease of Access.1')}
                          </p>
                        </li>
                        <li className="align-items-center mt-3">
                          <p className="font-18 text-black">
                            {t('Dedicated Relationship Manager for our Corporate Clientele.1')}
                          </p>
                        </li>
                        <li className="align-items-center mt-3">
                          <p className="font-18 text-black">
                            {t(
                              'Trusted & relied by major brands in the UAE as their mobility partner, every single day.1',
                            )}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 col-12 mb-4 mb-md-0">
                    <img src={awardwinnig} className="rounded-xl" alt="Dollar Award Winning"></img>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-4 col-md-5 col-12 mb-4 mb-md-0">
                    <img src={largestwidest} alt="Largest and Widest"></img>
                  </div>
                  <div className="col-lg-8 col-md-7 col-12">
                    <div className="oddEven-content pl-lg-5 pl-0">
                      <h3 className="font-24">{t('Largest & Widest.1')}</h3>
                      <p className="font-18 text-grey pt-3">
                        {t(
                          'You get to choose from one of UAEs largest and widest fleet. Be it the choice of wheels or quick & easy availability, Dollar Car Rental is your one-stop rental destination. So go ahead, plan that last-minute adventure - and dont you worry about those wheels no more.1',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-8 col-md-7 col-12 order-1 order-md-0">
                    <div className="oddEven-content pr-lg-5 pr-0">
                      <h3 className="font-24">{t('Dollar RED Carpet Services.1')}</h3>
                      <p className="font-18 text-grey pt-3">
                        {t(
                          'Premium services for the celebrity you! Experience our award-winning range of services promising convenience. With doorstep delivery & pickup services, flexible locations of your choice, wide range of fleet options, dedicated customer support managers and multiple counter solutions, a hassle-free seamless car renting is now all yours.1',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 col-12 mb-4 mb-md-0">
                    <img src={redCarpet} alt="Dollar Red Carpet Services"></img>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-4 col-md-5 col-12 mb-4 mb-md-0">
                    <img
                      src={globalpartner}
                      className="rounded-xl"
                      alt="Dollar Car Rental in Dubai, UAE"
                    ></img>
                  </div>
                  <div className="col-lg-8 col-md-7 col-12">
                    <div className="oddEven-content pl-lg-5 pl-0">
                      <h3 className="font-24">{t('Dollar Car Rental in Dubai, UAE.1')}</h3>
                      <p className="font-18 text-grey pt-3">
                        {t(
                          'The best adventures are often the ones planned last minute.. And to enjoy savings while at it? The definite cherry on top! Dollar Hunt is our signature 365 days of offers and surprise deals! Its your go-to destination for hunting UAEs finest car rental discount programs and promotions. Look for our #dollarhunt flash sales & offer of the day to enjoy savings on the road! Or simply ask our counter staff for your personalised surprise deal.1',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-8 col-md-7 col-12 order-1 order-md-0">
                    <div className="oddEven-content pr-lg-5 pr-0">
                      <h3 className="font-24">{t('Dollar RED Community.1')}</h3>
                      <p className="font-18 text-grey pt-3">
                        {t(
                          'You get to be part of our Dollar Red Community. You, us, dollar employees, our stakeholders, our corporate clientele, all of us - The Dollar Red Community. With us, our clients rent wheels to their best road trips & destinations. As team dollar, we strive to provide our best, with your constant feedback & support. As a business organization, we venture out & explore opportunities on the road together. We make things happen, together. We connect. We move. We grow. Each other & in the process, ourselves.1',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 col-12 mb-4 mb-md-0">
                    <img src={dollarredCommunity} alt={t('Dollar RED Community.1')}></img>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-4 col-md-5 col-12 mb-4 mb-md-0">
                    <img src={redPostcard} alt="Dollar Red Postcard"></img>
                  </div>
                  <div className="col-lg-8 col-md-7 col-12">
                    <div className="oddEven-content pl-lg-5 pl-0">
                      <h3 className="font-24">{t('Dollar RED Postcard.1')}</h3>
                      <p className="font-18 text-grey pt-3">
                        {t(
                          'You get to enjoy UAEs most souvenir-worthy loyalty program every time you Dollar Car Rental UAE! Dont forget to look for your Dollar RED Postcard when you step inside your next dollar rental car. These beautifully designed postcards are a mark of your loyalty and promises you assured offers, deep discounts or at times, even a surprise deal.1',
                        )}
                      </p>
                      {/* <p className="font-18 text-grey pt-3">To view our awards & recognitions, <a onClick={() => this.onMenuLinkClick('/KnowUs/vision')} className="text-red">click here.</a></p> */}
                      <p className="font-18 text-grey pt-3">
                        {t('For Press/Media/Analysis.1')}{' '}
                        <a onClick={() => this.onMenuLinkClick('/news')} className="text-red">
                          {t('click here.1')}
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="bg-grey py-5" id="awardreco ">
            <div className="container">
              <div className="text-center">
                <h2>{t('Awards & Recognitions.1')}</h2>
                <p className="font-18 text-grey mt-3">
                  {t(
                    'Our achievements as a customer-centric brand, a committed employer & a responsible corporation.1',
                  )}
                </p>
              </div>
              <div className="row mt-5">
                <div className="col-md-4 col-12">
                  <div className="awards-content text-center">
                    <a href={award2} target="_blank">
                      <img src={award1} alt="Awards And Recognitions"></img>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 col-12 mt-5 mt-md-0">
                  <div className="awards-content text-center">
                    <a href={documentfile1} target="_blank">
                      <img src={document1} alt="Dollar Documents"></img>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 col-12 mt-5 mt-md-0">
                  <div className="awards-content text-center">
                    <a href={award3} target="_blank">
                      <img src={isoCertificate} alt="Dollar IOS Certificate"></img>
                    </a>
                  </div>
                </div>
                {/* <div className="col-3">
                                <div className="awards-content text-center">
                                    <a href={award5} target="_blank">
                                        <img src={award5}></img>
                                    </a>
                                    
                                </div>
                            </div> */}
              </div>
              {/* <div className="row mt-5">
                            <div className="col-4">
                                <div className="awards-content text-center">
                                    <a href={documentfile1} target="_blank">
                                    <img src={document1}></img>
                                    </a>
                                    
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="awards-content text-center">
                                    <a  href={documentfile2} target="_blank">
                                    <img src={document2}></img>
                                    </a>
                                    
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="awards-content text-center">
                                    <a href={documentfile3} target="_blank">
                                    <img src={document3} ></img>
                                    </a>
                                    
                                </div>
                            </div>
                            
                        </div> */}
            </div>
          </section>

          <SocialMediaBox></SocialMediaBox>
          <ContactUsBox subHeading="" heading={t('Do you have further Question.1')}></ContactUsBox>
          {/* <section className="what-new-sectio ptb-80">
                        <div className="container">
                            <h2>Newsroom</h2>
                            <div className="row mt-5">
                                <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0">
                                    <div className="what-new-box">
                                        <a href="" className="what-new-link"></a>
                                        <img src="http://demo.fleetlet.com//DocumentPath/026c2d26-cb2e-461f-bac6-6ed65ffc02e3/news/What_is_Lorem_Ipsum_2342_NewsThumbnail_8057.jpeg"></img>
                                        <div className="what-new-box-more-content">
                                            <p>Dollar Car Rental UAE have launched its flexible offerings in UAE with hourly car rentals. The car</p>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0">
                                    <div className="what-new-box">
                                        <a href="" className="what-new-link"></a>
                                        <img src="http://demo.fleetlet.com//DocumentPath/026c2d26-cb2e-461f-bac6-6ed65ffc02e3/news/What_is_Lorem_Ipsum_2342_NewsThumbnail_8057.jpeg"></img>
                                        <div className="what-new-box-more-content">
                                            <p>Dollar Car Rental UAE have launched its flexible offerings in UAE with hourly car rentals. The car</p>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 mt-4 mt-lg-0">
                                    <div className="what-new-box">
                                        <a href="" className="what-new-link"></a>
                                        <img src="http://demo.fleetlet.com//DocumentPath/026c2d26-cb2e-461f-bac6-6ed65ffc02e3/news/What_is_Lorem_Ipsum_2342_NewsThumbnail_8057.jpeg"></img>
                                        <div className="what-new-box-more-content">
                                            <p>Dollar Car Rental UAE have launched its flexible offerings in UAE with hourly car rentals. The car</p>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(KnowUs)), KnowUs);

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import moengage from '@moengage/web-sdk';
import moment from 'moment';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import schedule06 from '../assets/images/calender-icoon.svg';
import infothin from '../assets/images/iconmonstr-info-thin.svg';
import modifyBooking from '../assets/images/modify-booking-icon.svg';
import printing from '../assets/images/print-icoon.svg';
import { bookingInstance } from '../axios/axiosInstance';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getCancellationInformation, getClearCancellationInformation } from '../Common/Util';
import VehicleFeatureBoxVertical from '../Common/VehicleFeatureBoxVertical';
import EmailReservation from './EmailReservation';
import RentalPolicies from './RentalPolicies';
import ReservationCancelPopUp from './ReservationCancelPopUp';
import ViewIncludeLongText from './ViewIncludeLongText';
import ViewInsuranceDetails from './ViewInsuranceDetails';

class ReservationConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Data: {},
      ReservationConfirmId: '',
      VehicleName: '',
      ReservationStatus: '',
      showCancelViewDetails: false,
      PaidCharge: 0,
      showExtrasDetails: false,
      LongTextShow: '',
      showShare: false,
      cancellationStatus: false,
      PromoCode: '',
      CancellationData: {},
      loading: false,
      rentalDays: 0,
      rentalType: 0,
      alertModal: '',
    };
  }

  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;
    this.ReservationDetails(params.index);

    setTimeout(() => {
      const cancellationInfo = getCancellationInformation();
      if (cancellationInfo != null) {
        this.state.cancellationStatus = cancellationInfo.data;
        getClearCancellationInformation();
      }
    }, 100);
    scroll.scrollToTop();
  };

  toogleViewCancelPopUp = (showHide) => {
    // for managing the old booking
    if (this.state.Data?.Identity?.Code.toLowerCase().startsWith('dlr'))
      return this.setState({ alertModal: 'contact our customer care at 800 Dollar' });
    // for managing the old booking

    this.setState({ loading: showHide });
    if (showHide) {
      const {
        match: { params },
      } = this.props;
      bookingInstance
        .get(`cancellation:${params.index}`)
        .then((response) => {
          setTimeout(() => {
            this.setState({ CancellationData: response.data.result });
          }, 0);

          setTimeout(() => {
            this.setState({ showCancelViewDetails: showHide });
          }, 0);
        })
        .catch((error) => {});
    } else {
      this.setState({ showCancelViewDetails: showHide });
    }
  };

  toggleViewShare = (showHide) => {
    this.setState({ showShare: showHide });
  };

  toogleViewIncludeLongTextPopUp = (showHide, LongText) => {
    this.setState({ showExtrasDetails: showHide, LongTextShow: LongText });
  };

  ReservationDetails = (Id) => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    bookingInstance
      .get(`/${Id}?countryCode=${code}`)
      .then((response) => {
        let paidCharge = 0;
        const responseData = response.data.result.data;
        moengage.add_email(responseData.Renter.EmailId);
        responseData.RentalRateDetail.forEach((element) => {
          paidCharge = paidCharge + element.TotalAmt;
        });

        this.setState({
          Data: responseData,
          PaidCharge: paidCharge,
          rentalDays: responseData.RentalIn.RentalDays,
        });

        if (responseData.ReservationStatus == 0) this.setState({ ReservationStatus: 'Pending' });
        else if (responseData.ReservationStatus == 1)
          this.setState({ ReservationStatus: 'Rejected' });
        else if (responseData.ReservationStatus == 2)
          this.setState({ ReservationStatus: 'Confirmed' });
        else if (responseData.ReservationStatus == 3)
          this.setState({ ReservationStatus: 'Internal Approval Pending' });
        else this.setState({ cancellationStatus: true, ReservationStatus: 'Cancelled' });

        if (this.state.Data.RentalIn.PromoCode != null && this.state.Data.RentalIn.PromoCode != '')
          this.setState({ PromoCode: this.state.Data.RentalIn.PromoCode });
        this.setState({ rentalType: this.state.Data.RentalType === 'Flexi' ? 3 : 0 });
      })
      .catch((error) => {});
    this.setState({ ReservationConfirmId: Id });
  };

  onPrintClick = () => {
    window.print();
  };

  onAddToCalenderClick = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    bookingInstance
      .get(`calendar/${this.state.ReservationConfirmId}?countryCode=${code}`)
      .then(({ data }) => {
        window.open(data.result.data.toString(), '_blank');
      });
  };

  onShareFeedbackClick = () => {
    this.props.history.push(`/feedback/${this.state.Data.Identity.Code}`);
  };

  onRetryPaymentClick = () => {
    bookingInstance.post(`payment/retry/${this.state.ReservationConfirmId}`).then((response) => {
      window.location = response.data.result.toString();
    });
  };

  toogleViewInsurancePopUp = (showHide, insuranceDescriptionToShow) => {
    this.setState({
      showInsuranceViewDetails: showHide,
      insuranceDesriptionToShow: insuranceDescriptionToShow,
    });
  };

  onStartAnotherReservation = () => {
    this.props.history.push('/StartaReservation');
  };

  onModifyClick = () => {
    // for managing the old booking
    if (this.state.Data.Identity?.Code.toLowerCase().startsWith('dlr'))
      return this.setState({ alertModal: 'contact our customer care at 800 Dollar' });
    // for managing the old booking

    var BookingInfo = [
      {
        CheckOutLocationId: this.state.Data.RentalIn.CheckOutBranchID,
        CheckInLocationId: this.state.Data.RentalIn.CheckInBranchID,
        PickUpDate: this.state.Data.RentalIn.CheckOutOn,
        Daily: this.state.Data.RentalIn.Daily,
        Days: this.state.Data.RentalRateDetail.Days,
        ReturnDate: this.state.Data.RentalIn.CheckInOn,
        pickupLocationName: this.state.Data.OutDetail.Name,
        dropOffLocationName: this.state.Data.InDetail.Name,
        pickupLocationAddress: this.state.Data.OutDetail.Address,
        dropOffLocationAddress: this.state.Data.InDetail.Address,
        promocode: '',
        deliveryState: this.state.Data.OutDetail.DeliveryPickupStateName,
        deliveryCode: this.state.Data.OutDetail.DeliveryPickupStateCode,
        deliveryAddress: this.state.Data.OutDetail.DeliveryPickupAddress,
        returnState: this.state.Data.InDetail.DeliveryPickupStateName,
        returnCode: this.state.Data.InDetail.DeliveryPickupStateCode,
        returnAddress: this.state.Data.InDetail.DeliveryPickupAddress,
        RentalDays: this.state.rentalDays,
        ModifiedReservationID: this.state.ReservationConfirmId,
      },
    ];
    localStorage.setItem('BookingInfo', JSON.stringify(BookingInfo));

    if (this.state.rentalType == 3) window.location = '/monthlybooking';
    else window.location = '/booking';
  };

  onShareClick = () => {
    this.setState({
      showShare: true,
    });
  };

  render() {
    const { t } = this.props;
    let onlinePaymentStatus =
      this.state.Data.OnlinePaymentDetail != null &&
      this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 1
        ? 'Success'
        : 'Last Payment Failed';

    return (
      <>
        <ListingHeader></ListingHeader>

        <div className="minH100 bg-grey reservation-confirmation">
          <section className="congrts-scnpg-section">
            <div className="container">
              <div className="congrts-title-wrap">
                {this.state.cancellationStatus ? (
                  <div>
                    <h1>{t('Reservation Canceled.1')}</h1>
                    <p>
                      {t('This is to confirm your reservation.1')}{' '}
                      {<span>#{this.state.Data.Code?.length ? this.state.Data.Code : ''}</span>}{' '}
                      {t('has been canceled.1')}
                    </p>
                  </div>
                ) : (
                  <div>
                    <h1>
                      {this.state.ReservationStatus == 'Confirmed'
                        ? 'Congratulations!'
                        : this.state.ReservationStatus}
                    </h1>
                    {this.state.ReservationStatus == 'Pending' ? (
                      <p>{t('Your booking pending for confirmation.1')}</p>
                    ) : (
                      <p>{t('Your booking has been confirmed.1')}</p>
                    )}
                  </div>
                )}
              </div>

              <div className="congrts-scnpg-wrap">
                <div className="congrts-scnpg-red-strip">
                  <h2>{t('Booking Summary.1')}</h2>
                  <div className="congrts-scnpg-red-right">
                    <div className="box-title-icon-border d-flex align-items-center">
                      <span
                        className="box-title-icon-rounded"
                        style={{ cursor: 'pointer' }}
                        onClick={this.onPrintClick}
                      >
                        <img src={printing} alt="Dollar Print" width="21" height="" />
                      </span>
                      <h4 style={{ cursor: 'pointer' }} onClick={this.onPrintClick}>
                        {t('Print.1')}
                      </h4>
                    </div>
                    <div className="box-title-icon-border d-flex align-items-center">
                      <span
                        className="box-title-icon-rounded"
                        style={{ cursor: 'pointer' }}
                        onClick={this.onAddToCalenderClick}
                      >
                        <img src={schedule06} alt="Dollar Calendar" width="24" height="16" />
                      </span>
                      <h4 style={{ cursor: 'pointer' }} onClick={this.onAddToCalenderClick}>
                        {t('Add To Calendar.1')}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="booking-summary-section">
                  <div className="booking-summary-left">
                    <div className="booking-summary-left-top">
                      <div className="img-details-wrap">
                        <img
                          src={
                            this.state.Data?.VehicleModel?.VehicleImages?.length
                              ? this.state.Data.VehicleModel.VehicleImages[0]
                              : this.state.Data?.VehicleModel?.VehicleImages?.FrontImageAttachment
                                  .ImageString
                          }
                          alt="Vehicle Image"
                          width="389"
                          height="245"
                        />
                      </div>
                      <div className="rvrsn-title-wrap-box">
                        <div className="rvrsn-title-left">
                          <h5 className="font-bold mb-1">
                            {this.state.Data.VehicleModel?.Name?.length
                              ? this.state.Data.VehicleModel.Name
                              : ''}
                          </h5>
                          <p className="text-14 opacity-50 text-black">
                            {t('or Similar.1')}
                            {this.state.Data.VehicleModel?.ClassificationName?.length ? (
                              <> | {this.state.Data.VehicleModel.ClassificationName}</>
                            ) : (
                              ''
                            )}
                            {this.state.Data.VehicleModel?.ModelYear?.length ? (
                              this.state.Data.VehicleModel?.ModelYear ? (
                                <>
                                  {' '}
                                  |{' '}
                                  <b>
                                    {' '}
                                    {t('Model Year.1')} - {this.state.Data.VehicleModel.ModelYear}{' '}
                                  </b>{' '}
                                </>
                              ) : null
                            ) : null}
                          </p>
                        </div>
                        <div className="rvrsn-title-right">
                          <h5 className="rsnrn-number" style={{ display: 'inline-block' }}>
                            {t('Reservation Number.1')}:
                            <span>
                              {this.state.Data?.Code?.length
                                ? this.state.Data.Code
                                : this.state.Data?.Identity?.Code}
                            </span>
                          </h5>
                        </div>
                      </div>

                      <div className="featture-box-wrap">
                        <h5 className="text-18 font-bold">{t('Features.1')}</h5>
                        <VehicleFeatureBoxVertical
                          model={this.state.Data.VehicleModel ? this.state.Data.VehicleModel : ''}
                        />
                        <ul className="check-box-listing mt-0">
                          {this.state.Data.BookingIncluded?.length
                            ? this.state.Data.BookingIncluded.map((include, index) => {
                                return (
                                  <li>
                                    <span>
                                      {include.ShortText}
                                      <a
                                        className="text-16 text-red font-bold d-block"
                                        onClick={() =>
                                          this.toogleViewIncludeLongTextPopUp(
                                            true,
                                            include.LongText,
                                          )
                                        }
                                      >
                                        <img alt="Sold-Out-Transparent" src={infothin} />
                                      </a>
                                    </span>
                                  </li>
                                );
                              })
                            : null}
                        </ul>
                      </div>

                      <div className="driver-details-wrap" id="include">
                        <h4>{t('Driver Details.1')}</h4>

                        <div className="row">
                          <div className="col-12">
                            <div className="row" id="labelcust">
                              <div className="col-12">
                                <div className="custom-input-type-wrap">
                                  <label htmlFor="first-name">{t('Full Name.1')}:</label>
                                  <p>
                                    {this.state.Data.Renter?.FirstName?.length
                                      ? this.state.Data.Renter.FirstName +
                                        ' ' +
                                        this.state.Data.Renter.LastName
                                      : ''}
                                  </p>
                                </div>
                              </div>

                              <div className="col-12 mt-2">
                                <div className="custom-input-type-wrap">
                                  <label htmlFor="contact-number">{t('Phone Number.1')}:</label>
                                  <p>
                                    {this.state.Data.Renter?.ContactNo?.length
                                      ? this.state.Data.Renter.ContactNo
                                      : ''}
                                  </p>
                                </div>
                              </div>

                              <div className="col-12 mt-2">
                                <div className="custom-input-type-wrap">
                                  <label htmlFor="first-name">{t('Nationality.1')}:</label>
                                  <br></br>
                                  <p>
                                    {this.state.Data.Renter?.NationalityName?.length
                                      ? this.state.Data.Renter.NationalityName
                                      : ''}
                                  </p>
                                </div>
                              </div>
                              <div className="col-12 mt-2">
                                <div className="custom-input-type-wrap">
                                  <label htmlFor="first-name">{t('Email Address.1')}:</label>
                                  <p>
                                    {this.state.Data.Renter?.EmailId?.length
                                      ? this.state.Data.Renter.EmailId
                                      : ''}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.props.match.params.index2 == 1 ? (
                            <div className="col-12">
                              {' '}
                              {this.state.ReservationStatus == 'Cancelled' ? (
                                ''
                              ) : (
                                <a
                                  href=""
                                  className="rounded-bttn  color-red mt-3 mb-3 d-none"
                                  title="Book &amp; Pay Online"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18"
                                    height="18"
                                    className="mr-2"
                                    viewBox="0 0 20.881 22.273"
                                  >
                                    <g transform="translate(4.872)">
                                      <path
                                        className="a"
                                        d="M138.962,5.806,134.09.238a.694.694,0,0,0-1.047,0l-4.872,5.568a.7.7,0,0,0,.523,1.154h2.784v9.048a.7.7,0,0,0,.7.7h2.784a.7.7,0,0,0,.7-.7V6.96h2.784a.7.7,0,0,0,.523-1.154Z"
                                        transform="translate(-127.998)"
                                      />
                                    </g>
                                    <g transform="translate(0 15.312)">
                                      <path
                                        className="a"
                                        d="M34.1,352v4.176H18.784V352H16v5.568a1.392,1.392,0,0,0,1.392,1.392h18.1a1.391,1.391,0,0,0,1.392-1.392V352Z"
                                        transform="translate(-16 -352)"
                                      />
                                    </g>
                                  </svg>
                                  {t('Upload Document.1')}
                                </a>
                              )}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="booking-summary-right">
                    <div className="link-booking-bttn-wrap">
                      {this.state.Data.IsAllowModification == true ? (
                        <a
                          className="red-bttn"
                          title="Book &amp; Pay Later"
                          onClick={this.onModifyClick}
                        >
                          <img src={modifyBooking} />
                          {t('Modify Reservation.1')}
                        </a>
                      ) : (
                        ''
                      )}

                      {this.state.Data.IsAllowCancellation == true ? (
                        <a
                          className="grey-bttn"
                          title="Book &amp; Pay Online"
                          onClick={() => this.toogleViewCancelPopUp(true)}
                        >
                          {t('Cancel Reservation.1')}
                        </a>
                      ) : (
                        ''
                      )}
                    </div>
                    <div className="pickup-location-box--wrap">
                      <div className="pickup-location-box">
                        <div className="pickup-location-dots">
                          <i className="dots"></i>
                          <span className="pickup-sm-title">{t('Pickup Location.1')}</span>
                          <h3 className="StationDetails__headline">
                            {this.state.Data.OutDetail?.Name?.length
                              ? this.state.Data.OutDetail.Name
                              : ''}
                          </h3>
                          <p className="StationDetails__subline">
                            {this.state.Data.OutDetail?.Address?.length
                              ? this.state.Data.OutDetail.Address
                              : ''}
                          </p>
                        </div>
                        <div className="pickup-location-dots">
                          <i className="dots"></i>
                          <span>{t('Pickup Date & Time.1')}</span>
                          <p className="StationDetails__subline">
                            {this.state.Data.OutDetail?.Timestamp?.length
                              ? moment(this.state.Data.OutDetail.Timestamp).format(
                                  'DD MMM YYYY, HH:mm',
                                )
                              : ''}
                          </p>
                          {this.state.Data.OutDetail?.DeliveryPickupAddress?.length ? (
                            <span className="delivery-pickup-box">
                              {t('Delivery.1')} :{' '}
                              <span>
                                {this.state.Data.OutDetail.DeliveryPickupAddress +
                                  ', ' +
                                  this.state.Data.OutDetail.DeliveryPickupStateName}
                              </span>
                            </span>
                          ) : null}
                        </div>
                      </div>
                      {this.state.Data.RentalType == 3 ? (
                        <>
                          <div className="pickup-location-box">
                            <div className="pickup-location-dots">
                              <i className="dots"></i>
                              <span>{t('Return Date & Time.1')}</span>
                              <p className="StationDetails__subline">
                                {this.state.Data.InDetail?.Timestamp?.length != null
                                  ? moment(this.state.Data.InDetail.Timestamp).format(
                                      'DD MMM YYYY, HH:mm',
                                    )
                                  : ''}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="pickup-location-box">
                            <div className="pickup-location-dots">
                              <i className="dots"></i>
                              <span className="pickup-sm-title">{t('Return Location.1')}</span>
                              <h3 className="StationDetails__headline">
                                {this.state.Data.InDetail?.Name?.length
                                  ? this.state.Data.InDetail.Name
                                  : ''}
                              </h3>
                              <p className="StationDetails__subline">
                                {this.state.Data.InDetail?.Address?.length
                                  ? this.state.Data.InDetail.Address
                                  : ''}
                              </p>
                            </div>
                            <div className="pickup-location-dots">
                              <i className="dots"></i>
                              <span>{t('Return Date & Time.1')}</span>
                              <p className="StationDetails__subline">
                                {this.state.Data.InDetail?.Timestamp?.length
                                  ? moment(this.state.Data.InDetail.Timestamp).format(
                                      'DD MMM YYYY, HH:mm',
                                    )
                                  : ''}
                              </p>
                              {this.state.Data.InDetail?.DeliveryPickupAddress?.length ? (
                                <span className="delivery-pickup-box">
                                  {t('Collection.1')}:{' '}
                                  <span>
                                    {this.state.Data.InDetail.DeliveryPickupAddress +
                                      ', ' +
                                      this.state.Data.InDetail.DeliveryPickupStateName}
                                  </span>
                                </span>
                              ) : null}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="confirmBooking-detail-box">
                      {this.state.Data.RentalType == 3 ? (
                        <>
                          <div className="confirmBookingTitle border-bottom">
                            <h3>
                              {this.state.Data.RentalIn.SubscriptionMonth > 0 ? (
                                <> {t('Total Months.1')}</>
                              ) : (
                                <> {t('Total Month.1')}</>
                              )}
                            </h3>
                            <h3 className="text-red">
                              {this.state.Data.RentalIn.SubscriptionMonth}
                              {this.state.Data.RentalIn.SubscriptionMonth > 0 ? (
                                <> {t('Months.1')}</>
                              ) : (
                                <> {t('Month.1')}</>
                              )}
                            </h3>
                          </div>
                          <div className="confirmBookingTitle border-bottom">
                            <h3>{t('Allowed KM.1')}</h3>
                            <h3 className="text-red">
                              {this.state.Data.RentalIn.SubscriptionKm} KMS | Month
                            </h3>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="confirmBookingTitle">
                            <h3>{t('Total Days.1')}</h3>
                            {this.state.rentalDays > 1 ? (
                              <h3 className="text-red">
                                {this.state.rentalDays} {t('days.1')}
                              </h3>
                            ) : (
                              <h3 className="text-red">
                                {this.state.rentalDays} {t('day.1')}
                              </h3>
                            )}
                          </div>
                        </>
                      )}
                      {this.state.Data.RentalRateDetail?.length
                        ? this.state.Data.RentalRateDetail.map((model) => {
                            return (
                              <div className="confirmBookingTitle">
                                <h4>
                                  {this.state.Data.RentalRateDetail?.length ? model.Item : ''}
                                  {this.state.Data.RentalRateDetail?.length ? (
                                    <span>{model.Description}</span>
                                  ) : (
                                    ''
                                  )}
                                </h4>
                                <h4 className="text-red">
                                  {t('AED.1')}{' '}
                                  {this.state.Data.RentalRateDetail?.length
                                    ? model.SubTotal.toFixed(2)
                                    : ''}
                                </h4>
                              </div>
                            );
                          })
                        : ''}
                      <div className="confirmBookingTitle">
                        <h4>{t('Sub Total.1')}</h4>
                        <h4 className="text-red">
                          {t('AED.1')}{' '}
                          {this.state.Data.SubTotal != null
                            ? this.state.Data.SubTotal.toFixed(2)
                            : null}
                        </h4>
                      </div>
                      <div className="confirmBookingTitle">
                        <h4>{t('Tax Total.1')}</h4>
                        <h4 className="text-red">
                          {t('AED.1')}{' '}
                          {this.state.Data.TotalTax != null
                            ? this.state.Data.TotalTax.toFixed(2)
                            : null}
                        </h4>
                      </div>
                      <div className="confirmBookingTitle border-top">
                        <h2>{t('Grand Total.1')}</h2>
                        <h2 className="text-red">
                          {t('AED.1')}{' '}
                          {this.state.Data != null && this.state.Data.TotalAmount != null
                            ? this.state.Data.TotalAmount.toFixed(2)
                            : ''}
                          &#8236;
                        </h2>
                      </div>
                      <div className="confirmBookingTitle">
                        <h4>{t('Payment Mode.1')} :</h4>
                        <h4 className="text-red">
                          {this.state.Data.RentalIn != null &&
                          this.state.Data.RentalIn.PaymentStatus == 0 ? (
                            <span>&nbsp; {t('Pay at Counter.1')}</span>
                          ) : this.state.Data.OnlinePaymentDetail != null &&
                            this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 1 ? (
                            <span>&nbsp; {t('Paid Online.1')} </span>
                          ) : null}
                          {this.state.Data.RentalIn != null &&
                          this.state.Data.RentalIn.PaymentStatus == 0 ? null : this.state.Data
                              .OnlinePaymentDetail != null &&
                            this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 1 ? (
                            this.state.Data.ModificationCharge != null ? null : (
                              <span>&nbsp; {onlinePaymentStatus}</span>
                            )
                          ) : (
                            <>
                              <span>&nbsp; {onlinePaymentStatus}</span>&nbsp;
                              {/* <h2
                                className="text-16 text-danger font-bold"
                                style={{ cursor: 'pointer' }}
                                onClick={this.onRetryPaymentClick}
                              >
                                {t('Retry.1')}
                              </h2> */}
                            </>
                          )}
                        </h4>
                      </div>

                      {this.state.Data.OnlinePaymentDetail != null &&
                      this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 1 &&
                      this.state.Data.ModificationCharge.AmountToRefund > 0 ? (
                        <>
                          <div className="confirmBookingTitle">
                            <h4 className="text-red">{t('Refund Amount.1')} :</h4>
                            <h4 className="text-red">
                              {
                                <>
                                  {
                                    <div>
                                      {t('AED.1')}{' '}
                                      {this.state.Data.ModificationCharge.AmountToRefund}
                                    </div>
                                  }
                                </>
                              }
                              <>
                                <br></br>
                              </>
                            </h4>
                          </div>
                        </>
                      ) : null}

                      {this.state.Data.OnlinePaymentDetail != null &&
                      this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 1 &&
                      this.state.Data.ModificationCharge.AmountToPay > 0 ? (
                        <>
                          <div className="confirmBookingTitle">
                            <h4 className="text-red">{t('Amount To Pay.1')} :</h4>
                            <h4 className="text-red">
                              {
                                <div>
                                  {t('AED.1')} {this.state.Data.ModificationCharge.AmountToPay}{' '}
                                </div>
                              }
                              <>
                                <br></br>
                              </>
                            </h4>
                          </div>
                        </>
                      ) : null}
                      {this.state.Data.OnlinePaymentDetail != null &&
                      this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 2 ? (
                        <div className="confirmBookingTitle">
                          <h4>{t('Payment Status.1')} :</h4>
                          <h4 className="text-red">
                            {this.state.Data.OnlinePaymentDetail != null &&
                            this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 2 ? (
                              <span>
                                &nbsp; {this.state.Data.OnlinePaymentDetail.PaymentMessage}{' '}
                              </span>
                            ) : null}
                            <>
                              <br></br>
                            </>
                          </h4>
                        </div>
                      ) : null}

                      {this.state.Data.RentalIn != null &&
                      this.state.Data.RentalIn.PaymentStatus == 1 &&
                      this.state.Data.OnlinePaymentDetail != null &&
                      this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 1 &&
                      this.state.Data.CancellationCharge != null &&
                      this.state.Data.CancellationCharge.AmountCancellationCharge == 0 ? (
                        <div className="confirmBookingTitle">
                          <h4>Paid Amount : </h4>
                          {this.state.Data.RentalIn != null &&
                          this.state.Data.RentalIn.PaymentStatus == 1 &&
                          this.state.Data.OnlinePaymentDetail != null &&
                          this.state.Data.OnlinePaymentDetail.OnlinePayStatus == 1 ? (
                            <h4 className="text-red">
                              {this.state.Data.ModificationCharge != null &&
                              this.state.Data.ModificationCharge.AmountPaid > 0 ? (
                                <div>
                                  {t('AED.1')}{' '}
                                  {this.state.Data.ModificationCharge.AmountPaid.toFixed(2)}&#8236;
                                </div>
                              ) : (
                                <div>
                                  {t('AED.1')}{' '}
                                  {this.state.Data != null && this.state.Data.TotalAmount != null
                                    ? this.state.Data.TotalAmount.toFixed(2)
                                    : ''}
                                  &#8236;
                                </div>
                              )}
                            </h4>
                          ) : null}
                        </div>
                      ) : null}
                      {this.state.Data.RentalIn != null &&
                      this.state.Data.RentalIn.PaymentStatus == 0 ? null : this.state.Data
                          .CancellationCharge != null &&
                        this.state.Data.CancellationCharge.AmountCancellationCharge == 0 ? (
                        <div className="confirmBookingTitle">
                          <h4>{t('Pay Ref No.1')} : </h4>
                          <h4 className="text-red mt-2">
                            {this.state.Data.OnlinePaymentDetail != null
                              ? this.state.Data.OnlinePaymentDetail.OnlinePayRefNo == null ||
                                this.state.Data.OnlinePaymentDetail.OnlinePayRefNo == ''
                                ? null
                                : '#' + this.state.Data.OnlinePaymentDetail.OnlinePayRefNo
                              : null}
                          </h4>
                        </div>
                      ) : null}
                      {
                        <ul className="booking-details-listing">
                          {this.state.Data.CancellationCharge != null &&
                          this.state.Data.CancellationCharge.AmountCancellationCharge > 0 ? (
                            <li className="mt-3">
                              <div className="booking-details-listing-left text-18 font-hel-bold text-red">
                                {t('Cancellation Charges.1')}
                              </div>
                              <div className="booking-details-listing-right text-red">
                                {t('AED.1')}{' '}
                                <span className="h4">
                                  {this.state.Data.CancellationCharge.AmountCancellationCharge.toFixed(
                                    2,
                                  )}
                                </span>
                              </div>
                            </li>
                          ) : null}
                          {this.state.Data.CancellationCharge != null &&
                          this.state.Data.CancellationCharge.AmountToRefund > 0 ? (
                            <li className="mt-3">
                              <div className="booking-details-listing-left text-18 font-hel-bold text-red">
                                {t('Refund Amount.1')}
                              </div>
                              <div className="booking-details-listing-right text-red">
                                {t('AED.1')}{' '}
                                <span className="h4">
                                  {this.state.Data.CancellationCharge != null
                                    ? this.state.Data.CancellationCharge.AmountToRefund.toFixed(2)
                                    : null}
                                </span>
                              </div>
                            </li>
                          ) : null}
                          {this.state.Data.CancellationCharge != null &&
                          this.state.Data.CancellationCharge.AmountPaid > 0 ? (
                            <li className="mt-3">
                              <div className="booking-details-listing-left text-18 font-hel-bold text-red">
                                {t('Amount Paid.1')}
                              </div>
                              <div className="booking-details-listing-right text-red">
                                {t('AED.1')}{' '}
                                <span className="h4">
                                  {this.state.Data.CancellationCharge != null
                                    ? this.state.Data.CancellationCharge.AmountPaid.toFixed(2)
                                    : null}
                                </span>
                              </div>
                            </li>
                          ) : null}
                        </ul>
                      }
                    </div>

                    <h4 className="paymode">{/* <span>Pay at Counter</span> */}</h4>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="rental-policy-confirm bg-white">
            <div className="container">
              <RentalPolicies rentalType={this.state.rentalType}></RentalPolicies>
            </div>
          </div>
        </div>

        {this.state.showCancelViewDetails ? (
          <div className="modal-dialog modal-xl modal-dialog-centered">
            <Modal
              show={this.state.showCancelViewDetails}
              onHide={() => this.toogleViewCancelPopUp(false)}
              dialogClassName="modal-dialog modal-xl modal-dialog-centered"
            >
              <ReservationCancelPopUp
                ConfirmationData={this.state.CancellationData}
                ReservationConfirmId={this.state.ReservationConfirmId}
              />
            </Modal>
          </div>
        ) : null}

        {this.state.showExtrasDetails ? (
          <div>
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <Modal
                show={this.state.showExtrasDetails}
                onHide={() => this.toogleViewIncludeLongTextPopUp(false)}
                dialogClassName="modal-dialog modal-xl modal-dialog-centered"
              >
                <ViewIncludeLongText LongTextShow={this.state.LongTextShow} />
              </Modal>
            </div>
          </div>
        ) : null}

        {this.state.showInsuranceViewDetails ? (
          <div>
            <div className="modal-dialog modal-xl modal-dialog-centered ">
              <Modal
                show={this.state.showInsuranceViewDetails}
                onHide={() => this.toogleViewInsurancePopUp(false)}
                dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap"
              >
                <ViewInsuranceDetails
                  insuranceDesriptionToShow={this.state.insuranceDesriptionToShow}
                />
              </Modal>
            </div>
          </div>
        ) : null}

        {this.state.showShare ? (
          <div>
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <Modal
                show={this.state.showShare}
                onHide={() => this.toggleViewShare(false)}
                dialogClassName="modal-dialog modal-xl modal-dialog-centered"
              >
                <EmailReservation ReservationConfirmId={this.state.ReservationConfirmId} />
              </Modal>
            </div>
          </div>
        ) : null}

        {this.state.alertModal != null && this.state.alertModal.length > 0 ? (
          <Dialog
            open={this.state.alertModal.length > 0}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
              {'Dollar'}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.alertModal}
              </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                onClick={() => this.setState({ alertModal: null })}
                variant="outlined"
                color="error"
                style={{
                  background: '#E31A37',
                  color: 'white',
                  justifyContent: 'center',
                  width: '100%',
                  margin: '0px 47px',
                }}
              >
                {t('OK.1')}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(withRouter(ReservationConfirm));

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./product_sans_bold-webfont.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "./product_sans_regular-webfont.woff2";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'ProductSansBold';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n  font-family: 'ProductSansRegular';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}", "",{"version":3,"sources":["webpack://src/assets/fonts/ProductSans.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,4CAAyC;AAC3C;;AAEA;EACE,iCAAiC;EACjC,4CAA6C;AAC/C","sourcesContent":["@font-face {\n  font-family: 'ProductSansBold';\n  src: url(product_sans_bold-webfont.woff2);\n}\n\n@font-face {\n  font-family: 'ProductSansRegular';\n  src: url(product_sans_regular-webfont.woff2) ;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

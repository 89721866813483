import React from 'react';
import styles from './CarCard.module.css';
import FeatureItem from './CarFeatures';
import stylesCar from './CarFeatures.module.css';

const CarCard = ({ name, image, specs, price, handleEnquireNowClick, isMobileView }) => {
  const features = [
    {
      iconSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/67b707713864e728a9840ef77508070f4729f6f28bb269ee34968960bf4412fb?placeholderIfAbsent=true&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
      value: '4',
      alt: 'Seats',
    },
    {
      iconSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/db2ba1fd386d97eac07a02238463fe8837f497ff5576b172f5cc75d09147837b?placeholderIfAbsent=true&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
      value: '4',
      alt: 'Doors',
    },
    {
      iconSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/95412130205737305be00670fb868df5770656d1283b9f8ecdd60b47fa63173e?placeholderIfAbsent=true&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
      value: '1.5 L',
      alt: 'Engine capacity',
    },
    {
      iconSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/feb0902964f193436ed372b1d5dec06cf4a4d5183dfb8c2bf21bca9a5fedbaa2?placeholderIfAbsent=true&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
      value: '4',
      alt: 'Cylinders',
    },
    {
      iconSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/478d8be7ecfda28aea140e7c4c649618f33a61eba0f676eadf7f07429041e5a8?placeholderIfAbsent=true&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
      value: 'A',
      alt: 'Transmission',
    },
    {
      iconSrc:
        'https://cdn.builder.io/api/v1/image/assets/TEMP/8780b10dc91d446692b341a04c0789e13f0675c15e7b1950eaebcb14ff23af3f?placeholderIfAbsent=true&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
      value: 'AC',
      alt: 'Air conditioning',
    },
  ];

  return (
    <div className={styles.carCard}>
      <h3 className={styles.carName}>{name}</h3>
      <img src={image} alt={name} className={styles.carImage} />
      {!isMobileView && (
        <div className={stylesCar.carFeatures}>
          {features.map((feature, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/22b35ae3909a6b5784715a5cde7d7182f5173ad5ea245569012b2081ddd0997b?placeholderIfAbsent=true&apiKey=ac85e0a3444a46c7baa2de216eef8e21"
                  alt=""
                  className={styles.separator}
                />
              )}
              <FeatureItem {...feature} />
            </React.Fragment>
          ))}
        </div>
      )}
      <img
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/c0aa2fd055f0a551bff475b086d98c0ad054076d586921ee27d8990827f6742e?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21"
        alt="Divider"
        className={styles.divider}
      />
      <div className={styles.priceWrapper}>
        <span className={styles.currency}>AED</span>
        <span className={styles.price}>{price}</span>
        <span className={styles.period}>/Month</span>
      </div>
      <button
        className={styles.enquireButton}
        onClick={() =>
          handleEnquireNowClick({
            carImage: image,
            carName: name,
            carPrice: price,
          })
        }
      >
        Enquire Now
      </button>
    </div>
  );
};

export default CarCard;

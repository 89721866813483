import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import privacypolicybanner from '../assets/images/corporate-leasing.png';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import LeasingInquiry from './LeasingInquiry';
class CorporateLeasing extends Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t('Long Term Personal Leasing Cars, Monthly Car Rental.1')}</title>
          <link rel="canonical" href="www.dollaruae.com/corporate-leasing" />
          <meta property="og:title" content="Corporate Leasing, Long term Vehicle leasing"></meta>
          <meta property="og:site_name" content="Dollar Car Rental"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/corporateleasing"></meta>
          <meta
            property="og:description"
            content="Dollar Car Rental, a transportation solutions partner for Corporate Leasing, reduced fleet management costs, improved fleet compliance, customized vehicle-builds, and other business-specific goals."
          ></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta
            property="og:image"
            content="https://www.dollaruae.com/static/media/corporate-leasing.c3453aa9.png"
          ></meta>
          <meta name="title" content="Corporate Leasing, Long term Vehicle leasing"></meta>
          <meta
            name="description"
            content="Dollar Car Rental, a transportation solutions partner for Corporate Leasing, reduced fleet management costs, improved fleet compliance, customized vehicle-builds, and other business-specific goals."
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/corporateleasing"></meta>
          <meta property="og:title" content="Corporate Leasing, Long term Vehicle leasing"></meta>
          <meta
            property="og:description"
            content="Dollar Car Rental, a transportation solutions partner for Corporate Leasing, reduced fleet management costs, improved fleet compliance, customized vehicle-builds, and other business-specific goals."
          ></meta>
          <meta
            property="og:image"
            content="https://www.dollaruae.com/static/media/corporate-leasing.c3453aa9.png"
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta property="twitter:url" content="https://www.dollaruae.com/corporateleasing"></meta>
          <meta
            property="twitter:title"
            content="Corporate Leasing, Long term Vehicle leasing"
          ></meta>
          <meta
            property="twitter:description"
            content="Dollar Car Rental, a transportation solutions partner for Corporate Leasing, reduced fleet management costs, improved fleet compliance, customized vehicle-builds, and other business-specific goals."
          ></meta>
          <meta
            property="twitter:image"
            content="https://www.dollaruae.com/static/media/corporate-leasing.c3453aa9.png"
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100">
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row flex-reversea">
                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <h1 className="border-botom">
                          {t('Corporate Leasing & Fleet Management Services.1')}
                        </h1>
                        <h2 className="border-botom">{t('Long Term Car Leasing.1')}</h2>
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <span>
                              <ul className="mb-0">
                                <li> {t('Commercial vehicles.1')} </li>
                                <li> {t('Specialized (business) vehicles.1')} </li>
                                <li> {t('Cars for field staff.1')} </li>
                                <li> {t('Cars for senior managers.1')} </li>
                                <li> {t('Personal lease.1')} </li>
                              </ul>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                      <img
                        src={privacypolicybanner}
                        alt="Dollar Corporate Leasing"
                        className="img-fluid rounded-xl"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="box-shadow-white-box-section grey-bg pb-4">
            <div className="container-fluid">
              <div className="box-shadow-white-box">
                <div className="row">
                  <div className="col-lg-7 p-3">
                    <p className="text_p mt-4">
                      {t(
                        'We believe, no road is long with the right company, And that is why, at Dollar Car Rental, we aim to be the leading and innovative provider of mobility & transportation solutions in the UAE.1',
                      )}
                    </p>
                    <br />
                    <p className="text_p mt-4">
                      {t(
                        'We host UAEs largest & widest fleet, for all your transportation needs Dollar Car Rental UAE takes pride in being associated with leading brands in the UAE like Pepsi, Mai Dubai, Pran Foods, NFPC, Aramex, Carrefour, etc. as their total transportation solutions partner, by helping them achieve reduced fleet management costs, improved fleet compliance, customized vehicle-builds, and other business-specific goals.1',
                      )}
                    </p>
                    <br />
                    <p className="text_p mt-3">
                      {t(
                        'Let us look at some of the advantages of long-term personal car leasing, especially in the UA.1',
                      )}
                    </p>
                    <br />

                    <h5 className="font-weight-bold">
                      {t(
                        'Key points that could define Dollar UAE as your total mobility partner.1',
                      )}
                    </h5>
                    <p className="text_p mt-4">
                      {t(
                        'Our vehicles are modern, reliable & adaptable, making us a wise choice for businesses that involve transportation of expensive and perishable goods.1',
                      )}
                    </p>
                    <br />

                    <h5 className="font-weight-bold">{t('Sales Consultants.1')}</h5>
                    <p className="text_p mt-4">
                      {t(
                        'that support you with scalable vehicle acquisition and management solutions ensuring significant cost savings, reduced administrative hassles, increased productivity, and risk mitigation within your organization.1',
                      )}
                    </p>
                    <br />

                    <h5 className="font-weight-bold">{t('Convenience & Flexibility.1')}</h5>
                    <p className="text_p mt-4">
                      {t(
                        'Our corporate leasing works on a pay-for-usage model, rather than fleet ownership. This allows our clients to use vehicles, based on their requirements with simple straightforward lease rentals, while not having to worry about the hassles of fleet management, servicing, insurance, damage repair, registration rentals, replacement vehicles, and end-of-term sales.1',
                      )}
                    </p>
                    <br />

                    <h5 className="font-weight-bold">{t('Long-term leasing.1')}</h5>
                    <p className="text_p mt-4">
                      {t('Long-term leasing options of up to 5+ years.1')}
                    </p>
                    <br />

                    <h5 className="font-weight-bold">{t('Dedicated Account Managers.1')}</h5>
                    <p className="text_p mt-4">
                      {t(
                        'Our team moves with your team, 24x7. Hassle-free interactions, no repeated paperwork, priority agile approach.1',
                      )}
                    </p>
                    <br />

                    <h5 className="font-weight-bold">{t('Periodic training.1')} </h5>
                    <p className="text_p mt-4">
                      {t(
                        'Periodic training & frequent in-house workshops for vehicle drivers and other involved human resources, conducted in all major Emirates.1',
                      )}
                    </p>
                    <br />

                    <h5 className="font-weight-bold">
                      {t('Customizable vehicular modifications.1')}
                    </h5>
                    <p className="text_p mt-4">
                      {t(
                        'for business-specific requirements Based on the needs of a large number of our clients, we have expanded and increased our focus on specialized vehicles, targeted towards the transport/logistics/distribution companies and commercial fleet owners in the UAE. We now offer these vehicles on a long-term lease as per your requirements. To know further on how you can manage your fleet more efficiently to instead focus more on your core business, contact us today.1',
                      )}
                    </p>
                    <br />
                    <p className="text_p mt-4">{t('We look forward to serving you.1')}</p>
                  </div>
                  <div className="col-lg-5" id="book">
                    <LeasingInquiry />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(CorporateLeasing);

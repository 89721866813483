import dayjs from 'dayjs';
import moment from 'moment';
import React, { Component } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import 'react-google-flight-datepicker/dist/main.css';
import { withTranslation } from 'react-i18next';
import buildingImg from '../../assets/images/building.svg';
import closeImg from '../../assets/images/closeX.svg';
import plainImg from '../../assets/images/plain.svg';
import { positionMap } from './constant';
import './daterangepicker.css';
import './googleDatePicker.css';
import './tabsStyle.css';

class CalendarPopUp extends Component {
  constructor(props) {
    super(props);
    this.divRef = React.createRef();
    this.state = {
      pickUpDate: this.props.stateValues.pickUpDate,
      returnDate: this.props.stateValues.returnDate,
      timeList: [],
      timeList1: [],
      pickUpCount: 0,
      currentPickupTime: this.props.stateValues.pickUpTime,
      currentReturnTime: this.props.stateValues.returnTime,
      returnCount: 0,
      dayBetween: this.props.stateValues.dayBetweenState || 3,
      pickTimeRangeSlider: this.props.stateValues.pickUpTime,
      returnTimeRangeSlider: this.props.stateValues.returnTime,
      pickupPeriod: this.props.stateValues.pickUpPeriod,
      returnPeriod: this.props.stateValues.returnPeriod,
      activeColor: 3,
      activeCalendar: true,
      size: window.innerWidth,
      currentFunction: 'pickUp',
      focusReturn: false,
      isFirefox: false,
      trackDate: 1,
      range: {
        startDate: new Date(this.props.stateValues.pickUpDate),
        endDate: new Date(this.props.stateValues.returnDate),
        key: 'selection', // specify the key
      },
    };
  }

  formatDateString(dateString) {
    const [day, numericMonth, year] = dateString.split(' ');

    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const month = months[parseInt(numericMonth, 10) - 1];

    const formattedDate = `${day} ${month} ${year}`;

    return formattedDate;
  }

  updateDayBetween = () => {
    const { pickUpDate, returnDate } = this.state;

    const CurrentPickupTime =
      moment(pickUpDate).format('DD MMM YYYY') +
      ', ' +
      this.state.currentPickupTime +
      ' ' +
      this.state.pickupPeriod;
    const CurrentReturnTime =
      moment(returnDate).format('DD MMM YYYY') +
      ', ' +
      this.state.currentReturnTime +
      ' ' +
      this.state.returnPeriod;

    // Define the date format
    const dateFormat = 'DD MMM YYYY, hh:mm A';

    // Parse the dates using moment
    const date1 = moment(CurrentPickupTime, dateFormat);
    const date2 = moment(CurrentReturnTime, dateFormat);

    // Calculate the difference in time (milliseconds)
    const differenceInTime = Math.abs(date2.diff(date1));

    // Convert time difference from milliseconds to days
    const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24);

    // If there's any decimal value, round up
    const days = Math.ceil(differenceInDays);

    let dayBetween = days;
    if (dayBetween > 0) {
      this.setState({ dayBetween }, () => {
        this.props.dayBetween(dayBetween);
      });
    } else {
      this.setState({ dayBetween: 0 }, () => {
        this.props.dayBetween(0);
      });
      if (pickUpDate > returnDate) {
        this.setState({ returnDate: pickUpDate }, () => {
          this.props.returnDate(pickUpDate);
        });
      }
    }
  };

  componentDidMount() {
    if (this.props.stateValues.activeColor === 4) {
      this.setState({ focusReturn: true });
    }

    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Firefox') > -1) {
      this.setState({ isFirefox: true });
    }

    this.updateCurrentPickupTime(this.props.stateValues.pickUpTime, 'pickUpTime');
    this.updateCurrentPickupTime(this.props.stateValues.returnTime, 'returnTime');

    this.updateDayBetween();
  }

  handleTimeIncrement12 = (pickup) => {
    if (pickup) {
      let splittedTime = this.state.currentPickupTime.split(':');
      let hours = parseInt(splittedTime[0]);
      let minutes = parseInt(splittedTime[1]);

      // Increment time by 30 minutes
      minutes += 30;
      if (minutes >= 60) {
        hours++;
        minutes = 0;
      }

      // Handle 12-hour format and wrapping around
      if (hours > 12) {
        hours = 1;
      }

      // Format hours and minutes to ensure 2 digits
      let formattedHours = (hours < 10 ? '0' : '') + hours;
      let formattedMinutes = (minutes < 10 ? '0' : '') + minutes;

      // Update current time
      const currentTime = formattedHours + ':' + formattedMinutes;

      this.updateCurrentPickupTime(currentTime, 'pickUpTime');

      this.setState(
        {
          currentPickupTime: currentTime,
        },
        () => {
          this.props.handlePickTime(this.state.currentPickupTime);
          if (this.state.currentPickupTime === '12:00') {
            if (this.state.pickupPeriod === 'AM') {
              this.handlePeriod(true, 'PM');
            } else {
              this.handlePeriod(true, 'AM');
            }
          }
          this.updateDayBetween();
        },
      );
    } else {
      let splittedTime = this.state.currentReturnTime.split(':');
      let hours = parseInt(splittedTime[0]);
      let minutes = parseInt(splittedTime[1]);

      // Increment time by 30 minutes
      minutes += 30;
      if (minutes >= 60) {
        hours++;
        minutes = 0;
      }

      // Handle 12-hour format and wrapping around
      if (hours > 12) {
        hours = 1;
      }

      // Format hours and minutes to ensure 2 digits
      let formattedHours = (hours < 10 ? '0' : '') + hours;
      let formattedMinutes = (minutes < 10 ? '0' : '') + minutes;

      // Update current time
      const currentTime = formattedHours + ':' + formattedMinutes;
      this.updateCurrentPickupTime(currentTime, 'returnTime');

      this.setState(
        {
          currentReturnTime: currentTime,
        },
        () => {
          this.props.handleReturnTime(this.state.currentReturnTime);
          if (this.state.currentReturnTime === '12:00') {
            if (this.state.returnPeriod === 'AM') {
              this.handlePeriod(false, 'PM');
            } else {
              this.handlePeriod(false, 'AM');
            }
          }
          this.updateDayBetween();
        },
      );
    }
  };

  changeDateRangeState = (item) => {
    this.setState({
      range: item.selection,
    });

    const formatedPickupDate = this.formatDateString(
      dayjs(item.selection.startDate).format('DD MM YYYY'),
    );

    this.props.pickUpDate(formatedPickupDate);
    this.setState({ pickUpDate: formatedPickupDate });

    const formatedReturnDate = this.formatDateString(
      dayjs(item.selection.endDate).format('DD MM YYYY'),
    );

    this.props.returnDate(formatedReturnDate);
    this.setState({ returnDate: formatedReturnDate });
  };

  //12 hours handleTimeDecrement
  handleTimeDecrement12 = (pickup) => {
    if (pickup) {
      let splittedTime = this.state.currentPickupTime.split(':');
      let hours = parseInt(splittedTime[0]);
      let minutes = parseInt(splittedTime[1]);

      // Decrement time by 30 minutes
      minutes -= 30;
      if (minutes < 0) {
        hours--;
        minutes = 30;
      }

      // Handle wrapping around to '12:30' after reaching '01:00'
      if (hours <= 0) {
        hours = 12;
      }

      // Format hours and minutes to ensure 2 digits
      let formattedHours = (hours < 10 ? '0' : '') + hours;
      let formattedMinutes = (minutes < 10 ? '0' : '') + minutes;

      // Update current time
      const currentTime = formattedHours + ':' + formattedMinutes;

      this.updateCurrentPickupTime(currentTime, 'pickUpTime');

      this.setState(
        {
          currentPickupTime: currentTime,
        },
        () => {
          this.props.handlePickTime(this.state.currentPickupTime);
          if (this.state.currentPickupTime === '12:00') {
            if (this.state.pickupPeriod === 'AM') {
              this.handlePeriod(true, 'PM');
            } else {
              this.handlePeriod(true, 'AM');
            }
          }
          this.updateDayBetween();
        },
      );
    } else {
      let splittedTime = this.state.currentReturnTime.split(':');
      let hours = parseInt(splittedTime[0]);
      let minutes = parseInt(splittedTime[1]);

      // Decrement time by 30 minutes
      minutes -= 30;
      if (minutes < 0) {
        hours--;
        minutes = 30;
      }

      // Handle wrapping around to '12:30' after reaching '01:00'
      if (hours <= 0) {
        hours = 12;
      }

      // Format hours and minutes to ensure 2 digits
      let formattedHours = (hours < 10 ? '0' : '') + hours;
      let formattedMinutes = (minutes < 10 ? '0' : '') + minutes;

      // Update current time
      const currentTime = formattedHours + ':' + formattedMinutes;
      this.updateCurrentPickupTime(currentTime, 'returnTime');

      this.setState(
        {
          currentReturnTime: currentTime,
        },
        () => {
          this.props.handleReturnTime(this.state.currentReturnTime);
          if (this.state.currentReturnTime === '12:00') {
            if (this.state.returnPeriod === 'AM') {
              this.handlePeriod(false, 'PM');
            } else {
              this.handlePeriod(false, 'AM');
            }
          }
          this.updateDayBetween();
        },
      );
    }
  };

  handlePeriod = (pickup, value) => {
    if (pickup) {
      this.setState({ pickupPeriod: value });
      this.props.handlePickUpPeriod(value);
    } else {
      this.setState({ returnPeriod: value });
      this.props.handleReturnPeriod(value);
    }
  };

  updateCurrentPickupTime = (timeString, time) => {
    if (time === 'pickUpTime') {
      this.setState({ pickTimeRangeSlider: timeString });
    } else if (time === 'returnTime') {
      this.setState({ returnTimeRangeSlider: timeString });
    }
  };

  calculateLeftStyle = () => {
    const { pickTimeRangeSlider } = this.state;
    const leftPosition = positionMap[pickTimeRangeSlider] || 0;
    return `${leftPosition}%`;
  };

  calculateRightStyle = () => {
    const { returnTimeRangeSlider } = this.state;
    const leftPosition = positionMap[returnTimeRangeSlider] || 0;
    return `${leftPosition}%`;
  };

  handleSliderChangeLeft = (event) => {
    const newValue = parseFloat(event.target.value);
    const hours = Math.floor(newValue);
    const minutes = (newValue - hours) * 60; // Adjusted the calculation to get minutes
    const currentTime = new Date();
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setSeconds(0);

    // Format the time as "hh:mm"
    let formattedTime = currentTime.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });

    formattedTime = formattedTime.replace(/\s[AP]M/i, '');
    // Update the state with the formatted time
    this.setState({
      pickTimeRangeSlider: formattedTime,
      currentTime: formattedTime,
    });

    // Call the callback function with the formatted time
    this.setState({ currentPickupTime: formattedTime }, () => {
      this.props.handlePickTime(formattedTime);
    });
  };

  handleSliderChangeRight = (event) => {
    const newValue = parseFloat(event.target.value); // Parse float to handle 0.5 increments
    const hours = Math.floor(newValue); // Get the hour part
    const minutes = (newValue % 1) * 60; // Convert the decimal part to minutes
    const currentTime = new Date();
    currentTime.setHours(hours);
    currentTime.setMinutes(minutes);
    currentTime.setSeconds(0);

    // Format the time as "hh:mm"
    let formattedTime = currentTime.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });

    formattedTime = formattedTime.replace(/\s[AP]M/i, '');

    this.setState({
      returnTimeRangeSlider: formattedTime,
      currentTime: formattedTime,
    });
    this.setState({ currentReturnTime: formattedTime }, () => {
      this.props.handleReturnTime(formattedTime);
    });
  };

  handlePickUpDateChange = (date) => {
    const formatedDate = this.formatDateString(dayjs(date).format('DD MM YYYY'));

    this.props.pickUpDate(formatedDate);
    this.setState({ pickUpDate: formatedDate });
  };

  handleReturnDateChange = (date) => {
    const formatedDate = this.formatDateString(dayjs(date).format('DD MM YYYY'));

    this.props.returnDate(formatedDate);
    this.setState({ returnDate: formatedDate });
  };

  onDateChange(startDate, endDate) {
    if (this.state.focusReturn) {
      let dateToSave = null;

      const newTrackDate = this.state.trackDate === 1 ? 2 : 1;
      this.setState({ trackDate: newTrackDate });

      if (startDate !== null && this.state.trackDate === 2) {
        dateToSave = this.formatDateString(dayjs(startDate).format('DD MM YYYY'));
      }
      if (endDate !== null && this.state.trackDate === 1) {
        dateToSave = this.formatDateString(dayjs(endDate).format('DD MM YYYY'));
      }

      if (dateToSave !== null) {
        this.props.returnDate(dateToSave);
        this.setState({
          returnDate: dateToSave,
        });
      }
      this.updateDayBetween();
      return;
    }

    this.setState({ activeColor: this.state.activeColor === 3 ? 4 : 3 });

    this.props.handleDateInputColor(this.state.activeColor);

    if (startDate !== null) {
      const formatedStartDate = this.formatDateString(dayjs(startDate).format('DD MM YYYY'));

      this.props.pickUpDate(formatedStartDate);
      this.setState({ pickUpDate: formatedStartDate });
    }

    if (endDate !== null) {
      const formatedEndDate = this.formatDateString(dayjs(endDate).format('DD MM YYYY'));
      this.props.returnDate(formatedEndDate);
      this.setState({ returnDate: formatedEndDate });
    }

    this.updateDayBetween();
  }

  render() {
    const { t } = this.props;
    const marginTopStyle = { paddingTop: window.innerWidth < 500 ? '5vw' : '' };
    const paddingBottomStyle = {
      paddingBottom: window.innerWidth < 500 ? 0 : '',
    };

    const popupStyle = this.state.isFirefox
      ? { scrollbarWidth: '8px', scrollbarColor: '#E31A37 #ffffff' }
      : {};
    return (
      <>
        <div className="overly-hidden"></div>
        <div className="pickupReturnDatePopup">
          <div className="inner-wrapper">
            <div className="mob-header mob">
              <div className="back">
                <h6>{t('Pickup Date & Return Date.1')}</h6>
                <div className="icon">
                  <img
                    src={closeImg}
                    alt=""
                    id="datePopupBackBtn"
                    onClick={() => this.props.hanldePopUpDate(0)}
                  />
                </div>
              </div>
            </div>
            <ul className="selectedLocations">
              <li>
                <div className="icon">
                  <img
                    src={
                      this.props.stateValues.pickUpLocation &&
                      this.props.stateValues.pickUpLocation.includes('Airport')
                        ? plainImg
                        : buildingImg
                    }
                    alt=""
                  />
                </div>
                <div className="inner-wrapper d-block">
                  <h6>{this.props.stateValues.pickUpLocation || 'Select pick up location'}</h6>
                  <h4>
                    {t('Pickup.1')} :{' '}
                    <span>
                      {moment(this.props.stateValues.pickUpDate, 'DD MMM YYYY').format(
                        'DD MMM YYYY',
                      )}
                    </span>{' '}
                    |{' '}
                    <span>
                      {this.state.currentPickupTime} {this.state.pickupPeriod}
                    </span>
                  </h4>
                </div>
              </li>
              <li>
                <div className="icon">
                  <img
                    src={
                      this.props.stateValues.returnLocation &&
                      this.props.stateValues.returnLocation.includes('Airport')
                        ? plainImg
                        : buildingImg
                    }
                    alt=""
                  />
                </div>
                <div className="inner-wrapper d-block">
                  <h6>{this.props.stateValues.returnLocation || 'Select return location'}</h6>
                  <h4>
                    Return :{' '}
                    <span>
                      {moment(this.props.stateValues.returnDate, 'DD MMM YYYY').format(
                        'DD MMM YYYY',
                      )}
                    </span>{' '}
                    |{' '}
                    <span>
                      {' '}
                      {this.state.currentReturnTime} {this.state.returnPeriod}
                    </span>
                  </h4>
                </div>
              </li>
              {/* )} */}
            </ul>

            <div className="right-section-c" style={popupStyle}>
              <div className="datePickerWrapper">
                {/* mobile Calander */}
                <DateRange
                  showMonthAndYearPickers={false}
                  onChange={this.changeDateRangeState}
                  ranges={[this.state.range]}
                  minDate={new Date()}
                  className="mobileReactDatePicker"
                />

                {/* web Calander */}
                <RangeDatePicker
                  minDate={dayjs()}
                  dateFormat="D"
                  startDate={this.state.pickUpDate}
                  endDate={this.state.returnDate}
                  dayFormat="MMM YYYY"
                  monthFormat="MMM YYYY"
                  disabled={false}
                  startWeekDay="monday"
                  onChange={(startDate, endDate) => this.onDateChange(startDate, endDate)}
                  isOpen="true"
                />
              </div>

              <div className="mob-stick-wrapper">
                <div className="total-days-c">
                  <h6>
                    <span id="totaldays">{this.state.dayBetween}</span> {t('days.1')}
                  </h6>
                </div>
                {this.state.activeCalendar || this.state.size > '500' ? (
                  <div className="time-section">
                    <div className="left column-c" style={marginTopStyle}>
                      <div className="d-flex d-sm-block align-items-center">
                        <h6 className="web">{t('Pickup Date & Time.1')}</h6>
                        <h6 className="mob">{t('Pickup.1')}</h6>
                        <h5>
                          {' '}
                          <span id="pickupDate">
                            {moment(this.state.pickUpDate, 'DD MMM').format('DD MMM')}
                          </span>{' '}
                          | {this.state.currentPickupTime} {this.state.pickupPeriod}
                        </h5>
                      </div>
                      <div className="time-selection">
                        <div className="custom-container">
                          <div className="time-counter">
                            <button type="button" onClick={() => this.handleTimeDecrement12(true)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M5 12H19"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                            <span>{this.state.currentPickupTime}</span>
                            <button type="button" onClick={() => this.handleTimeIncrement12(true)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M12 5V19"
                                  stroke="#231F20"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5 12H19"
                                  stroke="#231F20"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                          <div className="timeMode">
                            <button
                              className={`Button ${
                                this.state.pickupPeriod === 'AM' ? 'active' : ''
                              }`}
                              size="sm"
                              onClick={() => this.handlePeriod(true, 'AM')}
                            >
                              AM
                            </button>
                            <button
                              className={`Button ${
                                this.state.pickupPeriod === 'PM' ? 'active' : ''
                              }`}
                              size="sm"
                              onClick={() => this.handlePeriod(true, 'PM')}
                            >
                              PM
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="left column-c" style={paddingBottomStyle}>
                      <div className="d-flex d-sm-block">
                        <h6 className="web">{t('Return Date & Time.1')}</h6>
                        <h6 className="mob">{t('Return.1')}</h6>
                        <h5>
                          {' '}
                          <span id="returnDate">
                            {moment(this.state.returnDate, 'DD MMM').format('DD MMM')}
                          </span>{' '}
                          | {this.state.currentReturnTime} {this.state.returnPeriod}
                        </h5>
                      </div>
                      <div className="time-selection">
                        <div className="custom-container">
                          <div className="time-counter">
                            <button type="button" onClick={() => this.handleTimeDecrement12(false)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M5 12H19"
                                  stroke="black"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                            <span>{this.state.currentReturnTime}</span>
                            <button type="button" onClick={() => this.handleTimeIncrement12(false)}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M12 5V19"
                                  stroke="#231F20"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M5 12H19"
                                  stroke="#231F20"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </div>
                          <div className="timeMode">
                            <button
                              className={`Button ${
                                this.state.returnPeriod === 'AM' ? 'active' : ''
                              }`}
                              size="sm"
                              onClick={() => this.handlePeriod(false, 'AM')}
                            >
                              AM
                            </button>
                            <button
                              className={`Button ${
                                this.state.returnPeriod === 'PM' ? 'active' : ''
                              }`}
                              size="sm"
                              onClick={() => this.handlePeriod(false, 'PM')}
                            >
                              PM
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
          <div className="show-car-btn-mob mob">
            <button className="primary-button" onClick={this.props.searchClickHandler}>
              {t('Continue.1')}
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(CalendarPopUp);

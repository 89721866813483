import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import Header from '../Common/ListingHeader';
import BannerSection from './BannerSection';
const SpecialOffersIndex = React.lazy(() => import('../SpecialOffer/SpecialOffersIndex'));
const LessfineSection = React.lazy(() => import('./LessFineSection'));
const Subscribe = React.lazy(() => import('./Subscribe'));
const NewsIndex = React.lazy(() => import('../News/NewsIndex'));
const Footer = React.lazy(() => import('../Common/Footer'));
const CopyRightFooter = React.lazy(() => import('../Common/CopyRightFooter'));
const FindLocation = React.lazy(() => import('./FindLocation'));
class Hire extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowSize: 1024,
      isMobileView: false,
      headerActive: true,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowSize);
    this.updateWindowSize();
    scroll.scrollToTop();
  }

  updateWindowSize = () => {
    this.setState({
      windowSize: window.innerWidth,
    });
    this.setState({ isMobileView: false });
    if (window.innerWidth < 770) {
      this.setState({ isMobileView: true });
    }
  };

  handleHeaderActive = (value) => {
    if (this.state.windowSize > 553) {
      this.setState({ headerActive: value });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dollar Car Rental UAE | Rent a Car in Dubai at Best Prices</title>
          <meta
            name="description"
            content="Rent a Car in Dubai & Across UAE with Dollar Car Rental. Explore the best of monthly, weekly, daily & long-term car rentals - and avail big discounts and unmissable offers. "
          ></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental UAE | Rent a Car in Dubai at Best Prices"
          />
          <meta property="og:url" content="https://www.dollaruae.com" />
          <meta
            property="og:description"
            content="Rent a Car in Dubai & Across UAE with Dollar Car Rental. Explore the best of monthly, weekly, daily & long-term car rentals - and avail big discounts and unmissable offers. "
          ></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta
            property="og:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
          <meta
            name="title"
            content="Dollar Car Rental UAE | Rent a Car in Dubai at Best Prices"
          ></meta>
          <meta
            name="description"
            content="Rent a Car in Dubai & Across UAE with Dollar Car Rental. Explore the best of monthly, weekly, daily & long-term car rentals - and avail big discounts and unmissable offers. "
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/"></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental UAE | Rent a Car in Dubai at Best Prices"
          ></meta>
          <meta
            property="og:description"
            content="Rent a Car in Dubai & Across UAE with Dollar Car Rental. Explore the best of monthly, weekly, daily & long-term car rentals - and avail big discounts and unmissable offers. "
          ></meta>
          <meta
            property="og:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta property="twitter:url" content="https://www.dollaruae.com/"></meta>
          <meta
            property="twitter:title"
            content="Dollar Car Rental UAE | Rent a Car in Dubai at Best Prices"
          ></meta>
          <meta
            property="twitter:description"
            content="Rent a Car in Dubai & Across UAE with Dollar Car Rental. Explore the best of monthly, weekly, daily & long-term car rentals - and avail big discounts and unmissable offers. "
          ></meta>
          <meta
            property="twitter:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
        </Helmet>
        {this.state.headerActive && <Header />}
        <BannerSection
          isMobileView={this.state.isMobileView}
          handleHeaderActive={this.handleHeaderActive}
        />
        {/* <BestDeals /> */}
        <SpecialOffersIndex isMobileView={this.state.isMobileView} />
        {this.state.isMobileView == false ? <LessfineSection /> : null}
        {/* <ProductSectionWithHeader /> */}
        {/* <BrandSlider /> */}

        {this.state.isMobileView == false ? <Subscribe /> : null}

        {this.state.isMobileView == false ? <NewsIndex /> : null}
        <FindLocation isMobileView={this.state.isMobileView} />
        {this.state.isMobileView == false ? <Footer /> : null}
        {this.state.isMobileView == true ? (
          <CopyRightFooter isMobileView={this.state.isMobileView} />
        ) : null}
      </>
    );
  }
}

export default withTranslation()(Hire);

import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getLanguage } from '../Common/Util';
import newsImage from '../assets/images/news.png';
import { contentInstance } from '../axios/axiosInstance';
import NewsTile from './NewsTile';

class AllNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allNews: [],
    };
  }
  componentDidMount = () => {
    let lngParam = getLanguage();
    this.getAllNews(lngParam);
  };

  getAllNews = (lngParam) => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    contentInstance
      .get(`news/allUi?countryCode=${code}`)
      .then((response) => {
        this.setState({
          allNews: response.data.result,
        });
      })
      .catch((error) => {});
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t('News & Latest Updates of Dollar Car Rental.1')}</title>
          <link rel="canonical" href="www.dollaruae.com/news" />
          <meta property="og:title" content="News & Latest Updates of Dollar Car Rental"></meta>
          <meta property="og:site_name" content="Dollar Car Rental"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/news"></meta>
          <meta
            property="og:description"
            content="News, growth stories, customer experiences and latest updates on Dollar Car Rental."
          ></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta
            property="og:image"
            content="http://admin.dollaruae.com//DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/news/Spring_Souk_Mall_counter_opening_News465d41c0-075c-4012-ad39-d516d9ed3c9e.jpeg"
          ></meta>
          <meta name="title" content="News & Latest Updates of Dollar Car Rental"></meta>
          <meta
            name="description"
            content="News, growth stories, customer experiences and latest updates on Dollar Car Rental."
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/news"></meta>
          <meta property="og:title" content="News & Latest Updates of Dollar Car Rental"></meta>
          <meta
            property="og:description"
            content="News, growth stories, customer experiences and latest updates on Dollar Car Rental."
          ></meta>
          <meta
            property="og:image"
            content="https://www.dollaruae.com/static/media/news.248372cc.png"
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta property="twitter:url" content="https://www.dollaruae.com/news"></meta>
          <meta
            property="twitter:title"
            content="News & Latest Updates of Dollar Car Rental"
          ></meta>
          <meta
            property="twitter:description"
            content="News, growth stories, customer experiences and latest updates on Dollar Car Rental."
          ></meta>
          <meta
            property="twitter:image"
            content="https://www.dollaruae.com/static/media/news.248372cc.png"
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100">
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row flex-reversea">
                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <h1 className="border-botom">{t('News & Updates.1')}</h1>
                            <p>{t('The Latest from Dollar Car Rental UAE.1')}</p>
                            <p className="text_p mt-4">
                              {t(
                                'Read on to know how we keep ourselves up-to-speed with this compilation of latest updates, newsroom highlights, growth stories, and customer excerpts.1',
                              )}
                            </p>
                            <div className="mt-4 card-profile-wrap">
                              <h3>{t('Media/Analyst Contact at Dollar Car Rental UAE.1')} </h3>
                              {/* <h4>Anudeep Raghuthaman <span>Head - Marketing &amp; Sales</span></h4> */}
                              <ul>
                                <li>
                                  <p>
                                    <i className="fa fa-map-marker"></i>
                                    {t('Dollar Head Office, ARENCO Building.1')},<br></br>
                                    {t('Zabeel Road, Karama - Dubai.1')}
                                  </p>
                                </li>
                                {/* <li>
                                                                <p><i className="fa fa-envelope-o"></i>anudeep@dollaruae.com</p>
                                                            </li> */}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                      <div style={{ width: '97%', position: 'relative', top: '15px' }}>
                        <img
                          src={newsImage}
                          alt={t('Dollar News.1')}
                          className="img-fluid rounded-xl"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="what-new-sectio ptb-80 grey-bg py-4">
            <div className="container">
              <div className="row">
                {' '}
                {this.state.allNews != null
                  ? this.state.allNews.map((news) => {
                      return <NewsTile key={news.NewsID} news={news} />;
                    })
                  : ''}
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(AllNews)), AllNews);

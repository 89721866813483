import axios from 'axios';

const createAxiosInstance = (apiKey, clientKey, baseURL) => {
  return axios.create({
    baseURL: baseURL,
    headers: {
      apikey: apiKey,
      clientkey: clientKey,
      accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
};

export const branchInstance = createAxiosInstance(
  process.env.REACT_APP_BRANCH_API_KEY,
  process.env.REACT_APP_BRANCH_CLIENT_KEY,
  process.env.REACT_APP_BRANCH_API_URL,
);

export const productInstance = createAxiosInstance(
  process.env.REACT_APP_PRODUCT_API_KEY,
  process.env.REACT_APP_PRODUCT_CLIENT_KEY,
  process.env.REACT_APP_PRODUCT_API_URL,
);

export const contentInstance = createAxiosInstance(
  process.env.REACT_APP_CONTENT_API_KEY,
  process.env.REACT_APP_CONTENT_CLIENT_KEY,
  process.env.REACT_APP_CONTENT_API_URL,
);

export const userInstance = createAxiosInstance(
  process.env.REACT_APP_USER_API_KEY,
  process.env.REACT_APP_USER_CLIENT_KEY,
  process.env.REACT_APP_USER_API_URL,
);

export const bookingInstance = createAxiosInstance(
  process.env.REACT_APP_BOOKING_API_KEY,
  process.env.REACT_APP_BOOKING_CLIENT_KEY,
  process.env.REACT_APP_BOOKING_API_URL,
);

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import specialOfferBanner from '../assets/images/Business/593599952.jpg';
import { contentInstance } from '../axios/axiosInstance';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import SpecialOfferBigTile from './SpecialOfferBigTile';
import SpecialOfferTile from './SpecialOfferTile';

class SpecialOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SpecialOffers: [],
      bigSpecialOffers: [],
      avaliableindex: 0,
    };
  }

  handleSelect = (index) => {
    if (index == undefined) {
      this.setState({ avaliableindex: 0 });
    } else {
      this.setState({ avaliableindex: index });
    }

    contentInstance
      .get(`offers/allUi?countryCode=${process.env.REACT_APP_COUNTRY_CODE}`)
      .then((response) => {
        const specialOffers = response.data.result;
        const specialOffersWithrentaltypeName = [];
        const bigSpecialOffersWithrentaltypeName = [];
        let index = 1;

        specialOffers.forEach((offer) => {
          if (index % 7 == 0) {
            bigSpecialOffersWithrentaltypeName.push(offer);
          } else {
            specialOffersWithrentaltypeName.push(offer);
          }
          index++;
        });
        this.setState({ SpecialOffers: specialOffersWithrentaltypeName });
        this.setState({ bigSpecialOffers: bigSpecialOffersWithrentaltypeName });
      });
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    this.handleSelect(params.index);
    scroll.scrollToTop();
  }

  BookNow = (ID) => {
    this.props.history.push(`/offers/${ID}`);
  };

  render() {
    const { t } = this.props;
    return (
      <div className="grey-bg">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dollar Car Rental UAE | Exclusive Offers and Best Deals</title>
          <meta
            name="description"
            content="Explore exciting offers on your next car rental with Dollar Car Rental UAE. With discounts,deals and offers running all year round - we are your ultimate car rental destination."
          ></meta>
          <meta
            property="og:title"
            content="Dollar Car Rental UAE | Exclusive Offers and Best Deals"
          ></meta>
          <meta property="og:url" content="https://www.dollaruae.com/offers"></meta>
          <meta
            property="og:description"
            content="Explore exciting offers on your next car rental with Dollar Car Rental UAE. With discounts,deals and offers running all year round - we are your ultimate car rental destination."
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100 grey-bg pay-2">
          <section className="box-shadow-white-box-section pt-3 grey-bg py-2">
            <div className="container-fluid">
              <div className="box-shadow-white-box-special-a ">
                <div className="">
                  <div className="row flex-reversea">
                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <h2 className="border-botom">{t('Dollar Hunt Offers.1')}</h2>
                            <p className="mb-3">
                              {t('Best 365 days of offers & surprise deals.1')}
                            </p>
                            <p className="font-16 text-grey mt-4">
                              {t(
                                'Dollar offers many great car rental deals and discounts for big savings on top of our already great base rates. Whatever your departure date or destination, Dollar has a great rental car at a great rate waiting for you.1',
                              )}{' '}
                            </p>
                            <p className="font-16 text-grey mt-4">
                              {t(
                                'Before booking your next road trip, vacation, or business travel, check-in with Dollar Car Rental UAE to see how we can save you more with one of our rental car discounts.With our exclusive Dollar Hunt program, you can now enjoy 365 days of assured deals and the best car rental offers in the UAE.1',
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12 mb-4 mb-lg-0">
                      <img
                        src={specialOfferBanner}
                        alt={t('Special Offers.1')}
                        className="img-fluid rounded-xl"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="box-shadow-white-box-section">
            <div className=" mb-4 ">
              <div className="container">
                <div className="form-row">
                  <div className="col-xl-12 col-12">
                    <div className="form-row">
                      {this.state.SpecialOffers.map((offers) => {
                        return <SpecialOfferTile key={offers.SpecialOfferID} offers={offers} />;
                      })}
                    </div>
                  </div>
                  <div className="col-xl-4 col-12 mt-xl-0 mt-3">
                    <div className="special-offer-box">
                      {this.state.bigSpecialOffers.map((offers) => {
                        return (
                          <>
                            <SpecialOfferBigTile
                              key={offers.SpecialOfferID}
                              bigSpecialOffer={offers}
                            />
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </div>
    );
  }
}

export default withTranslation()(withRouter(SpecialOffers));

import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { bookingInstance } from '../axios/axiosInstance';

class MonthlyViewRentalDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RentalDetails: [],
    };
  }
  componentDidMount() {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    bookingInstance
      .get(`setting?countryCode=${code}`)
      .then((response) => {
        this.setState({
          RentalDetails: response.data,
        });
      })
      .catch((error) => {});
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        {
          <Modal.Header closeButton>
            <h4>{t('View Rental Details.1')}</h4>
          </Modal.Header>
        }
        <Modal.Body></Modal.Body>
      </div>
    );
  }
}

export default withTranslation()(MonthlyViewRentalDetails);

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { images } from './constant';

class LeaseThankYou extends Component {
  render() {
    return (
      <>
        <div className="overlay-oz"></div>
        <div className="thank-you-popup">
          <div className="close-btn" onClick={() => this.props.handleThankYouPopup(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <path d="M1 14L14 1" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" />
              <path d="M14 14L1 1" stroke="#999999" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
          </div>
          <div className="img">
            <img src={images.success} alt="success" />
          </div>
          <h6>Thank you</h6>
          <p>A leasing expert from our team will reach out to you shortly. Enjoy your day!</p>
        </div>
      </>
    );
  }
}

export default withTranslation()(LeaseThankYou);

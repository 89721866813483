import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { getCustomerId, getLanguage } from '../Common/Util';
import '../assets/css/slick.css';
import appstore from '../assets/images/app-store.png';
import arencologo from '../assets/images/arenco-logo.png';
import dollarlogo from '../assets/images/dollar.svg';
import googleplay from '../assets/images/google-play-store.png';
import { branchInstance } from '../axios/axiosInstance';

class ListingFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleTypes: [],
      locations: [],
      airPortLocations: [],
      citySegmentLocations: [],
      cityLocations: [],
      errorMessage: '',
      CookiePolicyAccepted: '',
    };
  }

  onFindReservationClick = (path) => {
    const customerId = getCustomerId();
    if (customerId == '00000000-0000-0000-0000-000000000000') {
      this.props.history.push('/findreservation');
    } else {
      this.props.history.push('/profile');
    }
  };

  onFooterMenuLinkClick = (path) => {
    localStorage.setItem('locationid', 'null');
    this.props.history.push(path);
  };

  componentDidMount = () => {
    this.getVehicleTypes();
    const CookiePolicyAccepted = localStorage.getItem('CookiePolicyAccepted');
    this.setState({ CookiePolicyAccepted: CookiePolicyAccepted });
  };

  getVehicleTypes = () => {
    let lngParam = getLanguage();
    const code = process.env.REACT_APP_COUNTRY_CODE;
    //  productInstance.get(`vehicleClassification/allUI?countryCode=${code}`).then((response) => {
    //   const vehicleTypes = response.data.result;
    //   this.setState({ vehicleTypes: vehicleTypes });
    // }); // no use
    this.getAllLocations(lngParam);
  };

  // onViewMoreClick = (Id) => {
  //   this.props.history.push(`/vehicletypes/${Id}`);
  // }; // no use

  onLocationMenuClick = (locationId) => {
    localStorage.setItem('locationid', locationId);
    this.props.history.push(`/locations`);
  };

  containsLocation = (locations, id) => {
    var i = -0;
    for (i = 0; i < locations.length; i++) {
      if (locations[i].locationId.toString() == id.toString()) return true;
    }
    return false;
  };

  getAllLocations = (lngParam) => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    branchInstance.get(`all?countryCode=${code}`).then((response) => {
      const { t } = this.props;
      const allBranches = response.data.result;
      const locations = [];
      const airPortLocations = [];
      const citySegmentLocations = [];
      const cityLocations = [];
      locations.push({
        locationId: '-1',
        name: t('All.1'),
      });
      allBranches.forEach((branch) => {
        if (!this.containsLocation(locations, branch.BranchType.BranchTypeID)) {
          locations.push({
            locationId: branch.BranchType.BranchTypeID,
            name: branch.BranchType.Name,
          });

          if (!branch.BranchType.Name.trim().toLowerCase().includes('airport')) {
            citySegmentLocations.push({
              locationId: branch.BranchType.BranchTypeID,
              name: branch.BranchType.Name,
            });
          }
        }
        if (branch.BranchType.Name.trim().toLowerCase().includes('airport')) {
          airPortLocations.push({
            branchTypeID: branch.BranchType.BranchTypeID,
            locationId: branch._id,
            name: branch.Name,
          });
        } else {
          cityLocations.push({
            branchTypeID: branch.BranchType.BranchTypeID,
            locationId: branch._id,
            name: branch.Name,
          });
        }
      });
      this.setState({ locations: locations });
      this.setState({ airPortLocations: airPortLocations });
      this.setState({ citySegmentLocations: citySegmentLocations });
      this.setState({ cityLocations: cityLocations });
    });
  };

  onMobileAppClick = () => {
    this.setState({ errorMessage: 'Mobile App is under construction.  Check back soon.' });
    return false;
  };

  handleClose = () => {
    this.setState({
      errorMessage: '',
    });
  };

  onFooterMenuLinkClick = (path) => {
    this.props.history.push(path);
  };

  onAcceptContinue = () => {
    var cookiesPolicy = 'accepted';
    localStorage.setItem('CookiePolicyAccepted', cookiesPolicy);
    const CookiePolicyAccepted = localStorage.getItem('CookiePolicyAccepted');
    this.setState({ CookiePolicyAccepted: CookiePolicyAccepted });
    //window.location.reload();
  };

  render() {
    const { t } = this.props;

    return (
      <footer className="footer-section">
        <div className="footer-top-wrap">
          <div className="footer-nav-menu-wrap first">
            <div className="container">
              <div className="row">
                <div className="col-lg-2 col-12">
                  <h4>{t('Airport Locations.1')}</h4>
                </div>
                <div className="col-lg-10 col-12">
                  <ul className="nav-item-ul">
                    {this.state.airPortLocations.map((loc) => {
                      return (
                        <li className="nav-item" key={loc.locationId}>
                          <a
                            className={
                              this.state.selectedLocation == loc.locationId
                                ? 'nav-link active'
                                : 'nav-link '
                            }
                            id="pills-home-tab"
                            data-toggle="pill"
                            href="#pills-home"
                            role="tab"
                            aria-controls="pills-home3"
                            aria-selected="false"
                            onClick={() => this.onLocationMenuClick(loc.branchTypeID)}
                          >
                            {loc.name}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-nav-menu-wrap">
            <div className="container">
              <div className="row">
                <div className="col-lg-2 col-12">
                  <h4>{t('City Locations.1')}</h4>
                </div>
                <div className="col-lg-10 col-12">
                  {this.state.citySegmentLocations.map((branch) => {
                    return (
                      <ul className="nav-item-ul" key={branch.locationId}>
                        <span>{branch.name}</span>
                        {this.state.cityLocations.map((loc) => {
                          return branch.locationId == loc.branchTypeID ? (
                            <li className="nav-item" key={loc.locationId}>
                              <a
                                id="pills-home-tab"
                                data-toggle="pill"
                                href="#pills-home"
                                role="tab"
                                aria-controls="pills-home3"
                                aria-selected="false"
                                onClick={() => this.onLocationMenuClick(loc.branchTypeID)}
                              >
                                {loc.name}
                              </a>
                            </li>
                          ) : null;
                        })}
                      </ul>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom-wrap">
          <div className="container">
            <div className="row justify-content-md-between justify-content-center">
              <div className="col-lg-auto col-md-6 col-sm-6 col-12">
                <a href="/" className="footer-logo" title="Dollar">
                  <img src={dollarlogo} width="105" height="29" alt="Dollar Car Rental UAE" />
                </a>

                {/* <div>
                                    <span>Corporate Address (Head Office):</span><br></br>
                                    Zabeel Rent A Car (L.L.C) <br></br>
                                    201, Block A,<br></br>
                                    Arenco Building<br></br>
                                    Al Karama, Zabeel Street,<br></br>
                                    Dubai , United Arab Emirates<br></br><br></br>
                                    <a href={"tel:+97143365065"} className="footer-mobile" title="Call">Phone:
                                    +971 4 336 5065
                                    </a><br></br>
                                    <a href="mailto:marketing@dollaruae.com" className="footer-mobile footer-email" title="Mail">Email:
                                    marketing@dollaruae.com
                                    </a>
                                </div>, */}

                <div>
                  <span>Dubai:</span>
                  <a
                    href="mailto:makeabooking@dollaruae.ae"
                    className="footer-mobile footer-email"
                    title="Mail"
                  >
                    makeabooking@dollaruae.ae
                  </a>
                  <a href={'tel:800 4710'} className="footer-mobile" title="Call">
                    Toll Free: 800 4710
                    <br />
                  </a>
                </div>
                <div>
                  <span>Abu Dhabi:</span>
                  <a
                    href="mailto:adreservations@dollaruae.ae"
                    className="footer-mobile footer-email"
                    title="Mail"
                  >
                    adreservations@dollaruae.ae
                  </a>
                  <a href={'tel:800 9100'} className="footer-mobile" title="Call">
                    Toll Free: 800 9100
                  </a>
                </div>
              </div>

              <div className="col-lg-auto col-md-6 col-sm-6 col-12 mt-4 mt-sm-0">
                <h5>{t('Download App.1')}</h5>
                <ul className="footer-nav-wrap">
                  <li>
                    <a
                      title={t('Google Play Store.1')}
                      className="download-store-app"
                      onClick={this.onMobileAppClick}
                    >
                      <img src={googleplay} alt={t('Google Play Store.1')} />
                    </a>
                  </li>
                  <li>
                    <a
                      title={t('App Store.1')}
                      className="download-store-app"
                      onClick={this.onMobileAppClick}
                    >
                      <img src={appstore} alt={t('App Store.1')} />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-auto col-md-6 col-sm-6 col-12 mt-4 mt-lg-0">
                <h5>{t('The Group.1')}</h5>
                <ul className="footer-nav-wrap">
                  <li>
                    <a href="https://www.aaagroup.com/" target="_blank" title="Arenco">
                      <img src={arencologo} width="80" height="86" alt="Arenco" />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-auto col-md-6 col-sm-6 col-12 mt-4 mt-lg-0">
                <h5 className="empty">&nbsp;</h5>
                <ul className="list-unstyled social-media-footer justify-content-lg-end">
                  <li>
                    <a href="https://www.linkedin.com/company/dollar-rent-a-car-uae">
                      <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.facebook.com/dollar.uae/">
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </li>

                  <li>
                    <a href="https://www.instagram.com/dollar.uae/">
                      <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
          <Dialog
            open={this.state.errorMessage.length > 0}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Dollar'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.errorMessage}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                {t('OK.1')}
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {this.state.loading == true ? (
          <div style={{ verticalAlign: 'center' }}>
            <Spinner animation="border" />
            &nbsp;{this.state.loadingText}
          </div>
        ) : null}
        <div className="copyright-footer">
          <div className="container">
            <div className="info-wrapper">
              <p className="order-lg-0 order-1 mt-lg-0 mt-2">
                &copy;{' '}
                <a href="/" title={t('Dollar.1')}>
                  {t('Dollar.1')}
                </a>
                {/* <script type="text/javascript">
                             document.write(new Date().getFullYear()); }
                        </script> */}
                &nbsp;{t('All Rights Reserved.1')}.
              </p>
              <ul className="list-unstyled info-list-wrap">
                <li>
                  <a href="https://blog.dollaruae.com/" title={t('Blogs.1')} target="_blank">
                    {t('Blogs.1')}
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    title="Terms and conditions"
                    onClick={() => this.onFooterMenuLinkClick('/termsandconditions')}
                  >
                    {t('Terms and conditions.1')}
                  </a>
                </li>
                {/* <li>
                                <a href="" title="Service promise" onClick={() => this.onFooterMenuLinkClick('/servicepromise')}>
                                    {t('Service promise.1')}
                                </a>
                            </li>
                            <li>
                                <a href="" title="Security tips" onClick={() => this.onFooterMenuLinkClick('/securitytips')}>
                                    {t('Security tips.1')}
                                </a>
                            </li> */}
                <li>
                  <a href="" title="Sitemap" onClick={() => this.onFooterMenuLinkClick('/sitemap')}>
                    {t('Sitemap.1')}
                  </a>
                </li>
                {/* <li>
                                <a href="" title=" Disclaimer" onClick={() => this.onFooterMenuLinkClick('/disclaimer')}>
                                    {t('Disclaimer.1')}
                                </a>
                            </li> */}
                <li>
                  <a
                    href=""
                    title={t('Privacy Policy.1')}
                    onClick={() => this.onFooterMenuLinkClick('/privacypolicy')}
                  >
                    {t('Privacy Policy.1')}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {this.state.CookiePolicyAccepted ? (
          ''
        ) : (
          <div className="save-cookies-section">
            <div className="container">
              <div className="save-cookies-content">
                <p>
                  {t(
                    'Our website uses cookies to ensure a better experience for you as a guest on our digital platform. Your consent will help us understand your preferences. Whoever thought of cookies road trippin’ together!.1',
                  )}{' '}
                </p>
                <a onClick={this.onAcceptContinue}>{t('Accept & Continue.1')}</a>
              </div>
            </div>
          </div>
        )}
      </footer>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(ListingFooter)), ListingFooter);

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import twentyfourBySevenimg from '../assets/images/Business/24By7.jfif';
import awardimg from '../assets/images/Business/Award.jfif';
import timeeffectimg from '../assets/images/Business/Time-effective.jfif';
import utmostflexibilityimg from '../assets/images/Business/Utmost-Flexibility.jfif';
import wiserimg from '../assets/images/Business/Wiser.jfif';
import costeffectimg from '../assets/images/Business/costeffect.jfif';
import hasalfreeimg from '../assets/images/Business/hasalfree.jfif';
import perfectimg from '../assets/images/Business/perfect.jfif';
import personalLeasingimg from '../assets/images/Business/personalLeasing.jfif';
import { productInstance } from '../axios/axiosInstance';
import LongTermLease from './LongTermLease';
var qs = require('qs');
class PersonalLeasing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderDafaultValue: 0,
      selectedVehicals: 0,
      selectedOption: 'convertible',
      selectedYear: '12',
      vehicleTypes: [],
      maxVehicles: 500,
      showContact: false,
      leasingEnquiry: {},
    };
  }

  componentDidMount = () => {
    this.getVehicleTypes();

    scroll.scrollToTop();
  };

  getVehicleTypes = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    productInstance.get(`vehicleClassification/allUI?countryCode=${code}`).then((response) => {
      const vehicleTypes = response.data.result;
      this.setState({ vehicleTypes: vehicleTypes });
      if (vehicleTypes.length > 0) {
        this.setState({
          selectedOption: vehicleTypes[0].Name,
        });
      }
    });
  };

  handleSliderChange = (event, newValue) => {
    this.setState({
      selectedVehicals: newValue,
    });
  };

  toogleContactPopup = (show) => {
    this.setState({
      showContact: show,
    });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    this.setState({
      selectedOption: e.target.value,
    });
  };

  onLeasOptionClick = (maxVehicles) => {
    this.setState({ maxVehicles: maxVehicles });
  };

  onChangeYear = (e) => {
    this.setState({
      selectedYear: e.target.value,
    });
  };

  onClick = () => {
    this.setState({
      showContact: true,
    });
    let leasingEnquiry = {
      selectedVehicalType: this.state.selectedOption,
      selectedVehicalQunatity: this.state.selectedVehicals,
      selectedYear: this.state.selectedYear,
    };
    this.setState({
      leasingEnquiry: leasingEnquiry,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t('Long Term Personal Leasing Cars, Monthly Car Rental.1')}</title>
          <link rel="canonical" href="www.dollaruae.com/personal-leasing" />
          <meta
            property="og:title"
            content="Long Term Personal Leasing Cars, Monthly Car Rental"
          ></meta>
          <meta property="og:site_name" content="Dollar Car Rental"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/personalleasing"></meta>
          <meta
            property="og:description"
            content="Dollar Car Rental offers long term personal leasing cars, best vehicle models and pocket-friendly affordable rental plans, that comfortably fall well within your monthly budget."
          ></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta
            property="og:image"
            content="https://www.dollaruae.com/static/media/costeffect.0417e493.jfif"
          ></meta>
          <meta name="title" content="Long Term Personal Leasing Cars, Monthly Car Rental"></meta>
          <meta
            name="description"
            content="Dollar Car Rental offers long term personal leasing cars, best vehicle models and pocket-friendly affordable rental plans, that comfortably fall well within your monthly budget."
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/personalleasing"></meta>
          <meta
            property="og:title"
            content="Long Term Personal Leasing Cars, Monthly Car Rental"
          ></meta>
          <meta
            property="og:description"
            content="Dollar Car Rental offers long term personal leasing cars, best vehicle models and pocket-friendly affordable rental plans, that comfortably fall well within your monthly budget."
          ></meta>
          <meta
            property="og:image"
            content="https://www.dollaruae.com/static/media/costeffect.0417e493.jfif"
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta property="twitter:url" content="https://www.dollaruae.com/personalleasing"></meta>
          <meta
            property="twitter:title"
            content="Long Term Personal Leasing Cars, Monthly Car Rental"
          ></meta>
          <meta
            property="twitter:description"
            content="Dollar Car Rental offers long term personal leasing cars, best vehicle models and pocket-friendly affordable rental plans, that comfortably fall well within your monthly budget."
          ></meta>
          <meta
            property="twitter:image"
            content="https://www.dollaruae.com/static/media/costeffect.0417e493.jfif"
          ></meta>
        </Helmet>
        <ListingHeader />

        <div className="know-us-page py-sm-5 py-3 bg-grey">
          <div className="container">
            <div className="border-titlec-content mb-sm-5 mb-4">
              <h1 className="mb-1">{t('Long Term Personal Leasing.1')}</h1>
              <h2 className="mb-1">{t('Long Term Car Rental.1')}</h2>
              <p className="font-18 text-grey">
                {t('We believe, no road is long with the right company.1')}
              </p>
            </div>
            <div className="row pb-5">
              <div className="col-lg-6 col-12">
                <br />
                <br />
                <img
                  src={personalLeasingimg}
                  className="rounded-xl"
                  alt="Long Term Personal Leasing"
                ></img>
              </div>
              <div className="col-lg-6 col-12">
                <div className="pl-lg-4 pl-0 pt-4 pt-lg-0">
                  <p className="font-18 text-grey">
                    {t(
                      'Renting is the new wise. Of that, there’s no doubt. COVID-19 has changed the way we now look at mobility & convenience. Personal Leasing is a practical approach to having a vehicle to yourself for a longer duration (1-3 years) - without the hassle of maintaining it or having to commit to long term financial contracts.1',
                    )}
                  </p>
                  <p className="font-18 text-grey mt-4">
                    {t(
                      'A reliable and reputed global mobility partner like Dollar Car Rental UAE can help you with a wide range of latest models of car fleet at the best market price in the UAE, on extremely flexible, customer-centric, and favorable terms. Our dedicated Personal Leasing Consultants can help you choose the best vehicle model and pocket-friendly affordable rental plan, that comfortably falls well within your monthly budget. Just let us know your preferred vehicle, be it an SUV, Sedan, Hatchback, or luxury car rental, lease duration, monthly budget, and any other add-ons (child safety seats, GPS trackers, mobile WIFI, etc) and we’ll make sure to help you find the best car rental offers and deals in the UAE.1',
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <LongTermLease></LongTermLease>

          <section className="py-5">
            <div className="container">
              <h2 className="w-85">
                {t(
                  'Let us look at some of the advantages of long-term personal car leasing, especially in the UAE.1',
                )}
              </h2>
              <div className="width-75 py-5 mx-auto">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-12">
                    <img
                      src={hasalfreeimg}
                      className="rounded-xl"
                      alt="Hassle free and Convenient"
                    ></img>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="oddEven-content pl-lg-5 pl-0 pt-4 pt-lg-0">
                      <h3 className="font-24">{t('Hassle-free & Convenient.1')}</h3>
                      <p className="font-18 text-grey pt-sm-3 pt-2">
                        {t(
                          'With Dollar Car Rental UAE, enjoy full-maintenance solutions when you lease a vehicle long term, instead of owning one. This set of solutions include Team Dollar taking care of the end-to-end vehicle registration, insurance, vehicle service and maintenance, Salik (toll) and traffic fine management, licenses, and all other paper works that usually comes with maintaining a car in the UAE.1',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-6 col-12 order-1 order-lg-0">
                    <div className="oddEven-content pr-lg-5 pr-0 pt-4 pt-lg-0">
                      <h3 className="font-24">{t('Cost-effective.1')}</h3>
                      <p className="font-18 text-grey pt-sm-3 pt-2">
                        {t(
                          'At Dollar Car Rental UAE, we believe, the experience of driving your dream vehicle shouldn’t come with a burdening price tag. Buying a new car comes with its own set of paper works and heavy financial commitments, that can lock you for years even if you choose to go with a car loan. When you instead choose to personal lease a vehicle with our long-term pocket-friendly payment plans, you’ll advantage from significant comfort and flexibility financially. This is also true when one considers long-term leasing against public transportation costs like Dubai Metro, RTA taxis, or Careem/Uber cabs.1',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <img src={costeffectimg} className="rounded-xl" alt="Cost Effective"></img>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-6 col-12">
                    <img
                      src={twentyfourBySevenimg}
                      className="rounded-xl"
                      alt="24X7 Supports"
                    ></img>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="oddEven-content pl-lg-5 pl-0 pt-4 pt-lg-0">
                      <h3 className="font-24">{t('24x7 Support.1')}</h3>
                      <p className="font-18 text-grey pt-sm-3 pt-2">
                        {t(
                          'We take pride in being continuously recognized and awarded as one of the leading Car Rental agencies in the UAE for providing excellent customer services and experience. When you lease from Dollar Car Rental UAE, you automatically unlock premium benefits like 24x7 emergency roadside assistance, complimentary quick replacement vehicle, free vehicle towing services, accident repair & insurance management, dedicated customer support personnel, and much more! We make sure, you and your family are in safe hands round the clock, even at odd hours.1',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-6 col-12 order-1 order-lg-0">
                    <div className="oddEven-content pr-lg-5 pr-0 pt-4 pt-lg-0">
                      <h3 className="font-24">{t('Wiser & Smarter Choice.1')}</h3>
                      <p className="font-18 text-grey pt-sm-3 pt-2">
                        {t(
                          'Why deal with depreciating costs of an owned vehicle when you can opt for a long-term car rental plan? Why deal with lengthy paper works, monotonous maintenance, and servicing schedules? Why worry about long-term financial commitments when you can Dollar Car Rental UAE like you own it.1',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <img src={wiserimg} className="rounded-xl" alt="Wiser and Smarter Choice"></img>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-6 col-12">
                    <img src={timeeffectimg} className="rounded-xl" alt="Time Effective"></img>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="oddEven-content pl-lg-5 pl-0 pt-4 pt-lg-0">
                      <h3 className="font-24">{t('Time-effective.1')}</h3>
                      <p className="font-18 text-grey pt-sm-3 pt-2">
                        {t(
                          'Personal Leasing a vehicle comes with straightforward payments. You can save a lot of time with Team Dollar silently managing all the maintenance and service-related to-dos that come with holding a vehicle. Skip long queues and lengthy paper works to instead enjoy complimentary premium delivery/pickup, online reservations, centralized booking access, etc. with our Dollar Red Carpet Services.1',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-6 col-12 order-1 order-lg-0">
                    <div className="oddEven-content pr-lg-5 pr-0 pt-4 pt-lg-0">
                      <h3 className="font-24">{t('Utmost Flexibility.1')}</h3>
                      <p className="font-18 text-grey pt-sm-3 pt-2">
                        {t(
                          'Enjoy the freedom to upgrade your lease plan based on your requirements. Visiting family? Extended stay cation plans? A cross-country road trip with your squad? Just let our customer support personnel know of your needs and we’ll be happy to assist you in changing/modifying the contractual terms and vehicle model.1',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <img
                      src={utmostflexibilityimg}
                      className="rounded-xl"
                      alt="Utmost Flexibility"
                    ></img>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-6 col-12">
                    <img src={perfectimg} className="rounded-xl" alt="Perfect LifeStyle Fit"></img>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="oddEven-content pl-lg-5 pl-0 pt-4 pt-lg-0">
                      <h3 className="font-24">{t('Perfect Lifestyle Fit.1')}</h3>
                      <p className="font-18 text-grey pt-sm-3 pt-2">
                        {t(
                          'Whether you are in the UAE as a tourist vacationing long-term, a new resident, contract-based official business traveler, young professional, visiting family, temporary ex-pat, etc, it only makes sense to lease a vehicle long term, ensuring safety & convenience during your stay in the country. While the 6-month long UAE summers can make choosing public transportation inconvenient at times, it’s also a time where you can enjoy surprise deals, major summer and festival offers at Dollar Car Rental UAE.1',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center pt-5">
                  <div className="col-lg-6 col-12 order-1 order-lg-0">
                    <div className="oddEven-content pr-lg-5 pr-0 pt-4 pt-lg-0">
                      <h3 className="font-24">{t('An Award-winning Experience.1')}</h3>
                      <p className="font-18 text-grey pt-sm-3 pt-2">
                        {t(
                          'Dont deny yourself the UAEs award-winning customer experience and support from Dollar Car Rental, thats trusted and relied on by residents, tourists, and major brands in the UAE alike, as their mobility partner - every single day.1',
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <img src={awardimg} className="rounded-xl" alt="Award Winning Experience"></img>
                  </div>
                </div>

                <div className="row align-items-center pt-5">
                  <div className="col-12">
                    <div className="oddEven-content pt-4 pt-lg-0 text-center">
                      <p className="font-18 text-grey pt-3">
                        {t(
                          'With Dollar Car Rental UAE, you can quick-lease a vehicle for 1 year extendable up to 3 years, starting from prices as low as AED 1200 per month* along with free delivery & pickup services plus the convenience of service counters of Dollar Car Rental UAE in Dubai, Abu Dhabi, Sharjah, Ras Al Khaimah, and Al Ain. Our Leasing Consultants will be happy to share with you the seasons latest competitive rates, offers, and deals from Dollar Car Rental UAE.1',
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(PersonalLeasing);

import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import accountmanager from '../assets/images/account-manager-icon.png';
import convenienceflexible from '../assets/images/convenience-flexibility.png';
import logntermleas from '../assets/images/long-term-leasing.png';
import trainingperiodic from '../assets/images/periodic-training.png';
import salesconsultant from '../assets/images/sales-consultant.png';
import vehiclemodified from '../assets/images/vehicular-modifications.png';

class KeyPointDollar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { t } = this.props;
    return (
      <>
        <section className="box-shadow-white-box-section grey-bg py-2">
          <div className="container-fluid">
            <div className="box-shadow-white-box-a mt-0">
              <div className="">
                <div className="text-center pad-30">
                  <div className="row" id="id2">
                    <div className="col-12">
                      <div className="pickup-location-box" id="id2">
                        <h5 className="font-bold">
                          {t(
                            'Key points that could define Dollar UAE as your total mobility partner.1',
                          )}
                        </h5>
                        <span className="mt-4 mt-md-0">
                          {t(
                            'Our vehicles are modern, reliable & adaptable, making us a wise choice for businesses that involve transportation of expensive and perishable goods.1',
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={salesconsultant} alt="Sales Consultant" />
                      <h6>
                        <b>{t('Sales Consultants.1')}</b>
                      </h6>
                      <span>
                        {t(
                          'Sales Consultants that support you with scalable vehicle acquisition and management solutions ensuring significant cost savings, reduced administrative hassles, increased productivity, and risk mitigation within your organization.1',
                        )}
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={convenienceflexible} alt="Convenience and Flexibility" />
                      <h6>
                        <b>{t('Convenience & Flexibility.1')}</b>
                      </h6>
                      <span>
                        {t(
                          'Our corporate leasing works on a pay-for-usage model, rather than fleet ownership. This allows our clients to use vehicles, based on their requirements with simple straightforward lease rentals, while not having to worry about the hassles of fleet management, servicing, insurance, damage repair, registration rentals, replacement vehicles, and end-of-term sales.1',
                        )}
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={vehiclemodified} alt="Customizable Vehicular Modification" />
                      <h6>
                        <b>{t('Customizable vehicular modifications.1')}</b>
                      </h6>
                      <span>
                        {t(
                          'Customizable vehicular modifications for business-specific requirements: Based on the needs of a large number of our clients, we have expanded and increased our focus on specialized vehicles, targeted towards the transport/logistics/distribution companies and commercial fleet owners in the UAE. We now offer these vehicles on a long-term lease as per your requirements.1',
                        )}
                      </span>
                    </div>

                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={logntermleas} alt="Long Term Leasing" />
                      <h6>
                        <b>{t('Long-term leasing.1')}</b>
                      </h6>
                      <span>{t('Long-term leasing options of up to 5+ years.1')}</span>
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={accountmanager} alt="Dedicated Account Manager" />
                      <h6>
                        <b>{t('Dedicated Account Managers.1')}</b>
                      </h6>
                      <span>
                        {t(
                          'Our team moves with your team, 24x7. Hassle-free interactions, no repeated paperwork, priority agile approach.1',
                        )}
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={trainingperiodic} alt="Periodic Training" />
                      <h6>
                        <b>{t('Periodic training.1')}</b>
                      </h6>
                      <span>
                        {t(
                          'Periodic training & frequent in-house workshops for vehicle drivers and other involved human resources, conducted in all major Emirates.1',
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(KeyPointDollar)), KeyPointDollar);

import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import dollarLogo from '../assets/images/dollar-logo.svg';
import CopyRightFooter from '../Common/CopyRightFooter';
import Footer from '../Common/Footer';
import Header from '../Common/Header';
import FindLocation from '../Hire/FindLocation';
import Subscribe from '../Hire/Subscribe';
import NewsIndex from '../News/NewsIndex';
import BannerSection from './MonthlyBannerSection';
import MonthlyFeatures from './MonthlyFeatures';
import MonthlyRenting from './MonthlyRenting';
import MonthlyTestimonials from './MonthlyTestimonials';
class MonthlySubscription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerActive: true,
      seoImage: dollarLogo,
    };
  }

  componentDidMount() {
    scroll.scrollToTop();
  }

  handleHeaderActive = (value) => {
    if (window.innerWidth > 553) {
      this.setState({ headerActive: value });
    }
  };

  updateSeoimage = (value) => {
    if (value) this.setState({ seoImage: value });
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <Helmet>
          <title>Monthly Car Rental - Explore unmissable deals, offers and discounts</title>
          <meta
            property="og:title"
            content="Monthly Car Rental - Explore unmissable deals, offers and discounts"
          />
          <meta property="og:site_name" content="Dollar Car Rental UAE" />
          <meta property="og:url" content="https://www.dollaruae.com/fleximonthly" />
          <meta
            property="og:description"
            content="Dollar Car Rental offers best deals on monthly car rentals in Dubai and across UAE. Explore exclusive offers, unmissable deals and book your next rental today."
          />
          <meta property="og:type" content="video.other" />
          <meta
            property="og:image"
            content={`http://admin.dollaruae.com//DocumentPath/${this.state.seoImage}`}
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@dollaruae" />
          <meta
            name="twitter:title"
            content="Monthly Car Rental - Explore unmissable deals, offers and discounts"
          />
          <meta
            name="twitter:description"
            content="Dollar Car Rental offers best deals on monthly car rentals in Dubai and across UAE. Explore exclusive offers, unmissable deals and book your next rental today."
          />
          <meta
            name="twitter:image"
            content={`http://admin.dollaruae.com//DocumentPath/${this.state.seoImage}`}
          />
          <link rel="canonical" href="https://www.dollaruae.com/fleximonthly" />
        </Helmet>
        {this.state.headerActive && <Header />}
        <BannerSection handleHeaderActive={this.handleHeaderActive} />
        <MonthlyRenting />
        <MonthlyFeatures />
        <MonthlyTestimonials />
        <Subscribe />
        <NewsIndex />
        <FindLocation />
        <Footer updateSeoimage={this.updateSeoimage} />
        {/* <CopyRightFooter /> */}
      </>
    );
  }
}

export default withTranslation()(MonthlySubscription);

import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';

class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.handleSelect(0);
  }

  handleSelect = (index) => {
    this.setState({ avaliableindex: index });
  };
  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t('FAQ on Car and Vehicle Rentals in UAE | Dollar Car Rental.1')} </title>
          <link rel="canonical" href="www.dollaruae.com/faq" />
          <meta
            property="og:title"
            content="FAQ on Car and Vehicle Rentals in UAE | Dollar Car Rental"
          ></meta>
          <meta property="og:site_name" content="Dollar Car Rental"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/faq"></meta>
          <meta
            property="og:description"
            content="Frequently asked questions on cars and vehicle rentals in UAE from Dollar Car Rental"
          ></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta
            property="og:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
          <meta
            name="title"
            content="FAQ on Car and Vehicle Rentals in UAE | Dollar Car Rental"
          ></meta>
          <meta
            name="description"
            content="Frequently asked questions on cars and vehicle rentals in UAE from Dollar Car Rental"
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/faq"></meta>
          <meta
            property="og:title"
            content="FAQ on Car and Vehicle Rentals in UAE | Dollar Car Rental"
          ></meta>
          <meta
            property="og:description"
            content="Frequently asked questions on cars and vehicle rentals in UAE from Dollar Car Rental"
          ></meta>
          <meta
            property="og:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta property="twitter:url" content="https://www.dollaruae.com/faq"></meta>
          <meta
            property="twitter:title"
            content="FAQon Car and Vehicle Rentals in UAE | Dollar Car Rental"
          ></meta>
          <meta
            property="twitter:description"
            content="Frequently asked questions on cars and vehicle rentals in UAE from Dollar Car Rental"
          ></meta>
          <meta
            property="twitter:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100">
          <section className="box-shadow-white-box-section  grey-bg py-4">
            <div className="container-fluid">
              <div className="">
                <div className="pickup-location-box">
                  <h1 className="border-botom">{t('FAQ on Vehicle Rentals.1')}</h1>
                  <h2 className="border-botom">{t('Frequently Asked Questions at Dollar.1')}</h2>
                  <p className="mb-4">{t('We have got you covered.1')}</p>
                </div>

                <div className="faq-wrapper">
                  {/* <div className="box-title-icon-border d-flex align-items-center mb-4">
                                <span className="box-title-icon-rounded">
                                    <img src={faq} />
                                </span>
                                <h4 className="text-16 text-red">{t('FAQ.1')}</h4>
                            </div> */}
                  <div className="">
                    <div
                      className="panel-group"
                      id="accordion"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseOne"
                              onClick={() => {
                                this.handleSelect(1);
                              }}
                            >
                              {t('What are the basic rental requirements?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseOne" className="panel-collapse collapse in">
                          <div className="panel-body text-muted">
                            <span>
                              {' '}
                              {t(
                                '1. Minimum Age Limit Dollar Car Rental UAE requires one to be of 22 years old and above for all rental plans within the country.1',
                              )}
                              <br />
                              {t('2. Valid Documents Required.1')}
                              <br />
                              {t('For Local Residents.1')}
                              <br />
                              {t('• Emirates ID Copy (Front & Back).1')}
                              <br />
                              {t('• UAE Driving License Copy (Front & Back).1')}
                              <br />
                              {t('• Passport Copy.1')}
                              <br />
                              {t('• Visa Copy.1')}
                              <br />
                              {t('• International Driving License Copy.1')}
                              <br />
                              {t('• Passport Copy.1')}
                              <br />
                              {t('• Visiting Visa Copy.1')}
                              <br />
                              {t('For Tourists (GCC Countries).1')}
                              <br />
                              {t('• Valid GCC Driving License Copy.1')}
                              <br />
                              {t('• Passport Copy.1')}
                              <br />
                              {t('• Visiting Visa Copy.1')}
                              <br />
                              {t(
                                'International or National Driving license in any other language other than Arabic and English should be translated into Arabic or English. The translation should be attested by the Embassy.1',
                              )}
                              <br />
                              {t(
                                '3. Security Deposits Valid Credit Card with minimum 3 months validity.1',
                              )}
                              <br />
                              {t('4. Vehicle Inspection & Add-ons.1')}
                              <br />
                              {t(
                                'Once you’ve ensured the vehicle quality is up to your satisfaction, you may proceed to sign the rental contract and complete the payment process. You may also add on amenities like baby/toddler rear seats, GPS trackers, Mobile WIFI, etc. at this stage.1',
                              )}{' '}
                              <br />
                              {t('• Valid GCC Driving License Copy.1')}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed  font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseTwo"
                              onClick={() => {
                                this.handleSelect(2);
                              }}
                            >
                              {t('Do you offer a vehicle delivery & pick up service?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTwo" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'For your added convenience, we can deliver and collect vehicles right at your doorstep for a nominal fee. This service is subject to availability.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThree"
                              onClick={() => {
                                this.handleSelect(3);
                              }}
                            >
                              {t('Can I reserve a specific make/model car?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseThree" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Dollar Car Rental UAE does not guarantee a specific make/model/color of the vehicle against any reservation. Should the specific car group/make/model not be available at the point of pick-up, Dollar reserves the right to offer an alternate make/model within the same group.1',
                              )}{' '}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseFour"
                              onClick={() => {
                                this.handleSelect(4);
                              }}
                            >
                              {t('What happens when I return the car late?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFour" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {' '}
                              {t(
                                'Additional charges are applicable based on the signed rental contract. You may nevertheless, check with our team for an authorized extension.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseFive"
                              onClick={() => {
                                this.handleSelect(5);
                              }}
                            >
                              {t('How about the Fuel Policy at Dollar Car Rental?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFive" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'One must return the vehicle with the same fuel level as given by the company. If not, the hirer will be charged the cost of fuel plus a service charge.1',
                              )}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseSix"
                              onClick={() => {
                                this.handleSelect(6);
                              }}
                            >
                              {t(
                                'What should I do when my vehicle needs servicing and maintenance?.1',
                              )}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseSix" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Please inform Dollar Team or use the ‘Book A Service’ feature from our website to arrange an appointment with one of our service representatives. We will book your vehicle in for service and arrange a replacement vehicle.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseSeven"
                              onClick={() => {
                                this.handleSelect(7);
                              }}
                            >
                              {t('What are my options for mode of Payment?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseSeven" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Dollar Car Rental UAE accepts all major Credit Cards (MASTER, VISA, JCB, DINERS). Credit card must be valid for a minimum of 3 months from the day of rental and must be physically available at the time of vehicle collection.1',
                              )}{' '}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseEight"
                              onClick={() => {
                                this.handleSelect(8);
                              }}
                            >
                              {t('Can I rent a car without a credit card?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseEight" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>Yes, you can </span>
                            <span>
                              If you do not possess a credit card we offer the option for payment
                              and security blocking to be provided on a debit card A security
                              deposit of AED 1500 AED 3000 will be charged to your debit card This
                              deposit will be automatically returned to your debit card 30 days
                              after the closure of your rental agreement{' '}
                            </span>
                            <span>
                              security deposit may vary based on category of vehicle chosen
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseNine"
                              onClick={() => {
                                this.handleSelect(9);
                              }}
                            >
                              {t('What are the parking rules to be followed in the UAE?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseNine" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Parking across the UAE is easily available. Paid Parking along roadsides are strictly followed, with parking meters under the same.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseTen"
                              onClick={() => {
                                this.handleSelect(10);
                              }}
                            >
                              {t('What about Traffic violations?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {' '}
                              {t(
                                'Most roads in the UAE are radar-controlled and thus it is in the driver’s interest to adhere to all speed limits & road signs. Any Traffic Violation is a direct offense of the driver. All fines related to your dollar rental car will be processed by our team while subsequent notifications and costs are invoiced to the customer. For every traffic fine incurred, you will be charged 10% extra to cover the administration charges and knowledge Fee, as imposed by Government of Dubai.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseEleven"
                              onClick={() => {
                                this.handleSelect(11);
                              }}
                            >
                              {t('What do we do in the case of breakdown/accidents?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseEleven" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {' '}
                              {t(
                                'Dollar offers you 24x7 breakdown and roadside assistance. And in the unforeseen circumstances of your vehicle being involved in an accident, please note that the vehicle should not be moved from the point of the accident or damage, except in the case where the damage is minor and the vehicle is causing an obstruction to the traffic. You will then call the police and remain with the vehicle in order to obtain a police report to be submitted to Dollar, so that an insurance claim can be made. Kindly notify our 24x7 support zone, 800 Dollar and they will facilitate any replacement vehicle that may be required.1',
                              )}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseTwelve"
                              onClick={() => {
                                this.handleSelect(12);
                              }}
                            >
                              {t('What should I do if my rental vehicle is stolen?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseTwelve" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {' '}
                              {t(
                                'Report the theft to the police immediately, obtain a copy of the police report and then notify Dollar Car Rental at the earliest.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseThirteen"
                              onClick={() => {
                                this.handleSelect(13);
                              }}
                            >
                              {t('Can I add additional drivers for the same car rental?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseThirteen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Yes, you may add an extra driver, for a minimal charge, provided he/she fills all the relevant details.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseFourteen"
                              onClick={() => {
                                this.handleSelect(14);
                              }}
                            >
                              {t('What is the policy on off-road driving?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFourteen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Off-road use is a violation of the rental agreement and is not allowed.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseFifteen"
                              onClick={() => {
                                this.handleSelect(15);
                              }}
                            >
                              {t('What is your Policy on driving to Oman?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseFifteen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Our cars may be driven into Oman with prior purchase of an Oman Insurance Certificate along with the No Objection Letter available at the rental desk. Charges vary based on the vehicles, time and period. However, certain vehicle groups may not be allowed to travel into Oman territory. Driving to Bahrain, Saudi Arabia, Qatar, Yemen, Kuwait, etc. is strictly forbidden.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseSixteen"
                              onClick={() => {
                                this.handleSelect(16);
                              }}
                            >
                              {t('What are the expected additional charges?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseSixteen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Additional charges related to the rental include Salik (toll fees), any driving/traffic fine or violation charges imposed by RTA/Police/Municipality, Re-fueling Service Charge, additional driver charge, baby seats and GPS, Airport Return Charges, and for any Dubai registered rented or leased vehicle a Vehicle License Fee will be applied accordingly as implemented/mandated by the Roads and Transport Authority (RTA) of Dubai.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#collapseSeventeen"
                              onClick={() => {
                                this.handleSelect(17);
                              }}
                            >
                              {t('How can I avail the best offers at Dollar Car Rental UAE?.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="collapseSeventeen" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>
                              {t(
                                'Dollar Car Rental guarantees the best prices, assured seasonal offers and surprise deals, 365 days a year! You can follow us on social media to stay tuned or ask our representative to update you on the ongoing promotions.1',
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#Answer1"
                              onClick={() => {
                                this.handleSelect(17);
                              }}
                            >
                              {t('Question1.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="Answer1" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>{t('Answer1.1')}</span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#Answer2"
                              onClick={() => {
                                this.handleSelect(17);
                              }}
                            >
                              {t('Question2.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="Answer2" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>{t('Answer2.1')}</span>
                          </div>
                        </div>
                      </div>
                      <div className="box-shadow-white-box py-3 px-4">
                        <div className="panel-heading">
                          <h4 className="panel-title">
                            <a
                              style={{ color: 'black' }}
                              className="accordion-toggle collapsed font-bold"
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#Answer3"
                              onClick={() => {
                                this.handleSelect(17);
                              }}
                            >
                              {t('Question3.1')}
                            </a>
                          </h4>
                        </div>
                        <div id="Answer3" className="panel-collapse collapse">
                          <div className="panel-body">
                            <span>{t('Answer3.1')}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default hoistStatics(withTranslation()(FAQ), FAQ);

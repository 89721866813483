import React, { Component } from 'react';
import amex from './../assets/images/cards/amex.svg';
import applePay from './../assets/images/cards/applePay.svg';
import dinerClub from './../assets/images/cards/dinerClub.svg';
import jcb from './../assets/images/cards/jcb.svg';
import masterCard from './../assets/images/cards/masterCard.svg';
import mercury from './../assets/images/cards/mercury.svg';
import samsungPay from './../assets/images/cards/samsungPay.svg';
import unionPay from './../assets/images/cards/unionPay.svg';
import visa from './../assets/images/cards/visa.svg';
import './ImageGallery.css';

const cardImages = [
  { src: amex, alt: 'American Express' },
  { src: mercury, alt: 'Mercury' },
  { src: dinerClub, alt: 'Diners Club' },
  { src: jcb, alt: 'JCB' },
  { src: masterCard, alt: 'MasterCard' },
  { src: samsungPay, alt: 'Samsung Pay' },
  { src: unionPay, alt: 'UnionPay' },
  { src: visa, alt: 'Visa' },
  { src: applePay, alt: 'applePay' },
];

class ImageGallery extends Component {
  state = {
    paymentMethod: 1,
  };

  handlePaymentSelection = (method) => {
    this.props.handlePaymentMethodSelection(method);
    this.setState({ paymentMethod: method });
  };

  renderOption = (method, title, content) => (
    <div
      className={`paymentOption ${this.state.paymentMethod === method ? 'active' : ''}`}
      onClick={() => this.handlePaymentSelection(method)}
    >
      <div className="optionHeader">
        <input
          type="radio"
          checked={this.state.paymentMethod === method}
          onChange={() => this.handlePaymentSelection(method)}
          className="radioInput"
        />
        <label className="paymentMethod">{title}</label>
      </div>
      {content}
    </div>
  );

  render() {
    return (
      <>
        {/* <div className="dividerLine" /> */}

        <div className="sectionSpacing">
          {this.renderOption(
            1,
            <span className="paymentTitle">Credit / Debit Cards</span>,
            <div className="cardImagesWrapper">
              {cardImages.map((image, index) => (
                <div key={index} className="cardImageContainer">
                  <img src={image.src} alt={image.alt} className="paymentIconImage" />
                </div>
              ))}
            </div>,
          )}
        </div>

        {/* <div className="dividerLine" /> */}

        <div className="sectionSpacing">
          {this.renderOption(
            2,
            <span className="paymentTitle">Etihad GuestPay</span>,
            <div className="cardImagesWrapper">
              <div className="cardImageContainer">
                <img
                  id="etihad_guest_pay"
                  alt="Etihad GuestPay"
                  src={process.env.REACT_APP_POINTS_PAY_LOGO}
                  className="paymentIconImage"
                />
              </div>
            </div>,
          )}
        </div>
      </>
    );
  }
}

export default ImageGallery;

import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import tickMarkIcon from '../assets/images/tick-mark.png';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';

class ThankYou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageToDisplay: 'Thank you for getting in touch.',
    };
  }

  componentDidMount = () => {
    let messageToDisplay = 'Thank you for getting in touch.';
    let message = localStorage.getItem('thanksmessage');
    if (message !== null && message.toString() !== '') {
      messageToDisplay = message.toString();
    }
    localStorage.removeItem('thanksmessage');
    this.setState({ messageToDisplay: messageToDisplay });
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Dollar Car Rental UAE | Thank You</title>
          <link rel="canonical" href="www.dollaruae.com/thankyou" />
          <meta
            name="description"
            content="For support related to reservations, upcoming rentals, rentals in progress, corporate lease, or other enquiries/concerns -we're 24x7 right here for you. Call or write to us and we will be happy to help!  "
          ></meta>
        </Helmet>

        <div>
          <ListingHeader></ListingHeader>

          <div className="minH100 box-shadow-white-box-a my-0 background-grey">
            <div className="container">
              <div className="row pt-5 align-items-center justify-content-center ">
                <div className="col-sm-12 col-lg-8 col-md-12 col-12">
                  <div id="reserve p-3 contact-us-quick-box">
                    <div className="rental-steps-wrap ">
                      <div className="pickup-location-box text-center">
                        <img src={tickMarkIcon} alt="Thank you" className="mb-3" />
                        <h3 className="font-bold mb-2  ">{this.state.messageToDisplay}</h3>
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
              <br></br>
              <div className="col-lg-12">
                <div className="p-3 contact-us-quick-box">
                  <h5>Do you have further Question?</h5>
                  <p></p>
                  <div className="contact-us-quick-call">
                    <a className="phone-quick" href={'tel:+97143365065'}>
                      <i className="fa fa-phone"></i>+971 4 336 5065
                    </a>
                    <a className="email-quick" href="mailto:customer.care@dollaruae.ae">
                      Email address: customer.care@dollaruae.ae
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ListingFooter />
        </div>
      </>
    );
  }
}

export default hoistStatics(withTranslation()(ThankYou), ListingFooter);

import React from 'react';
import styles from './BenefitCard.module.css';

const BenefitCard = ({ title, description, image, layout }) => {
  return (
    <div
      className={`${styles.benefitCard} ${layout === 'left' ? styles.imageLeft : styles.imageRight}`}
    >
      <img src={image} alt={title} className={styles.benefitImage} />
      <div className={styles.benefitContent}>
        <h3 className={styles.benefitTitle}>{title}</h3>
        <p className={styles.benefitDescription}>{description}</p>
      </div>
    </div>
  );
};

export default BenefitCard;

import moment from 'moment-timezone';
import React, { Component } from 'react';
import 'react-google-flight-datepicker/dist/main.css';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getLanguage } from '../Common/Util';
import StartBooking from '../MonthlySubscription/Tabs/StartBooking';
import { contentInstance } from '../axios/axiosInstance';
import QuoteRequest from './QuoteRequest';

class BookingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      specialoffer: {},
      headerActive: true,
    };
  }

  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;
    let lngParam = getLanguage();

    this.getSpecialOffer(params.index, lngParam);
    scroll.scrollToTop();
  };

  getSpecialOffer = (SpecialOfferID, lngParam) => {
    contentInstance
      .get(`offers/ui/${SpecialOfferID}`)
      .then((response) => {
        this.setState({
          specialoffer: response.data.result,
        });
      })
      .catch((error) => {});
  };

  handleBanner = (value) => {};

  handleHeaderActive = (value) => {
    if (window.innerWidth > 553) {
      this.setState({ headerActive: value });
    }
  };

  render() {
    const { t } = this.props;
    const promoCodeMatch = this.state.specialoffer[0]?.Code;
    const promoCode = promoCodeMatch ? promoCodeMatch : null;

    return (
      <>
        {this.state.headerActive && <ListingHeader />}

        <div className="special-offer-page-wrap bg-grey minH100">
          {this.state.headerActive && (
            <section className="box-shadow-white-box-section pt-3" id="partner">
              <div className="container-fluid">
                <div className="box-shadow-white-box-a ">
                  <div className="">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="special-offer-banner">
                          {this.state.specialoffer[0]?.OfferAttachment.ImageString && (
                            <img
                              src={this.state.specialoffer[0]?.OfferAttachment.ImageString}
                              alt={t('Booking Details.1')}
                              className="img-fluid"
                            />
                          )}

                          <div className="discount-sticker">
                            {this.state.specialoffer[0]?.FavouriteNote}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}
          <div className="position-relative search-widget-module">
            {promoCode !== null && promoCode?.length > 0 ? (
              <section className="sort-c1 bookingDeatils">
                <div className="inner-wrapper" style={{ background: 'white' }}>
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="tab-content " id="nav-tabContent">
                          <StartBooking
                            manageBanner={this.handleBanner}
                            handleHeaderActive={this.handleHeaderActive}
                            promoCode={promoCode}
                            page={'L'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
          </div>

          <section className="box-shadow-white-box-section pb-4">
            <div className="container-fluid">
              <div className="box-shadow-white-box mb-0" id="About">
                <div className="row">
                  <div className="col-lg-7 p-3">
                    <h2 className="border-botom">{this.state.specialoffer[0]?.Name}</h2>
                    <p className="text_p">{this.state.specialoffer[0]?.Description}</p>
                    {
                      // this.state.specialoffer.BookingMode == 1 &&
                      this.state.specialoffer[0]?.EnableBookingDateValidation == true ? (
                        <>
                          <div className="special-offer-start-end-date-wrap">
                            <div className="special-offer-start-end-date-col">
                              <h6 className="font-weight-bold">{t('Start Date.1')}</h6>
                              <p className="text_p mt-2">
                                {moment(this.state.specialoffer[0]?.BookingFrom).format(
                                  'DD MMM YYYY, h:mm A',
                                )}
                              </p>
                            </div>
                            <div className="special-offer-start-end-date-col">
                              <h6 className="font-weight-bold">{t('End Date.1')}</h6>
                              <p className="text_p mt-2">
                                {moment(this.state.specialoffer[0]?.BookingTo).format(
                                  'DD MMM YYYY, h:mm A',
                                )}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : null
                    }

                    {this.state.specialoffer[0]?.TermsAndConditions != '' ? (
                      <>
                        <h6 className="font-weight-bold mt-4 text-center text-sm-left">
                          {t('Terms & Conditions.1')}
                        </h6>
                        <p className="text_p text-center text-sm-left">
                          {this.state.specialoffer[0]?.TermsAndConditions}
                        </p>
                      </>
                    ) : null}
                  </div>
                  {promoCode !== null && promoCode?.length > 0 ? null : ( // <SpecialOfferBooking />
                    <QuoteRequest SpecialOfferID={this.state.specialoffer[0]?._id} />
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(withRouter(withRouter(BookingDetails)));

import React from 'react';
import styles from './CarFeatures.module.css';

const FeatureItem = ({ iconSrc, value, alt }) => (
  <div className={styles.featureItem}>
    <img loading="lazy" src={iconSrc} alt={alt} className={styles.featureIcon} />
    <div className={styles.featureValue}>{value}</div>
  </div>
);

export default FeatureItem;

import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import infothin from '../assets/images/iconmonstr-info-thin.svg';
import popupinfo from '../assets/images/popup-info-btn.svg';
import soldout from '../assets/images/Sold-Out-Transparent.png';
import whatsapp from '../assets/images/whatsapp.svg';
import { bookingInstance } from '../axios/axiosInstance';
import { getCurrency, getLanguage } from '../Common/Util';
import VehicleFeatureBox from '../Common/VehicleFeatureBox';
import ViewIncludeLongText from '../ListingPage/ViewIncludeLongText';
import EmailPopup from './EmailPopup';

class IncludePageInline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payNowRate: '',
      rackRate: '',
      whatsappNumber: '',
      contactNo: '',
      isCheked: true,
      paySectionValue: '',
      showEmail: false,
      includeSection: [],
      currencyCode: '',
      windowSize: 1024,
      isMobileView: false,
    };
  }

  componentDidUpdate(prevProps) {
    this.state.currencyCode = getCurrency();
    if (prevProps.model != this.props.model) {
      this.checkPaymentMode();
      let lngParam = getLanguage();
      this.getBookingIncludeSection(lngParam);
    }
  }

  checkPaymentMode = () => {
    if (this.props.model.PaymentMode == 0) {
      this.setState({
        isCheked: false,
      });
      this.setState({
        paySectionValue: 'payletter',
      });
    } else {
      this.setState({
        isCheked: true,
      });
      this.setState({
        paySectionValue: 'paynow',
      });
    }
  };

  componentDidMount() {
    let lngParam = getLanguage();
    this.getBookingSetting();
    this.getBookingIncludeSection(lngParam);
    this.checkPaymentMode();
  }

  getBookingIncludeSection = () => {
    if (this.props.model.TariffGroup != null) {
      this.props.searchParam.VehicleModelID = this.props.model.VehicleModelID;
      this.props.searchParam.TariffGroupID = this.props.model.TariffGroup.TariffGroupID;
      const code = process.env.REACT_APP_COUNTRY_CODE;
      bookingInstance
        .post(`included?countryCode=${code}`, this.props.searchParam)
        .then((response) => {
          this.setState({ includeSection: response.data.result.data });
        })
        .catch((error) => {});
    }
  };

  toogleEmailPopup = (showHide) => {
    this.setState({
      showEmail: showHide,
    });
  };

  onBookNowClickHandler = () => {
    this.props.onVehicleChange(this.props.model, this.state.paySectionValue);
  };

  onCloseHandler = () => {
    this.props.onVehicleIndexChange(9999, {});
  };

  getBookingSetting = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    bookingInstance
      .get(`setting`)
      .then((response) => {
        this.setState({
          whatsappNumber: response.data.BookingPortalInformation.WhatsAppContactNo,
          contactNo: response.data.BookingPortalInformation.CallContactNo,
        });
      })
      .catch((error) => {});
  };

  onChangeValue = (e) => {
    const isCheked = this.state.isCheked;
    this.setState({
      isCheked: !isCheked,
      paySectionValue: e.target.value,
    });
  };

  toogleViewIncludeLongTextPopUp = (showHide, LongText) => {
    this.setState({
      showExtrasDetails: showHide,
    });
    this.setState({
      LongTextShow: LongText,
    });
  };

  render() {
    const { t } = this.props;
    return this.props.model != null && this.props.model.VehicleImages != null ? (
      <div id="carTemp1">
        <div className="vehicle-box-detail-wrap" id={this.props.model.VehicleModelID}>
          <div className="vehicle-box-detail-left">
            {
              <div className="vehicle-detail-slider-wrap slick-initialized slick-slider">
                {this.props.model.IsSoldOut == true ? (
                  <>
                    <span className="soldOutDiv vehicle-sold-out">
                      <img alt="Sold-Out-Transparent" src={soldout} className="soldOut_img" />
                    </span>
                    <div style={{ textAlign: 'center' }}>
                      <img
                        className="carouselImage"
                        src={this.props.model.VehicleImages[0]}
                        alt="First slide"
                      />
                    </div>
                  </>
                ) : (
                  <div className="slick-list draggable">
                    <div style={{ textAlign: 'center' }}>
                      <img
                        className="carouselImage"
                        src={this.props.model.VehicleImages[0]}
                        alt="First slide"
                      />
                    </div>
                  </div>
                )}
              </div>
            }
            <ul className="check-box-listing">
              {this.state.includeSection != null
                ? this.state.includeSection.map((include) => {
                    return (
                      <li>
                        <span>
                          {include.ShortText}
                          <a
                            className="text-16 text-red font-bold d-block"
                            onClick={() =>
                              this.toogleViewIncludeLongTextPopUp(true, include.LongText)
                            }
                          >
                            <img alt="Sold-Out-Transparent" src={infothin} />
                          </a>
                        </span>
                      </li>
                    );
                  })
                : ''}
            </ul>
          </div>
          <div className="vehicle-box-detail-right">
            <div className="vehicle-box-detail-right-content">
              <h4>{this.props.model.Name}</h4>
              <span className="similar-text">
                {t('or Similar.1')} | {this.props.model.VehicleClassification?.Name}
              </span>
              <button type="button" className="close" aria-label="Close">
                <span aria-hidden="true">
                  <img src={popupinfo} alt="Info" width="40" height="40" />
                </span>
                <ul>
                  <li onClick={() => this.toogleEmailPopup(true)}>
                    <a title="mail" className="book-now-all book-now-email">
                      <i className="fa fa-envelope-o" aria-hidden="true"></i>
                      {t('Email.1')}
                    </a>
                  </li>
                  <li>
                    <a
                      title="Contact the Supplier directly via Phone call at +97143365065"
                      data-transitionid="84c36da3-cd10-422d-a6dd-2165a1251bfb"
                      data-transitiontype="SpecialOffer"
                      data-mediatype="PhoneCall"
                      href={`tel:+97143365065`}
                      className="book-now-all book-now-phone"
                    >
                      <i className="fa fa-phone" aria-hidden="true"></i>
                      {t('Call.1')}
                    </a>
                  </li>
                  <li>
                    <a
                      title="Contact the Supplier directly via WhatsApp at +971529780591"
                      data-transitionid="84c36da3-cd10-422d-a6dd-2165a1251bfb"
                      data-transitiontype="SpecialOffer"
                      data-mediatype="WhatsApp"
                      target="_blank"
                      href={`https://api.whatsapp.com/send?phone=+971529780591}&text=Hi, I'm contacting you through dollaruae.com - I would like to rent the car. Is it available?`}
                      className="book-now-all book-now-whatsapp"
                    >
                      <img src={whatsapp} alt="Whatsapp" width="20" height="20" />
                      {t('WhatsApp.1')}
                    </a>
                  </li>
                </ul>
              </button>
              <VehicleFeatureBox model={this.props.model} />
              <div className="included-wrapper-featured">
                {this.props.model.Features.length > 0 ? (
                  <p className="vehicle-featuress-content">
                    {t('Vehicle Features.1')}
                    <br></br>
                    {this.props.model.Features.map((feature, index) => {
                      return (
                        <label key={feature.Name} style={{ color: 'grey' }}>
                          {(index ? ', ' : '') + feature.Name}
                        </label>
                      );
                    })}
                  </p>
                ) : null}
              </div>

              {this.props.model.Description.length > 0 ? (
                <div className="included-wrapper-featured">
                  <p className="vehicle-featuress-content">
                    {t('Vehicle Description.1')}
                    <br></br>

                    <label style={{ color: 'grey' }}>{this.props.model.Description}</label>
                  </p>
                </div>
              ) : null}

              <div className="featured-rent-price-radio">
                <div className="featured-rent-radio">
                  {this.props.model.PaymentMode == 2 || this.props.model.PaymentMode == 1 ? (
                    this.props.searchParam.ModifiedReservationID ==
                    '00000000-0000-0000-0000-000000000000' ? (
                      <div className="featured-rent-box-radio">
                        <input
                          type="radio"
                          id="paynow"
                          name="featured-rent-price-radio"
                          value="paynow"
                          checked={this.state.isCheked}
                          onChange={this.onChangeValue}
                          disabled={
                            this.props.model.TariffGroup != null &&
                            !this.props.model.TariffGroup.IsOnFreeSale
                          }
                          style={{
                            cursor:
                              this.props.model.TariffGroup != null &&
                              !this.props.model.TariffGroup.IsOnFreeSale
                                ? 'not-allowed'
                                : 'pointer',
                          }}
                        />
                        <label
                          htmlFor="paynow"
                          style={{
                            cursor: !this.props.model.TariffGroup.IsOnFreeSale
                              ? 'not-allowed'
                              : 'pointer',
                          }}
                        >
                          <span>
                            {t('Pay Now.1')} <p>{this.state.currencyCode}</p>{' '}
                            <b> {this.props.model.RentalCharges.PayNowTotal.toFixed(2)}</b>
                          </span>{' '}
                          | {this.props.model.RentalDays} days
                          <p>{t('Save Money Instantly.1')}</p>
                        </label>
                      </div>
                    ) : null
                  ) : null}
                  {this.props.model.PaymentMode == 0 || this.props.model.PaymentMode == 2 ? (
                    this.props.searchParam.ModifiedReservationID !=
                    '00000000-0000-0000-0000-000000000000' ? (
                      <div className="featured-rent-box-radio">
                        <input
                          type="radio"
                          id="payletter"
                          name="featured-rent-price-radio"
                          value="payletter"
                          checked={!this.state.isCheked}
                          onChange={this.onChangeValue}
                          style={{ cursor: 'pointer' }}
                        />
                        <label htmlFor="payletter" style={{ cursor: 'pointer' }}>
                          <span>
                            {t('Pay Later.1')} <p>{this.state.currencyCode}</p>{' '}
                            <b> {this.props.model.RentalCharges.RackRateTotal.toFixed(2)}</b>
                          </span>{' '}
                          | {this.props.model.RentalDays} days
                          <p>{t('When you pick your keys.1')}</p>
                        </label>
                      </div>
                    ) : this.props.model.PaymentMode == 0 || this.props.model.PaymentMode == 2 ? (
                      <div className="featured-rent-box-radio">
                        <input
                          type="radio"
                          id="payletter"
                          name="featured-rent-price-radio"
                          value="payletter"
                          checked={!this.state.isCheked}
                          onChange={this.onChangeValue}
                          style={{ cursor: 'pointer' }}
                        />
                        <label htmlFor="payletter" style={{ cursor: 'pointer' }}>
                          <span>
                            {t('Pay Later.1')} <p>{this.state.currencyCode}</p>{' '}
                            <b> {this.props.model.RentalCharges.RackRateTotal.toFixed(2)}</b>
                          </span>{' '}
                          | {this.props.model.RentalDays} days
                          <p>{t('When you pick your keys.1')}</p>
                        </label>
                      </div>
                    ) : null
                  ) : null}
                </div>
              </div>
              <div className="book-now-all-bttns-wrap">
                {this.props.model.IsSoldOut ? (
                  this.props.model.IsCallToBook ? (
                    <a
                      title="Book Now"
                      className="book-now-btttn"
                      style={{ cursor: 'not-allowed' }}
                    >
                      {t('Call Now.1')} - 800 4710
                    </a>
                  ) : (
                    <a
                      title="Book Now"
                      className="book-now-btttn"
                      style={{ cursor: 'not-allowed' }}
                    >
                      {t('Book Now.1')}
                    </a>
                  )
                ) : this.props.model.IsCallToBook ? (
                  <a title="Book Now" className="book-now-btttn" href={'tel:+97143365065'}>
                    {t('Call Now.1')} - +971 4 336 5065
                  </a>
                ) : (
                  <a
                    title="Book Now"
                    className="book-now-btttn"
                    onClick={this.onBookNowClickHandler}
                  >
                    {t('Book Now.1')}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>

        {this.state.showExtrasDetails ? (
          <div>
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <Modal
                show={this.state.showExtrasDetails}
                onHide={() => this.toogleViewIncludeLongTextPopUp(false)}
                dialogClassName="modal-dialog modal-xl modal-dialog-centered view-details-wrap"
              >
                <ViewIncludeLongText LongTextShow={this.state.LongTextShow} />
              </Modal>
            </div>
          </div>
        ) : null}
        {this.state.showEmail ? (
          <div className="modal-dialog modal-xl modal-dialog-centered">
            <Modal
              show={this.state.showEmail}
              onHide={() => this.toogleEmailPopup(false)}
              dialogClassName="modal-dialog modal-xl modal-dialog-centered"
            >
              <EmailPopup
                model={this.props.model}
                searchParam={this.props.searchParam}
                additionalParam={this.props.additionalParam}
                includeSection={this.state.includeSection}
              />
            </Modal>
          </div>
        ) : null}
      </div>
    ) : null;
  }
}

export default hoistStatics(withTranslation()(IncludePageInline), IncludePageInline);

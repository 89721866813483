import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import moengage from '@moengage/web-sdk';
import { Component } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { getLanguage } from '../../Common/Util';
import envelopImg from '../../assets/images/envelop.svg';
import hashImg from '../../assets/images/hash.svg';
import { bookingInstance } from '../../axios/axiosInstance';
import './tabsStyle.css';

class ManageBooking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationNo: '',
      email: '',
      errorMessage: '',
      loading: false,
      isActive: 0,
    };
  }

  confiramtionNoHandle = (confirmationNo) => {
    this.setState({ confirmationNo });
  };

  handleEmail = (email) => {
    this.setState({ email });
  };

  validate = () => {
    let lngParam = getLanguage();

    this.setState({
      errorMessage: '',
    });

    let valid = true;
    if (this.state.confirmationNo.trim() === '') {
      if (lngParam === 'en') {
        this.setState({
          errorMessage: 'The confirmationNo field is required',
        });
      } else {
        //ar
        this.setState({
          errorMessage: 'مطلوب مجال confirmationNo',
        });
      }

      return false;
    }
    if (this.state.email === undefined || this.state.email === '') {
      if (lngParam === 'en') {
        this.setState({ errorMessage: 'The email field is required' });
      } else {
        //ar
        this.setState({
          errorMessage: 'The email field is required',
        });
      }

      return false;
    }
    return valid;
  };

  handleClose = () => {
    this.setState({
      errorMessage: '',
    });
  };

  findResvervationHandle = (e) => {
    e.preventDefault();
    this.setState({ isActive: 0 });
    if (this.validate()) {
      const code = process.env.REACT_APP_COUNTRY_CODE;
      moengage.add_email(this.state.email);
      bookingInstance
        .post(
          `filter/?countryCode=${code}&bookingNo=${this.state.confirmationNo}&emailId=${this.state.email}`,
        )
        .then((response) => {
          response.data = response.data.result.data;
          this.setState({ loading: false });
          this.props.history.push(`/reservationmodify/${response.data[0]}/1`);
        })
        .catch((error) => {
          if (error) {
            this.setState({ errorMessage: error?.response?.data?.message });
            this.setState({ loading: false });
          }
        });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="" id="manageBooking" role="tabpanel" aria-labelledby="manageBookingtab">
          <form onSubmit={(e) => this.findResvervationHandle(e)}>
            <div className="inner-wrapper">
              <div className="confirm-number inner-box">
                <label htmlFor="confirmNumber">
                  <span>{t('Confirmation Number.1')}</span>
                </label>
                <div
                  className={`input ${this.state.isActive === 1 ? 'active' : ''}`}
                  onClick={() => this.setState({ isActive: 1 })}
                >
                  <div className="icon">
                    <img src={hashImg} alt="" />
                  </div>
                  <input
                    type="text"
                    placeholder="Enter Confirmation Number"
                    id="confirmNumber"
                    onChange={(e) => this.confiramtionNoHandle(e.target.value)}
                  />
                </div>
              </div>
              <div className="email">
                <div className=" inner-box">
                  <label htmlFor="email">
                    <span>{t('Email.1')}</span>
                  </label>
                  <div
                    className={`input ${this.state.isActive === 2 ? 'active' : ''}`}
                    onClick={() => this.setState({ isActive: 2 })}
                  >
                    <div className="icon">
                      <img src={envelopImg} alt="" />
                    </div>
                    <input
                      type="text"
                      placeholder="Enter Email"
                      id="email"
                      onChange={(e) => this.handleEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <button type="submit" className="primary-button">
                {t('Find Reservation.1')}
              </button>
            </div>
          </form>
        </div>

        <div className="row">
          <div className="col col-lg-6">
            <div className="form-group">
              {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
                <Dialog
                  open={this.state.errorMessage.length > 0}
                  onClose={this.handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
                    {'Dollar'}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      {this.state.errorMessage}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions style={{ justifyContent: 'center' }}>
                    <Button
                      onClick={this.handleClose}
                      variant="outlined"
                      color="error"
                      style={{
                        background: '#E31A37',
                        color: 'white',
                        justifyContent: 'center',
                        width: '100%',
                        margin: '0px 47px',
                      }}
                    >
                      {t('OK.1')}
                    </Button>
                  </DialogActions>
                </Dialog>
              ) : null}
              {this.state.loading === true ? (
                <div style={{ verticalAlign: 'center' }}>
                  <Spinner animation="border" />
                  &nbsp;{'Sending quote request.1'}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withTranslation()(withRouter(withRouter(ManageBooking)));

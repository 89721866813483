import axios from 'axios';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll, scroller } from 'react-scroll';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { getCurrency, getLanguage, getUserInfo } from '../Common/Util';
import IncludePageInline from '../Include/IncludePageInline';
import noResultImage from '../assets/images/icons8-spinner.gif';
import sortBy from '../assets/images/sort-by-icon.svg';
import { bookingInstance } from '../axios/axiosInstance';
import ListingCard from './ListingCard';
import ListingPage3 from './ListingPage3';
import RentalDetails from './RentalDetails';

class ListingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      selectVehicleForBooking: {},
      vehicleTypeDefaultValue: 'Vehicle Type',
      vehicleModels: [],
      isFirstTimeLoad: true,
      allVehicleTypes: [],
      showStickHeader: false,
      selectedVehicleIndex: 9999,
      isActive: 0,
      columnCount: 4,
      showRentalDetails: false,
      step1: 1,
      paySectionValue: '',
      selectedVehicleType: '00000000-0000-0000-0000-000000000000',

      additionalParam: {
        pickupLocationName: '',
        dropOffLocationName: '',
        pickupLocationAddress: '',
        dropOffLocationAddress: '',
        pickupLocationMessage: '',
        dropOffLocationMessage: '',
        pickupLocationBranchDescription: '',
        dropOffLocationBranchDescription: '',
        originalVehicleModelID: '00000000-0000-0000-0000-000000000000',
        originalTariffGroupID: '00000000-0000-0000-0000-000000000000',
        originalTariffRateID: '00000000-0000-0000-0000-000000000000',
      },

      searchParam: {
        Classifications: [],
        VehicleModelID: '00000000-0000-0000-0000-000000000000',
        CheckOutOn: '2024-09-20T15:51:00',
        CheckInOn: '2024-09-23T15:50:00',
        DeliveryDetails: {
          Address: '',
          StateName: '',
          StateCode: '',
        },
        PickupDetails: {
          Address: '',
          StateName: '',
          StateCode: '',
        },
        CheckOutBranchID: '663cc0d59ce22eb7250a0045',
        CheckInBranchID: '663cc0d59ce22eb7250a0045',
        PromoCode: '',
        ShukranId: '',
        PaymentStatus: 1,
        ModifiedReservationID: '00000000-0000-0000-0000-000000000000',
        ContactID: '00000000-0000-0000-0000-000000000000',
        RentalDays: 0,
        Daily: true,
      },
      iPAddress: '127.0.0.1',
      customerCountryCode: 'UAE',
      customerCountryName: 'United_Arab_Emirate',
      isChecked: false,
      isHeaderActive: true,
      showPayNowLaterBtn: true,
    };
  }

  componentDidMount() {
    this.getIPAddress();
    window.addEventListener('scroll', this.handleScroll, true);
    window.addEventListener('resize', this.update);
    this.update();
    getCurrency();
    setTimeout(() => {
      this.GetVehicleModels();
    }, 1000);
    const userinfo = getUserInfo();

    if (userinfo !== null) this.state.searchParam.ContactID = userinfo.CustomerID;
  }

  update = () => {
    let columnCount = 4;
    if (window.innerWidth <= 530) columnCount = 1;
    if (window.innerWidth > 530 && window.innerWidth <= 767) columnCount = 2;
    if (window.innerWidth > 767 && window.innerWidth <= 1024) columnCount = 3;
    if (window.innerWidth > 991) columnCount = 4;
    this.setState({ columnCount: columnCount });
  };

  GetVehicleModels = () => {
    const BookingInfo = JSON.parse(localStorage.getItem('BookingInfo'));

    const defaultParams = {
      CheckOutLocationId: '',
      CheckInLocationId: '',
      PickUpDate: '',
      ReturnDate: '',
      promocode: '',
      ShukranId: '',
      pickupLocationName: '',
      dropOffLocationName: '',
      pickupLocationAddress: '',
      dropOffLocationAddress: '',
      pickupLocationMessage: '',
      dropOffLocationMessage: '',
      pickupLocationBranchDescription: '',
      dropOffLocationBranchDescription: '',
      deliveryState: '',
      deliveryCode: '',
      deliveryAddress: '',
      returnState: '',
      returnCode: '',
      returnAddress: '',
      rentalDays: 0,
      daily: true,
    };

    let params = { ...defaultParams };
    let searchParam = { ...this.state.searchParam };
    let additionalParam = { ...this.state.additionalParam };

    if (BookingInfo && BookingInfo.length) {
      const booking = BookingInfo[0];
      const modifiedReservationID = booking?.ModifiedReservationID;

      if (
        modifiedReservationID &&
        modifiedReservationID !== '00000000-0000-0000-0000-000000000000'
      ) {
        this.setState({ showPayNowLaterBtn: false });
      }

      params = {
        ...params,
        CheckOutLocationId: booking.CheckOutLocationId,
        CheckInLocationId: booking.CheckInLocationId,
        PickUpDate: booking.PickUpDate,
        ReturnDate: booking.ReturnDate,
        pickupLocationName:
          booking.pickupLocationName || `${booking.returnState} ${booking.returnAddress}`,
        dropOffLocationName:
          booking.dropOffLocationName || `${booking.deliveryState} ${booking.deliveryAddress}`,
        promocode: booking.promocode,
        ShukranId: booking.ShukranId,
        pickupLocationAddress: booking.pickupLocationAddress,
        dropOffLocationAddress: booking.dropOffLocationAddress,
        pickupLocationBranchDescription: booking.pickupLocationBranchDescription,
        dropOffLocationBranchDescription: booking.dropOffLocationBranchDescription,
        pickupLocationMessage: booking.pickupLocationMessage,
        dropOffLocationMessage: booking.dropOffLocationMessage,
        deliveryState: booking.deliveryState,
        deliveryCode: booking.deliveryCode,
        deliveryAddress: booking.deliveryAddress,
        returnState: booking.returnState,
        returnCode: booking.returnCode,
        returnAddress: booking.returnAddress,
        rentalDays: booking.RentalDays,
        daily: true,
      };

      searchParam = {
        ...searchParam,
        CheckOutBranchID: params.CheckOutLocationId,
        CheckInBranchID: params.CheckInLocationId,
        CheckInOn: params.ReturnDate,
        CheckOutOn: params.PickUpDate,
        PromoCode: params.promocode,
        ShukranId: params.ShukranId,
        PickupDetails: {
          StateName: params.returnState,
          StateCode: params.returnCode,
          Address: params.returnAddress,
        },
        DeliveryDetails: {
          StateName: params.deliveryState,
          StateCode: params.deliveryCode,
          Address: params.deliveryAddress,
        },
        Daily: params.daily,
        RentalDays: params.rentalDays,
      };

      additionalParam = {
        ...additionalParam,
        pickupLocationName: params.dropOffLocationName,
        dropOffLocationName: params.pickupLocationName,
        pickupLocationAddress: params.dropOffLocationAddress,
        dropOffLocationAddress: params.pickupLocationAddress,
        pickupLocationMessage: params.dropOffLocationMessage,
        dropOffLocationMessage: params.pickupLocationMessage,
        pickupLocationBranchDescription: params.dropOffLocationBranchDescription,
        dropOffLocationBranchDescription: params.pickupLocationBranchDescription,
      };

      if (modifiedReservationID) {
        searchParam.ModifiedReservationID = modifiedReservationID;
        localStorage.removeItem('BookingInfo');
        setTimeout(() => {
          this.setState({ showDetails: true });
        }, 1000);
      }
    } else {
      params = {
        ...params,
        CheckOutLocationId: this.state.searchParam.CheckOutBranchID,
        CheckInLocationId: this.state.searchParam.CheckInBranchID,
        PickUpDate: this.state.searchParam.CheckOutOn,
        ReturnDate: this.state.searchParam.CheckInOn,
        rentalDays: this.state.searchParam.RentalDays,
        daily: this.state.searchParam.Daily,
        pickupLocationName: this.state.additionalParam.pickupLocationName,
        dropOffLocationName: this.state.additionalParam.dropOffLocationName,
      };
    }

    const vehicleTypeParam = localStorage.getItem('VehicleTypeParam');
    if (vehicleTypeParam) {
      searchParam.Classifications = [vehicleTypeParam];
      this.setState({ vehicleTypeDefaultValue: vehicleTypeParam });

      setTimeout(() => {
        this.setState({ showDetails: true });
      }, 1000);
      localStorage.removeItem('VehicleTypeParam');
    }

    this.setState({ searchParam, additionalParam });
    this.fetchVehicleModels(
      params.ReturnDate,
      params.PickUpDate,
      params.CheckOutLocationId,
      params.CheckInLocationId,
      searchParam.Classifications,
      params.promocode,
      params.ShukranId,
      params.deliveryState,
      params.deliveryCode,
      params.deliveryAddress,
      params.returnState,
      params.returnCode,
      params.returnAddress,
      params.rentalDays,
      params.daily,
    );
  };

  onVehicleTypeChange = (e) => {
    let searchParam = this.state.searchParam;
    let classfications = [];

    if (e != '00000000-0000-0000-0000-000000000000') classfications.push(e);

    this.setState({ selectedVehicleType: e });
    searchParam.Classifications = classfications;
    this.setState({ searchParam: searchParam });
    this.setState({ vehicleTypeDefaultValue: e });

    var deliveryState = null;
    var deliveryCode = null;
    var deliveryAddress = null;
    var returnState = null;
    var returnCode = null;
    var returnAddress = null;

    const BookingInfo = JSON.parse(localStorage.getItem('BookingInfo'));

    if (BookingInfo != null && BookingInfo != undefined) {
      deliveryState = BookingInfo[0].deliveryState;
      deliveryCode = BookingInfo[0].deliveryCode;
      deliveryAddress = BookingInfo[0].deliveryAddress;
      returnState = BookingInfo[0].returnState;
      returnCode = BookingInfo[0].returnCode;
      returnAddress = BookingInfo[0].returnAddress;
    }

    this.fetchVehicleModels(
      this.state.searchParam.CheckInOn,
      this.state.searchParam.CheckOutOn,
      this.state.searchParam.CheckOutBranchID,
      this.state.searchParam.CheckInBranchID,
      classfications,
      this.state.searchParam.PromoCode,
      this.state.searchParam.ShukranId,
      deliveryState,
      deliveryCode,
      deliveryAddress,
      returnState,
      returnCode,
      returnAddress,
      this.state.searchParam.RentalDays,
      this.state.searchParam.Daily,
    );
  };

  onSortChange = (e) => {
    let vehicles;

    if (e.target.value == 'lowhigh')
      vehicles = this.state.vehicleModels.sort((a, b) =>
        a.RentalCharges.DiscountedRate > b.RentalCharges.DiscountedRate ? 1 : -1,
      );
    else if (e.target.value == 'highlow')
      vehicles = this.state.vehicleModels.sort((a, b) =>
        b.RentalCharges.DiscountedRate > a.RentalCharges.DiscountedRate ? 1 : -1,
      );
    else
      vehicles = this.state.vehicleModels.sort((a, b) =>
        (a.IsBestSeller ? 1 : 0) > (b.IsBestSeller ? 1 : 0) ? 1 : -1,
      );

    this.setState({ vehicleModels: vehicles });
  };

  getIPAddress = () => {
    var config = {
      method: 'get',
      url: 'https://geolocation-db.com/json/',
      headers: {},
    };

    axios(config)
      .then((res) => {
        this.setState({
          iPAddress: res.data.IPv4,
          customerCountryCode: res.data.country_code,
          customerCountryName: res.data.country_name,
        });
      })
      .catch((error) => {});
  };

  fetchVehicleModels = (
    pickupDate,
    returnDate,
    checkOutLocationId,
    checkInLocationId,
    vehicleType,
    promocode,
    ShukranId,
    deliveryState,
    deliveryCode,
    deliveryAddress,
    returnState,
    returnCode,
    returnAddress,
    rentalDays,
    daily,
  ) => {
    this.onSelectedVehicleIndexChange(9999, {});

    const lngParam = getLanguage();
    let paramVehicleType = [];

    if (vehicleType.length > 0 && vehicleType[0] !== 'Vehicle Type') {
      paramVehicleType = [vehicleType[0]];
    }

    let searchParam = {
      CheckOutOn: returnDate,
      CheckInOn: pickupDate,
      CheckOutBranchID: checkOutLocationId,
      CheckInBranchID: checkInLocationId,
      PromoCode: promocode?.length === 16 ? '' : promocode,
      ShukranId: ShukranId,
      ModifiedReservationID: this.state.searchParam.ModifiedReservationID,
      IPLocation: {
        IPAddress: this.state.iPAddress,
        Country_code: this.state.customerCountryCode,
        Country_name: this.state.customerCountryName,
      },
      Daily: daily,
      RentalDays: rentalDays,
      ...(paramVehicleType.length && { Classifications: paramVehicleType }),
      ...(deliveryCode &&
        deliveryCode.length && {
          DeliveryDetails: {
            Address: deliveryAddress,
            StateName: deliveryState,
            StateCode: deliveryCode,
          },
        }),
      ...(returnCode &&
        returnCode.length && {
          PickupDetails: {
            Address: returnAddress,
            StateName: returnState,
            StateCode: returnCode,
          },
        }),
    };

    const countryCode = process.env.REACT_APP_COUNTRY_CODE;
    bookingInstance
      .post(`reservation?countryCode=${countryCode}`, searchParam)
      .then((response) => {
        const { data } = response.data.result;
        if (data && data.SearchVehicleModels.length > 0) {
          this.setState({
            vehicleModels: data.SearchVehicleModels,
            allVehicleTypes: data.VehicleTypes,
          });
        } else {
          this.setState({ isFirstTimeLoad: false });
        }
      })
      .catch((error) => {
        if (error) this.setState({ isFirstTimeLoad: false });
      });
  };

  toogleBookNowPopup = (step) => {
    this.setState({ step1: step });
    this.setState({ isActive: 1 });

    if (step == 1) this.setState({ showDetails: !this.state.showDetails });
  };

  onUpgradeModelSelected = (
    selectedModel,
    originalVehicleModelID,
    originalTariffGroupID,
    originalTariffRateID,
  ) => {
    let additionalParam = this.state.additionalParam;
    additionalParam.originalVehicleModelID = originalVehicleModelID;
    additionalParam.originalTariffGroupID = originalTariffGroupID;
    additionalParam.originalTariffRateID = originalTariffRateID;
    this.setState({ additionalParam: additionalParam });
    this.onVehicleChange(selectedModel, '');
  };

  onVehicleChange = (selectedModel, paySelection) => {
    this.setState({ selectVehicleForBooking: selectedModel });
    this.setState({ paySectionValue: paySelection });
    let searchParam = this.state.searchParam;

    if (paySelection == 'paynow' || selectedModel.PaymentStatus == 1) searchParam.PaymentStatus = 1;
    else searchParam.PaymentStatus = 0;

    this.setState({ searchParam: searchParam });
    this.toogleBookNowPopup(4);
    scroll.scrollToTop();
  };

  onContinueClickHandler = (searchParam) => {
    this.setState({ searchParam: searchParam });
    this.toogleBookNowPopup(4);
    scroll.scrollToTop();
  };

  onSelectedVehicleIndexChange = (index, selectedVehicle) => {
    this.setState({
      selectedVehicleIndex: index,
      selectVehicleForBooking: selectedVehicle,
    });

    if (selectedVehicle != null)
      setTimeout(function () {
        scroller.scrollTo(selectedVehicle.VehicleModelID + '_box', {
          smooth: 'easeInOutQuint',
        });
      }, 1000);
  };

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    let lastScrollY = window.scrollY;

    if (lastScrollY > 100) this.setState({ showStickHeader: true });
    else this.setState({ showStickHeader: false });
  };

  showBookingDetails = () => {
    this.setState({ showDetails: !this.state.showDetails });
  };

  handleHeaderActive = (index) => {
    if (window.innerWidth > 567.75) this.setState({ isHeaderActive: index });
  };

  render() {
    const { t } = this.props;
    let remaining = this.state.vehicleModels.length % this.state.columnCount;

    return (
      <div className="grey-bg">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Book a Car for Rent | Get Your Car</title>
          <link rel="canonical" href="www.dollaruae.com/vehicle-listing" />
          <meta
            name="description"
            content="Book a car with flexible rates - Secure your travel"
          ></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta
            property="og:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
          <meta name="title" content="Book a Car for Rent | Get Your Car"></meta>
          <meta
            name="description"
            content="Book a Car with flexible rates - Secure Your travel"
          ></meta>
          <meta property="og:type" content="web site"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/Booking"></meta>
          <meta property="o g:title" content="Book a Car for Rent | Get Your Car"></meta>
          <meta
            property="og:description"
            content="Book a Car with flexible rates - Secure Your travel"
          ></meta>
          <meta
            property="og:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
          <meta property="twitter:Card" content="summary_large_image"></meta>
          <meta property="twitter:url" content="https://www.dollaruae.com/Booking"></meta>
          <meta property="twitter:title" content="Book a Car for Rent | Get Your Car"></meta>
          <meta
            property="twitter:description"
            content="Book a Car with flexible rates - Secure Your travel"
          ></meta>
          <meta
            property="twitter:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
        </Helmet>

        {this.state.isHeaderActive && <ListingHeader></ListingHeader>}
        <div className="minH100  pay-2">
          <section
            className={
              this.state.showStickHeader
                ? 'rental-steps-section rental-steps-sticky'
                : 'rental-steps-section'
            }
          >
            <div className="container-fluid">
              <ul className="rental-steps-wrap new--rental-steps-wrap">
                <li className="active checked" onClick={() => this.toogleBookNowPopup(1)}>
                  <a className="rental-step-dropdown" title="Rental Details" data-steps="1">
                    <span className="rental-steps-title">{t('Location & Vehicle.1')}</span>
                    <div className="rental-steps-content rental-steps-done">
                      <div className="rental-detail-place">
                        <div className="rental-detail-place-left" onClick={this.showBookingDetails}>
                          <span>{this.state.additionalParam.dropOffLocationName}</span>
                        </div>
                        <div
                          className="rental-detail-place-right"
                          onClick={this.showBookingDetails}
                        >
                          <span>{this.state.additionalParam.pickupLocationName}</span>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
                <li
                  className={
                    this.state.step1 == 1 && this.state.showRentalDetails == false
                      ? 'active'
                      : this.state.step1 > 2
                        ? 'active checked rental-step-dropdown'
                        : 'rental-step-dropdown'
                  }
                  onClick={() => this.toogleBookNowPopup(1)}
                >
                  <a className="rental-step-dropdown" title="Vehicle Selection" data-steps="2">
                    <span className="rental-steps-title">{t('Add-Ons & Documents.1')}</span>
                    <div className="rental-steps-content rental-steps-done">
                      <div className="rental-detail-place">
                        <div className="rental-detail-place-left">
                          <span>
                            {this.state.selectVehicleForBooking != null
                              ? this.state.selectVehicleForBooking.Name
                              : ''}
                          </span>
                        </div>
                        <div className="rental-detail-place-right">
                          <span>
                            {this.state.selectVehicleForBooking != null ? 'or Similar' : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                  </a>
                  {this.state.selectVehicleForBooking == null ? (
                    <div className="rental-steps-content rental-steps-current">{t('Select.1')}</div>
                  ) : null}
                </li>

                <li className={this.state.step1 == 4 ? 'active' : 'rental-step-dropdown'}>
                  <a className="rental-step-dropdown" title="Rental Details" data-steps="4">
                    <span className="rental-steps-title">{t('Payment & Confirmation.1')}</span>
                  </a>
                  {this.state.step1 == 4 ? (
                    <div className="rental-steps-content rental-steps-current">{t('Select.1')}</div>
                  ) : null}
                </li>
              </ul>
            </div>
          </section>

          {this.state.step1 == 1 ? (
            <div>
              <RentalDetails
                key="rentalDetails1"
                showDetails={this.state.showDetails}
                PickUpDate={this.state.searchParam.CheckOutOn}
                ReturnDate={this.state.searchParam.CheckInOn}
                pickupLocationName={this.state.additionalParam.dropOffLocationName}
                dropOffLocationName={this.state.additionalParam.pickupLocationName}
                promoCode={this.state.searchParam.PromoCode}
                modifiedReservationID={this.state.searchParam.ModifiedReservationID}
                deliveryState={this.state.searchParam.DeliveryDetails.StateName}
                deliveryCode={this.state.searchParam.DeliveryDetails.StateCode}
                deliveryAddress={this.state.searchParam.DeliveryDetails.Address}
                returnState={this.state.searchParam.PickupDetails.StateName}
                returnCode={this.state.searchParam.PickupDetails.StateCode}
                returnAddress={this.state.searchParam.PickupDetails.Address}
                handleHeaderActive={this.handleHeaderActive}
              />

              <section className="offer-box-section mt-4">
                <div className="container-fluid">
                  <div className="choose-vehicle-title">
                    <h1 className="h4">{t('Choose your wheels.1')}</h1>
                    <div className="vehicle-type-box">
                      <img src={sortBy} />
                      <select
                        name="sort-by"
                        className="custom-filter-box"
                        onChange={this.onSortChange}
                        defaultValue={'asc'}
                      >
                        <option key={1} disabled="" value="asc">
                          {t('Sort by.1')}
                        </option>
                        <option value="lowhigh">{t('Low to High.1')}</option>
                        <option value="highlow">{t('High to Low.1')}</option>
                        <option value="mostpopular">{t('Best Sellers.1')}</option>
                      </select>
                    </div>
                  </div>

                  <div className="offer-filter-wrappper">
                    <div className="offer-filter-wrap">
                      <div
                        key="all-vehicles"
                        className={
                          this.state.selectedVehicleType == '00000000-0000-0000-0000-000000000000'
                            ? 'what--new-box what--new-box-all active'
                            : 'what--new-box what--new-box-all'
                        }
                        value={this.state.vehicleTypeDefaultValue}
                      >
                        <a
                          className="what--new-link what--new-cr"
                          onClick={() => {
                            this.onVehicleTypeChange('00000000-0000-0000-0000-000000000000');
                          }}
                        >
                          <p>{t('All.1')}</p>
                        </a>
                      </div>
                      {this.state.allVehicleTypes.map((type) => (
                        <div
                          key={type.VehicleClassification.VehicleClassificationID}
                          className={
                            this.state.selectedVehicleType ==
                            type.VehicleClassification.VehicleClassificationID
                              ? 'what--new-box active'
                              : 'what--new-box'
                          }
                          value={this.state.vehicleTypeDefaultValue}
                        >
                          <a
                            className="what--new-link what--new-cr"
                            onClick={() => {
                              this.onVehicleTypeChange(
                                type.VehicleClassification.VehicleClassificationID,
                              );
                            }}
                          >
                            <p>{type.VehicleClassification.Name}</p>
                            <div className="what-new-box-more-content mt-0">
                              <p className="mb-0">
                                <span>
                                  {t('From')} {t('AED.1')} {(type.MinPrice * 1.05).toFixed(0)}
                                </span>
                              </p>
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </section>

              <section className="offer-box-section">
                <div className="container-fluid">
                  <div className="offer-box-wrapper">
                    {this.state.searchParam.PromoCode !== '' &&
                    this.state.searchParam.PromoCode !== undefined ? (
                      <div className="row justify-content-end mt-4 w-100 no-gutters pr-3">
                        <div className="col-auto">
                          <div className="promocode-applied-box">
                            {this.state.promoCode?.length !== 16
                              ? t('Added Promo Code.1')
                              : t('Added Shukran Id')}
                            : <span>{this.state.searchParam.PromoCode}</span>{' '}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {this.state.vehicleModels.length > 0 ? (
                      this.state.vehicleModels.map((model, index) => {
                        return (index + 1) % this.state.columnCount !== 0 ? (
                          <ListingCard
                            key={model.VehicleModelID}
                            showPayNowLaterBtn={this.state.showPayNowLaterBtn}
                            model={model}
                            onVehicleChange={this.onVehicleChange}
                            shouldRenderBtn={true}
                            onUpgradeModelSelected={this.onUpgradeModelSelected}
                            hideInclude={0}
                            hideUpgradeOptions={0}
                            showDiffPrice={0}
                            searchParam={this.state.searchParam}
                            additionalParam={this.state.additionalParam}
                            vehicleIndex={index + 1}
                            onVehicleIndexChange={this.onSelectedVehicleIndexChange}
                            selectedVehicleIndex={this.state.selectedVehicleIndex}
                            days={model.RentalDays}
                          />
                        ) : this.state.selectedVehicleIndex > index + 1 - this.state.columnCount &&
                          this.state.selectedVehicleIndex <= index + 1 ? (
                          <>
                            <ListingCard
                              key={model.VehicleModelID}
                              showPayNowLaterBtn={this.state.showPayNowLaterBtn}
                              shouldRenderBtn={true}
                              onUpgradeModelSelected={this.onUpgradeModelSelected}
                              model={model}
                              onVehicleChange={this.onVehicleChange}
                              hideInclude={0}
                              hideUpgradeOptions={0}
                              showDiffPrice={0}
                              searchParam={this.state.searchParam}
                              additionalParam={this.state.additionalParam}
                              vehicleIndex={index + 1}
                              onVehicleIndexChange={this.onSelectedVehicleIndexChange}
                              selectedVehicleIndex={this.state.selectedVehicleIndex}
                              days={model.RentalDays}
                            />

                            <IncludePageInline
                              id="carTemp1"
                              key={`include-${index}`}
                              model={this.state.selectVehicleForBooking}
                              onVehicleChange={this.onVehicleChange}
                              hideInclude={0}
                              hideUpgradeOptions={0}
                              searchParam={this.state.searchParam}
                              additionalParam={this.state.additionalParam}
                              onVehicleIndexChange={this.onSelectedVehicleIndexChange}
                            ></IncludePageInline>
                          </>
                        ) : (
                          <ListingCard
                            key={model.VehicleModelID}
                            showPayNowLaterBtn={this.state.showPayNowLaterBtn}
                            shouldRenderBtn={true}
                            onUpgradeModelSelected={this.onUpgradeModelSelected}
                            model={model}
                            onVehicleChange={this.onVehicleChange}
                            hideInclude={0}
                            hideUpgradeOptions={0}
                            showDiffPrice={0}
                            searchParam={this.state.searchParam}
                            additionalParam={this.state.additionalParam}
                            vehicleIndex={index + 1}
                            onVehicleIndexChange={this.onSelectedVehicleIndexChange}
                            selectedVehicleIndex={this.state.selectedVehicleIndex}
                            days={model.RentalDays}
                          />
                        );
                      })
                    ) : this.state.isFirstTimeLoad == false ? (
                      <div className="nodata-for-rent-section">
                        <h3>{t('Ohh ! No vehicles available for requested date.1')}</h3>
                        <p>
                          {t(
                            'Please try to search for another date period or contact our customer care at 800 Dollar.1',
                          )}
                        </p>
                      </div>
                    ) : (
                      <div className="nodata-for-rent-section">
                        <h3>{t('Searching for best rates for you, please wait.1')}</h3>
                        <br></br>
                        <br></br>
                        <img
                          src={noResultImage}
                          alt={t('Searching for Best rates for you.1')}
                          style={{ width: '100px', height: '100px' }}
                        />
                      </div>
                    )}
                    {remaining != 0 &&
                    this.state.selectedVehicleIndex >
                      this.state.vehicleModels.length - remaining ? (
                      <IncludePageInline
                        key={`remaining-${remaining}`}
                        model={this.state.selectVehicleForBooking}
                        onVehicleChange={this.onVehicleChange}
                        hideInclude={0}
                        hideUpgradeOptions={0}
                        searchParam={this.state.searchParam}
                        additionalParam={this.state.additionalParam}
                        onVehicleIndexChange={this.onSelectedVehicleIndexChange}
                      ></IncludePageInline>
                    ) : null}
                  </div>
                </div>
              </section>
            </div>
          ) : null}
          {this.state.step1 == 2 ? <div></div> : null}

          {this.state.step1 == 4 ? (
            <div>
              <ListingPage3
                selectVehicleForBooking={this.state.selectVehicleForBooking}
                searchParam={this.state.searchParam}
                additionalParam={this.state.additionalParam}
                onUpgradeModelSelected={this.onUpgradeModelSelected}
                iPAddress={this.state.iPAddress}
                customerCountryCode={this.state.customerCountryCode}
                customerCountryName={this.state.customerCountryName}
              ></ListingPage3>
            </div>
          ) : null}
        </div>
        <ListingFooter></ListingFooter>
      </div>
    );
  }
}

export default hoistStatics(withTranslation()(ListingPage), ListingPage);

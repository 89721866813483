import moengage from '@moengage/web-sdk';

const initializeMoEngage = () => {
  moengage.initialize({
    app_id: process.env.REACT_APP_MOENGAGE_APP_ID,
    cluster: process.env.REACT_APP_MOENGAGE_CLUSTER,
    debug_logs: process.env.REACT_APP_MOENGAGE_LOGS_DEBUG === 'true',
    enableSPA: process.env.REACT_APP_MOENGAGE_SPA_ENABLE === 'true',
    useLatest: process.env.REACT_APP_MOENGAGE_LATEST_VERSION === 'true',
  });
};

export default initializeMoEngage;

import React, { useState } from 'react';
import ReasonCard from './ReasonCard';
import styles from './WhyRentFromDollar.module.css';

const reasonsData = [
  {
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/ef10819fd4bbc830ca690253b24af1b84e9cf1e17466f960bccd9ef9eec791fc?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    title: (
      <>
        <span className={styles.boldBlack}>Insured</span> Fleet of Vehicles
      </>
    ),
    description: '',
  },
  {
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/ae19106805cec3186753081ac041862be9547853839578ec6e28e88c1dc6a352?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    title: (
      <>
        <span className={styles.boldBlack}>Latest models</span> &{' '}
        <span className={styles.boldBlack}>youngest fleet</span> in the region
      </>
    ),
    description: '',
  },
  {
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/1b2ad61bad4fa4e2f49f10e542ec18b45db4a64278f44783d3e7ea76c7471fa4?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    title: (
      <>
        <span className={styles.boldBlack}>24/7</span> Roadside Assistance
      </>
    ),
    description: '',
  },
  {
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/0e3ae4a9a52c663afa9d767de92dbeb7de13ad1af4f47703ea8da4627b571cf1?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    title: (
      <>
        <span className={styles.boldBlack}>Rent from more than 28+ locations</span> across the{' '}
        <span className={styles.boldBlack}>UAE</span>
      </>
    ),
    description: '',
  },
  {
    image:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/407ba55583f84517f7b4897469d884d1983522075db8c30ae08b1d26b7de051c?apiKey=ac85e0a3444a46c7baa2de216eef8e21&&apiKey=ac85e0a3444a46c7baa2de216eef8e21',
    title: (
      <>
        <span className={styles.boldBlack}>No hidden charges</span> & transparency{' '}
        <span className={styles.boldBlack}>guaranteed</span>
      </>
    ),
    description: '',
  },
];

const WhyRentFromDollar = ({ isMobileView, isTabletView }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? reasonsData.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === reasonsData.length - 1 ? 0 : prevIndex + 1));
  };

  const renderCardsForTabletView = () => {
    const visibleCards = [
      reasonsData[currentIndex],
      reasonsData[(currentIndex + 1) % reasonsData.length],
    ];
    return (
      <div className={styles.reasonCardContainer}>
        {visibleCards.map((reason, index) => (
          <ReasonCard key={index} {...reason} />
        ))}
      </div>
    );
  };

  return (
    <section className={styles.whyRentSection}>
      <h2 className={styles.sectionTitle}>Why rent a car from dollar?</h2>
      {isMobileView ? (
        <>
          <div className={styles.reasonCard}>
            <img
              src={reasonsData[currentIndex].image}
              alt={reasonsData[currentIndex].title}
              className={styles.reasonImage}
            />
            <div className={styles.reasonContent}>
              <h3 className={styles.reasonTitle}>{reasonsData[currentIndex].title}</h3>
            </div>
          </div>
          <div className={styles.carouselControl}>
            <span className={styles.buttonContainer}>
              <button onClick={handlePrev} className={styles.arrowButton}>
                {'<'}
              </button>
              <button onClick={handleNext} className={styles.arrowButton}>
                {'>'}
              </button>
            </span>
          </div>
        </>
      ) : isTabletView ? (
        <>
          {renderCardsForTabletView()}
          <div className={styles.carouselControl}>
            <span className={styles.buttonContainer}>
              <button onClick={handlePrev} className={styles.arrowButton}>
                {'<'}
              </button>
              <button onClick={handleNext} className={styles.arrowButton}>
                {'>'}
              </button>
            </span>
          </div>
        </>
      ) : (
        <>
          <div className={styles.reasonsGrid3}>
            {reasonsData.slice(0, 3).map((reason, index) => (
              <ReasonCard key={index} {...reason} />
            ))}
          </div>
          <div className={styles.reasonsGrid2}>
            {reasonsData.slice(3).map((reason, index) => (
              <ReasonCard key={index} {...reason} />
            ))}
          </div>
        </>
      )}
    </section>
  );
};

export default WhyRentFromDollar;

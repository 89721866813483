import Slider from '@material-ui/core/Slider';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import ContactPopup from '../Common/ContactPopup';
import { productInstance } from '../axios/axiosInstance';

class ProductSections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderDafaultValue: 0,
      selectedVehicals: 0,
      selectedOption: 'convertible',
      selectedYear: '12',
      vehicleTypes: [],
      showContact: false,
      maxVehicles: 5,
      leasingEnquiry: {},
      mode: 1,
    };
  }

  componentDidMount = () => {
    this.getVehicleTypes();
  };

  getVehicleTypes = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    productInstance.get(`vehicleClassification/allUI?countryCode=${code}`).then((response) => {
      const vehicleTypes = response.data.result;
      this.setState({ vehicleTypes: vehicleTypes });

      if (vehicleTypes.length > 0) {
        this.setState({
          selectedOption: vehicleTypes[0].Name,
        });
      }
    });
  };

  onKnowMoreClick = () => {
    if (this.state.mode == 0) {
      this.props.history.push('/corporateleasing');
    } else {
      this.props.history.push('/personalleasing');
    }
    scroll.scrollToTop();
  };

  onLeasOptionClick = (maxVehicles, mode) => {
    this.setState({
      maxVehicles: maxVehicles,
      mode: mode,
      selectedVehicals: 0,
    });
  };

  handleSliderChange = (event, newValue) => {
    this.setState({
      selectedVehicals: newValue,
    });
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    this.setState({
      selectedOption: e.target.value,
    });
  };

  onChangeYear = (e) => {
    this.setState({
      selectedYear: e.target.value,
    });
  };

  toogleContactPopup = (show) => {
    this.setState({
      showContact: show,
    });
  };

  onSubmitLeasingEnquiryClick = () => {
    this.setState({
      showContact: true,
    });
    let leasingEnquiry = {
      selectedQuoteRequestType: this.state.mode,
      selectedVehicalType: this.state.selectedOption,
      selectedVehicalQunatity: this.state.selectedVehicals,
      selectedYear: this.state.selectedYear,
    };
    this.setState({
      leasingEnquiry: leasingEnquiry,
    });
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <section className="product-section pt-5 bg-grey ptb-80" id="productsection">
          <div className="container">
            {/* <h2 className="border-botom">Book Your Wheels</h2>
                    <p className="mb-50">{t('Easy Car Rental. Assured Offers. An Award-winning Experience..1')}</p> */}
            <ul className="nav nav-tabs product-nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link active "
                  id="leasing-tab"
                  data-toggle="tab"
                  href="#leasing1"
                  role="tab"
                  aria-controls="leasing"
                  aria-selected="true"
                  onClick={() => this.onLeasOptionClick(5, 1)}
                >
                  {t('Personal.1')}
                </a>
              </li>
              <li className="nav-item" role="presentation">
                <a
                  className="nav-link"
                  id="business-leasing-tab"
                  data-toggle="tab"
                  href="#business-leasing1"
                  role="tab"
                  aria-controls="business-leasing"
                  aria-selected="false"
                  onClick={() => this.onLeasOptionClick(500, 0)}
                >
                  {t('Corporate.1')}
                </a>
              </li>
            </ul>
            <div className="tab-content product-content" id="myTabContent">
              <div
                className="tab-pane show active"
                id="leasing"
                role="tabpanel"
                aria-labelledby="leasing-tab"
              >
                <div className="lease-wrapper row justify-content-between">
                  {this.state.mode == 1 ? (
                    <div className="lease-left bottomAlignwrap">
                      <h3>{t('Long Term Car Rental.1')}</h3>
                      <p>
                        {t(
                          'Be wise, don’t compromise. Dollar Car Rental UAE like you own it! With monthly/yearly prices cheaper than conventional car purchasing budgets, Dollar promises great prices for your choice of dream car, without the hassles of frequent paperwork, maintenance & long-term financial commitments.1',
                        )}
                      </p>
                      <p>
                        {t(
                          'Instead, reap the benefits of up-to-date vehicle performance, fuel economy, 24x7 dedicated premium support, roadside & emergency assistance for the best price in the UAE.1',
                        )}
                      </p>
                      <p>
                        {t(
                          'Perfect for new residents, young professionals, and working families.1',
                        )}
                      </p>

                      <a
                        onClick={this.onKnowMoreClick}
                        className="submit-lease-enquiry"
                        title="Read More"
                      >
                        {t('Know More.1')}
                      </a>
                    </div>
                  ) : (
                    <div className="lease-left bottomAlignwrap">
                      <h3>{t('Business Leasing.1')}</h3>
                      <p>
                        {t(
                          'Dollar Car Rental is a globally established brand when it comes to helping companies move their business on road. We take pride in being associated with the leading brands in the UAE as their total transportations solutions partner.1',
                        )}
                      </p>
                      <p>
                        {t(
                          'Our industrially acclaimed sales consultants guide you with expert inputs regarding your logistics, fleet management costs, compliances, optimal customized vehicular builds, distribution, supply chain management, and, other business-specific goals.1',
                        )}
                      </p>
                      <p>
                        {t(
                          'Our range of fleet is one of the largest and widest in the UAE, making us a wise reliable choice for businesses that involve transportation of expensive and perishable goods - with long-term leasing options up to 5+ years.1',
                        )}
                      </p>
                      <p>{t('Start your journey with us today.1')}</p>
                      <a
                        onClick={this.onKnowMoreClick}
                        className="submit-lease-enquiry"
                        title="Read More"
                      >
                        {t('Know More.1')}
                      </a>
                    </div>
                  )}

                  <div className="lease-right mt-lg-0 mt-sm-5 mt-2">
                    <div className="tab-content lease-tabs-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="personal"
                        role="tabpanel"
                        aria-labelledby="personal-tab"
                      >
                        <div className="nos-vehicles mb-sm-4 mb-2">
                          <div id="buttoncss">
                            {
                              <p className="mb-2 font-14">
                                <b>{t('Vehicles Type.1')}</b>
                              </p>
                            }

                            <ul className="nos-vehicle-list" onChange={this.onChange}>
                              {this.state.vehicleTypes.map((vehicleType) => (
                                <li key={vehicleType.VehicleClassificationID}>
                                  <input
                                    type="radio"
                                    id={vehicleType.Name}
                                    name="noofveicles"
                                    value={vehicleType.Name}
                                    checked={this.state.selectedOption === vehicleType.Name}
                                  />
                                  <label htmlFor={vehicleType.Name}>
                                    <img
                                      src={vehicleType.Icon.ImageString}
                                      alt={vehicleType.Name}
                                    />
                                    <p>{vehicleType.Name}</p>
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <label className="book-text text-black  d-flex justify-content-between">
                          <p className="mb-2 mt-2  font-14">
                            <b>{t('Number of Vehicles.1')}</b>
                          </p>
                          <h6 className="font-weight-bold mt-2">{this.state.selectedVehicals}</h6>
                        </label>
                        <div className="nos-vehicle-slider mb-sm-4 mb-2">
                          <Slider
                            key="slider1"
                            color="secondary"
                            max={this.state.maxVehicles}
                            step={1}
                            onChange={this.handleSliderChange}
                            value={this.state.selectedVehicals}
                          />
                          <div className="vehicle-range-value">
                            <span>0</span>
                            <span>{this.state.maxVehicles}</span>
                          </div>
                        </div>
                        <div className="lease-tenure-wrap mb-4">
                          <p className="mb-2 mt-2 font-14">
                            <b>Lease tenure</b>
                          </p>
                          <ul
                            className="nos-vehicle-list lease-tenure-radio"
                            onChange={this.onChangeYear}
                          >
                            <li>
                              <input
                                type="radio"
                                id="oneyear"
                                name="noofveicles1"
                                value={12}
                                checked={this.state.selectedYear === '12'}
                              />
                              <label htmlFor="oneyear">
                                <p>1 {t('Year.1')}</p>
                              </label>
                            </li>
                            <li>
                              <input
                                type="radio"
                                id="twoyear"
                                name="noofveicles1"
                                value={24}
                                checked={this.state.selectedYear === '24'}
                              />
                              <label htmlFor="twoyear">
                                <p>2 {t('Year.1')}</p>
                              </label>
                            </li>
                            <li>
                              <input
                                type="radio"
                                id="threeyear"
                                name="noofveicles1"
                                value={36}
                                checked={this.state.selectedYear === '36'}
                              />
                              <label htmlFor="threeyear">
                                <p>3 {t('Year.1')}</p>
                              </label>
                            </li>
                            {this.state.maxVehicles == 5 ? null : (
                              <>
                                <li>
                                  <input
                                    type="radio"
                                    id="fouryear"
                                    name="noofveicles1"
                                    value={48}
                                    checked={this.state.selectedYear === '48'}
                                  />
                                  <label htmlFor="fouryear">
                                    <p>4 {t('Year.1')}</p>
                                  </label>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    id="fiveyear"
                                    name="noofveicles1"
                                    value={60}
                                    checked={this.state.selectedYear === '60'}
                                  />
                                  <label htmlFor="fiveyear">
                                    <p>5 {t('Year.1')}</p>
                                  </label>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                        <a
                          title="Submit Lease Enquiry"
                          className="submit-lease-enquiry"
                          onClick={this.onSubmitLeasingEnquiryClick}
                        >
                          {t('Submit Lease Enquiry.1')}
                        </a>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="businesss"
                        role="tabpanel"
                        aria-labelledby="businesss-tab"
                      >
                        ...
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane"
                id="business-leasing"
                role="tabpanel"
                aria-labelledby="business-leasing-tab"
              >
                <div className="lease-wrapper row justify-content-between">
                  <div className="lease-right"></div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={this.state.showContact}
            onHide={() => this.toogleContactPopup(false)}
            dialogClassName="modal-dialog modal-xl modal-dialog-centered"
          >
            <ContactPopup leasingEnquiry={this.state.leasingEnquiry} />
          </Modal>
        </section>
      </>
    );
  }
}

export default withTranslation()(withRouter(ProductSections));

import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import ContactPopup from '../Common/ContactPopup';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import Truck from '../assets/images/Business/1010860.jpg';
import Group73 from '../assets/images/Business/1147848548.jfif';
import fleetManagementSolutionPart1 from '../assets/images/Business/394268698.jfif';
import ZeroImage from '../assets/images/Business/Quick_links_Business_Mobility1.jpg';
import accountmngr from '../assets/images/dedicated-account-manager.png';
import salesconsultant from '../assets/images/expert-sales-consultant.png';
import fuelmanagement from '../assets/images/fuel-management.png';
import operatexp from '../assets/images/operate-exp.png';
import purchasemng from '../assets/images/purchase-management.png';
import realtimetrack from '../assets/images/real-time-tracking.png';
import reducecost from '../assets/images/reduce-cost.png';
import savemoneytime from '../assets/images/save-money-time.png';
import { productInstance } from '../axios/axiosInstance';
import ContactUsBox from './ContactUsBox';
import CorporateLeaseBox from './CorporateLeaseBox';
class FleetManagementSolutions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderDafaultValue: 0,
      selectedVehicals: 0,
      selectedOption: 'convertible',
      selectedYear: '12',
      vehicleTypes: [],
      maxVehicles: 500,
      showContact: false,
      leasingEnquiry: {},
    };
  }

  componentDidMount = () => {
    this.getVehicleTypes();
    scroll.scrollToTop();
  };

  getVehicleTypes = () => {
    const code = process.env.REACT_APP_COUNTRY_CODE;
    productInstance.get(`vehicleClassification/allUI?countryCode=${code}`).then((response) => {
      const vehicleTypes = response.data.result;
      this.setState({ vehicleTypes: vehicleTypes });
      if (vehicleTypes.length > 0) {
        this.setState({
          selectedOption: vehicleTypes[0].Name,
        });
      }
    });
  };

  handleSliderChange = (event, newValue) => {
    this.setState({
      selectedVehicals: newValue,
    });
  };

  toogleContactPopup = (show) => {
    this.setState({
      showContact: show,
    });
  };
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    this.setState({
      selectedOption: e.target.value,
    });
  };

  onLeasOptionClick = (maxVehicles) => {
    this.setState({ maxVehicles: maxVehicles });
  };

  onChangeYear = (e) => {
    this.setState({
      selectedYear: e.target.value,
    });
  };

  onClick = () => {
    this.setState({
      showContact: true,
    });
    let leasingEnquiry = {
      selectedVehicalType: this.state.selectedOption,
      selectedVehicalQunatity: this.state.selectedVehicals,
      selectedYear: this.state.selectedYear,
    };
    this.setState({
      leasingEnquiry: leasingEnquiry,
    });
  };

  onMenuLinkClick = (path) => {
    this.props.history.push(path);
    scroll.scrollToTop();
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Truck Rental in Dubai | Hire Vehicles for All Your Business Needs</title>
          <link rel="canonical" href="www.dollaruae.com/fleetmanagementsolutions" />
          <meta
            property="og:title"
            content="Truck Rental in Dubai | Hire Vehicles for All Your Business Needs"
          ></meta>
          <meta property="og:site_name" ontent="Dollar Car Rental"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/"></meta>
          <meta
            property="og:description"
            content="Need a truck or commercial vehicle? Visit Dollar Car Rental - the leading Fleet Management Solutions provider in Dubai and across UAE. "
          ></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta
            property="og:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee08d93356c2d1.jpeg"
          ></meta>
          <meta
            name="title"
            content="Truck Rental in Dubai | Hire Vehicles for All Your Business Needs"
          ></meta>
          <meta
            name="description"
            content="Need a truck or commercial vehicle? Visit Dollar Car Rental - the leading Fleet Management Solutions provider in Dubai and across UAE. "
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta
            property="og:url"
            content="https://www.dollaruae.com/fleet-management-solutions"
          ></meta>
          <meta
            property="og:title"
            content="Truck Rental in Dubai | Hire Vehicles for All Your Business Needs"
          ></meta>
          <meta
            property="og:description"
            content="Need a truck or commercial vehicle? Visit Dollar Car Rental - the leading Fleet Management Solutions provider in Dubai and across UAE. "
          ></meta>
          <meta
            property="og:image"
            content="https://www.dollaruae.com/static/ media/394268698.ab3b3d74.jfif"
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta
            property="twitter:url"
            content="https://www.dollaruae.com/fleet-management-solutions"
          ></meta>
          <meta
            property="twitter:title"
            content="Truck Rental in Dubai | Hire Vehicles for All Your Business Needs"
          ></meta>
          <meta
            property="twitter:description"
            content="Need a truck or commercial vehicle? Visit Dollar Car Rental - the leading Fleet Management Solutions provider in Dubai and across UAE. "
          ></meta>
          <meta
            property="twitter:image"
            content="https://www.dollaruae.com/static/ media/394268698.ab3b3d74.jfif"
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="know-us-page pt-5 pb-3 bg-grey">
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="border-titlec-content mb-md-5 mb-4">
                  <h1 className="mb-1">{t('Fleet Management Solutions.1')}</h1>
                  <h2 className="mb-1">{t('Truck Rental For All Your Needs.1')}</h2>
                  <p className="font-18 text-grey">
                    {t('Hands-on Expertise to save your Business Thousands.1')}
                  </p>
                </div>
                <div>
                  <p className="font-18 text-grey">
                    {t(
                      'Dollar Car Rental is a leading Fleet Management Solutions provider in the UAE. We take pride in serving major brands in the country, who choose to trust us every single day with their mobility needs. This includes using our up-to-date expert guidance on the management of their fleet - acquisition, resale, maintenance, vehicle tracking, fuel, accident management & more.1',
                    )}
                  </p>
                  <p className="font-18 text-grey mt-4">
                    {t(
                      'We understand how important it is to build a highly efficient fleet and the role it plays while growing one’s business. That is why, our sales consultants work on customized solutions for your business, that are tailor-made to your unique requirements. Our proven approach includes evaluating fleet performance and identifying opportunities for continuous enhancements & efficiency.1',
                    )}
                  </p>
                </div>
              </div>
              <div className="col-lg-5 mt-4 mt-lg-0">
                <div className="">
                  <img
                    src={fleetManagementSolutionPart1}
                    className="rounded-xl"
                    alt="Dollar Fleet Management Solutions"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="box-shadow-white-box-section grey-bg py-2">
          <div className="container-fluid">
            <div className="box-shadow-white-box-a mt-0">
              <div className="">
                <div className="text-center pad-30">
                  <div className="row" id="id2">
                    <div className="col-12">
                      <div className="pickup-location-box" id="id2">
                        <h5 className="font-bold">{t('Why Choose Dollar.1')}</h5>
                      </div>
                    </div>

                    <div className="col-sm-12 col-lg-6 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={accountmngr} alt="Dollar Account Manager" />
                      <h6>
                        <b>{t('Dedicated Account Manager.1')}</b>
                      </h6>
                      <span>
                        {t(
                          'Our team moves with your team, 24x7. Hassle-free interactions, no repeated paperwork, priority agile approach.1',
                        )}
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-6 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={salesconsultant} alt="Dollar Expert Sales Consultants" />
                      <h6>
                        <b>{t('Expert Sales Consultants.1')}</b>
                      </h6>
                      <span>
                        {t(
                          'that support you with scalable vehicle acquisition and management solutions ensuring significant cost savings, reduced administrative hassles, increased productivity, and risk mitigation within your organization.1',
                        )}
                      </span>
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={savemoneytime} alt="Dollar Save Money and Time" />
                      <h6>
                        <b>{t('Save Money & Time.1')}</b>
                      </h6>
                      {/* <span>Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</span> */}
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={reducecost} alt="Dollar Reduced Fleet Costs" />
                      <h6>
                        <b>{t('Reduced Fleet Costs.1')}</b>
                      </h6>
                      {/* <span>Our team moves with your team, 24x7. Hassle-free interactions, no repeated paperwork, priority agile approach.</span> */}
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={operatexp} alt="Reduced Operation Expenses" />
                      <h6>
                        <b>{t('Reduced Operating Expenses.1')}</b>
                      </h6>
                      {/* <span>that support you with scalable vehicle acquisition and management solutions ensuring significant cost savings, reduced administrative hassles, increased productivity, and risk mitigation within your organization..</span> */}
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={fuelmanagement} alt="Fuel Management" />
                      <h6>
                        <b>{t('Fuel Management.1')}</b>
                      </h6>
                      {/* <span>Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</span> */}
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={purchasemng} alt="Purchase Managment" />
                      <h6>
                        <b>{t('Purchase Management.1')}</b>
                      </h6>
                      {/* <span>Our team moves with your team, 24x7. Hassle-free interactions, no repeated paperwork, priority agile approach.</span> */}
                    </div>
                    <div className="col-sm-12 col-lg-4 col-md-12 col-12 mt-4 mt-sm-0">
                      <img src={realtimetrack} alt="Real Time Tracking and Analytics" />
                      <h6>
                        <b>{t('Real-time Tracking & Analytics.1')}</b>
                      </h6>
                      {/* <span>that support you with scalable vehicle acquisition and management solutions ensuring significant cost savings, reduced administrative hassles, increased productivity, and risk mitigation within your organization..</span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={this.state.showContact}
            onHide={() => this.toogleContactPopup(false)}
            dialogClassName="modal-dialog modal-xl modal-dialog-centered"
          >
            <ContactPopup leasingEnquiry={this.state.leasingEnquiry} />
          </Modal>
        </section>

        <CorporateLeaseBox></CorporateLeaseBox>

        <section className="box-shadow-white-box-section grey-bg py-2">
          <div className="container-fluid">
            <div className="box-shadow-white-box-a mt-0" id="partner">
              <div className="pad-30">
                <div className="row">
                  <div className="col-12">
                    <div className="pickup-location-box">
                      <h5 className="font-bold mb-3">
                        <center>{t('Truck Rental For All Your Needs.1')}</center>
                      </h5>

                      <h6>
                        <center>{t('Your keys to efficiency, savings & growth.1')}</center>
                      </h6>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4" id="truckrental" name="truckrental">
                    <div className="fotter-link-box">
                      <img src={Truck} className="rounded-xl" alt="Specialized Vehicles" />
                      <div className="pickup-location-flex-content">
                        <h3 className="mb-2 mt-2 w-100">{t('Specialized Vehicles.1')}</h3>
                        <span>
                          {t(
                            'Dollar Car Rental UAE takes pride in being associated with leading brands in the UAE like Pepsi, Mai Dubai, Pran Foods, NFPC, Aramex, Carrefour, etc. as their total transportation solutions partner, by helping them achieve reduced fleet management costs, improved fleet compliance, customized vehicle-builds, and other business-specific goals.1',
                          )}
                        </span>
                      </div>
                      {/* <a onClick={() => this.onMenuLinkClick('/SpecializedVehicles')} className="rounded-bttn rounded-bttn-transparent mt-auto" title="View More">
                                       View More
                                 </a> */}
                      <div className="text-right mt-auto">
                        <a
                          className="red-link-btn mt-auto"
                          onClick={() => this.onMenuLinkClick('/specializedvehicles')}
                        >
                          {t('View More.1')}
                          <i className="fa fa-angle-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4 mt-4 mt-lg-0">
                    <div className="fotter-link-box">
                      <img src={ZeroImage} className="rounded-xl" alt="Business Mobility" />
                      <div className="pickup-location-flex-content">
                        <h3 className="mb-2 mt-2 w-100">{t('Business Mobility.1')}</h3>
                        <span>
                          {t(
                            'Modern, reliable & adaptable choices for businesses that involve transportation of expensive and perishable goods. All our vehicle models are rigid, ergonomic, and open-for-customizations to suit different business requirements & long-term fleet management solutions. Book a complimentary appointment with any of our sales consultants today for personalized insights into your business.1',
                          )}
                        </span>
                      </div>
                      {/* <a onClick={() => this.onMenuLinkClick('/CorporateFleets')} className="rounded-bttn rounded-bttn-transparent mt-auto" title="View More">
                                       View More
                                 </a> */}
                      <div className="text-right mt-auto">
                        <a
                          className="red-link-btn mt-auto"
                          onClick={() => this.onMenuLinkClick('/explorecorporatefleet')}
                        >
                          {t('View More.1')}
                          <i className="fa fa-angle-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-lg-4 mt-4 mt-lg-0">
                    <div className="fotter-link-box">
                      <img src={Group73} className="rounded-xl" alt="Long Term Leasing" />
                      <div className="pickup-location-flex-content">
                        <h3 className="mb-2 mt-2 w-100">{t('Long Term Leasing.1')}</h3>
                        <span>
                          {t(
                            'Renting is the new wise. Of that, there’s no doubt. COVID-19 has changed the way we now look at mobility & convenience. Personal Leasing is a practical approach to having a vehicle to yourself for a longer duration (1-3 years) - without the hassle of maintaining it or having to commit to long term financial contracts.1',
                          )}
                        </span>
                      </div>
                      {/* <a onClick={() => this.onMenuLinkClick('/FleetSolutions')} className="rounded-bttn rounded-bttn-transparent mt-auto" title="View More">
                                       View More
                                 </a> */}
                      <div className="text-right mt-auto">
                        <a
                          className="red-link-btn mt-auto"
                          onClick={() => this.onMenuLinkClick('/long-term-leasing')}
                        >
                          {t('View More.1')}
                          <i className="fa fa-angle-right"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            show={this.state.showContact}
            onHide={() => this.toogleContactPopup(false)}
            dialogClassName="modal-dialog modal-xl modal-dialog-centered"
          >
            <ContactPopup leasingEnquiry={this.state.leasingEnquiry} />
          </Modal>
        </section>

        <ContactUsBox
          subHeading={t(
            'For a complimentary session on personalized insights into how you can manage your fleet more efficiently and other business solutions, contact us today.1',
          )}
          heading={t('We look forward to serving you.1')}
        ></ContactUsBox>

        <div>
          {/* <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
                <div className="container-fluid">
                    <div className="box-shadow-white-box-a ">
                        <div className="">
                            <div className="row flex-reversea">
                                <div className="col-sm-12 col-lg-12 col-md-12 col-12">
                                    <div className="pickup-location-box">
                                        <h5 className="font-bold mb-3 mt-2 ">Fleet Management Solutions</h5>
                                        <div className="pickup-location-flex">
                                            <div className="pickup-location-flex-content">
                                                <h3 className="mb-4">Need content for this page, please provide!
                                                <br/>
                                                </h3>
                                                <p className="text_p mt-4">
                                        Experience only the best at your ultimate car rental destination.
                                    </p>
                                    <p className="text_p mt-4">
                                        At Dollar Car Rental UAE, it is easy to find a rental car that suits your budget and your style. Dollar
                                        offers a large selection of high-quality, well-maintained and latest models of rental vehicles
                                        designed to make getting to your destination comfortable and fun. All vehicles in our fleet are
                                        non-smoking, and each year we add more and more fuel-efficient vehicles, which means even
                                        more savings for you. In the UAE, Dollar Car Rental offers a wide range of great rental cars that
                                        includes SUVs, sedans, hatchbacks, economy & luxury rentals. No matter what kind of vehicle fits
                                        your needs, Dollar has just what you’re looking for at a great rate, backed by excellent service
                                    </p>
                                    <br />
                                    <p className="text_p mt-4">
                                        Feel free to reach out to us for the season’s latest competitive rates, offers and deals from Dollar
                                        Car Rental UAE towards a vehicle of your choice and budget.
                                    </p>
                                    <br />
                                    <p className="text_p mt-4">
                                        Not sure which vehicle model to go for? Look for the detail indicators against each vehicle listing
                                        to help understand its seating capacity, luggage capacity, engine specs, auto/manual and more.
                                        Nevertheless, our representatives would be happy to assist you in finding your best pocketfriendly fit.
                                    </p>
                                    <br />
                                    <p className="text_p mt-4">
                                        To hands on wheels and open roads
                                    </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                 <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                                    <img src={image} alt="" className="img-fluid rounded-xl" />
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </div>

        {/* <LessFineSection />
            <BrandSlider /> */}
        <ListingFooter />
      </>
    );
  }
}
export default hoistStatics(
  withTranslation()(withRouter(FleetManagementSolutions)),
  FleetManagementSolutions,
);
// export default FleetSolutions;

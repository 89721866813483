import moengage from '@moengage/web-sdk';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import styles from './EnquiryForm.module.css';
import { countriesOptions, durationOptions, options } from './TeacherConstant';

const initialFormData = {
  name: '',
  email: '',
  phone: '',
  countryCode: '+971',
  region: '',
  regionValue: '',
  duration: '',
  message: '',
  carName: '',
  carImage: '',
  flag: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/ae.svg',
};

const EnquiryForm = ({ onClose, prefillData, setThanksPopUp }) => {
  const [formData, setFormData] = useState({
    ...initialFormData,
    carName: prefillData?.carName || '',
    carImage: prefillData?.carImage || '',
    carPrice: prefillData?.carPrice || '',
  });
  const [errors, setErrors] = useState({});
  const [openPhoneCode, setOpenPhoneCode] = useState(false);
  const [openRegion, setOpenRegion] = useState(false);
  const [openDuration, setOpenDuration] = useState(false);

  // Ref for the form container
  const formRef = useRef(null);

  useEffect(() => {
    // Handler to close form if clicked outside
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on cleanup
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const validateFormData = (data) => {
    const errors = {};

    const fields = {
      name: {
        value: data.name.trim(),
        pattern: /^[a-zA-Z\s]+$/,
        patternMessage: 'Name must contain only letters',
      },
      email: {
        value: data.email.trim(),
        requiredMessage: 'Email is required',
        pattern: /\S+@\S+\.\S+/,
        patternMessage: 'Email must be a valid email address',
      },
      phone: {
        value: data.phone.trim(),
        pattern: /^\d+$/,
        patternMessage: 'Phone must contain only digits',
      },
      region: {
        value: data.region.trim(),
      },
      duration: {
        value: data.duration.trim(),
      },
    };

    Object.keys(fields).forEach((key) => {
      const { value, pattern, patternMessage } = fields[key];
      if (!value) errors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required`;
      else if (pattern && !pattern.test(value)) errors[key] = patternMessage;
    });

    return errors;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateFormData(formData);
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        moengage.add_email(formData.email);

        const payload = {
          name: formData.name,
          email: formData.email,
          phone: `${formData.countryCode}${formData.phone}`,
          region: formData.regionValue,
          brand: 'dollar',
          description: `Car Name: ${formData.carName}, Price Shown:${formData.carPrice} and Additional Message: ${formData.message}`,
          duration: formData.durationValue,
          lead_type_id: 4,
        };

        const response = await axios.post(
          process.env.REACT_APP_LEASE_TO_OWN_URL,
          JSON.stringify(payload),
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          setThanksPopUp(true); // Trigger the thank you page
          onClose(); // Close the enquiry form
        }
      } catch (error) {}
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevState) => ({
      ...prevState,
      [name]: '',
    }));
  };

  return (
    <section className={styles.popup}>
      <form className={styles.formContainer} onSubmit={handleFormSubmit} ref={formRef}>
        <header className={styles.header}>
          <div className={styles.titleWrapper}>
            <h1 className={styles.title}>Enquire Now</h1>
            <div className={styles.redDot} />
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/e29c5d73bc06e7deeb1f283e3410d2b3e1c29da412f471a5a167d1427c585038?apiKey=ac85e0a3444a46c7baa2de216eef8e21"
            className={styles.closeIcon}
            alt="Close form"
            onClick={onClose}
          />
        </header>
        {formData?.carName?.length ? (
          <>
            <p className={styles.selectionText}>You have selected</p>
            <div className={styles.selectedCar}>
              <div className={styles.carDetails}>
                <div className={styles.imageColumn}>
                  <div className={styles.imageWrapper}>
                    <img
                      loading="lazy"
                      src={formData.carImage}
                      className={styles.carImage}
                      alt="Selected car"
                    />
                  </div>
                </div>
                <div className={styles.infoColumn}>
                  <div className={styles.carInfo}>
                    <h2 className={styles.carName}>{formData.carName}</h2>
                    <div className={styles.priceWrapper}>
                      <span className={styles.priceLabel}>AED </span>
                      <span className={styles.priceAmount}> {formData.carPrice} </span>
                      <span className={styles.priceLabel}> / Month</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          ''
        )}
        <div className={styles.formLabels}>
          <div className={styles.inputRow}>
            <div className={styles.inputWrapper}>
              <label className={styles.contactLabel} htmlFor="name">
                Name*
              </label>
              <input
                id="name"
                className={styles.input}
                type="text"
                placeholder="Your Name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                aria-label="Your Name"
              />
              <span className={styles.validationMessage}>{errors.name}</span>
            </div>

            <div className={styles.inputWrapper}>
              <label className={styles.contactLabel} htmlFor="email">
                Email*
              </label>
              <input
                id="email"
                className={styles.input}
                type="email"
                placeholder="Your Email ID"
                name="email"
                value={formData.email}
                onChange={handleChange}
                aria-label="Your Email ID"
              />
              <span className={styles.validationMessage}>{errors.email}</span>
            </div>
          </div>

          {/* Other form fields go here */}
        </div>

        <div className={styles.contactGroup}>
          <div className={styles.contactWrapper}>
            <label className={styles.contactLabel} htmlFor="phone">
              Contact No*
            </label>
            <div className={styles.phoneInput}>
              <img
                loading="lazy"
                src={formData.flag}
                className={styles.flagIcon}
                alt="Flag icon"
                onClick={() => {
                  setOpenPhoneCode(!openPhoneCode);
                  setOpenRegion(false);
                  setOpenDuration(false);
                }}
              />
              <div className={styles.countryCode}>
                <span
                  onClick={() => {
                    setOpenPhoneCode(!openPhoneCode);
                    setOpenRegion(false);
                    setOpenDuration(false);
                  }}
                >
                  {formData.countryCode}
                </span>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/1872c9546dd26cbbfab2303e6e17ce8e267c3cd6be812f196ae1426210bb45a2?apiKey=ac85e0a3444a46c7baa2de216eef8e21"
                  className={styles.codeArrow}
                  alt=""
                />
                {openPhoneCode && (
                  <ol className={styles.selectOpt}>
                    {options.map((code) => (
                      <li
                        key={code.value}
                        onClick={(e) => {
                          e.preventDefault();
                          setFormData({
                            ...formData,
                            countryCode: code.value,
                            flag: code.flag,
                          });
                          setOpenPhoneCode(false);
                        }}
                      >
                        <div className={styles.flagIcon}>
                          <img src={code.flag} alt="Country flag" />
                        </div>
                        <span>{code.label}</span>
                      </li>
                    ))}
                  </ol>
                )}
              </div>
              <div className={styles.phoneNumber}>
                <input
                  type="text"
                  value={formData.phone}
                  placeholder="000-000-000"
                  name="phone"
                  onChange={handleChange}
                />
              </div>
            </div>
            <span className={styles.validationMessage}>{errors.phone}</span>
          </div>
          <div className={styles.regionWrapper}>
            <label className={styles.regionLabel} htmlFor="region">
              Emirates / Region
            </label>
            <div
              className={styles.regionSelect}
              onClick={() => {
                setOpenRegion(!openRegion);
                setOpenPhoneCode(false);
                setOpenDuration(false);
              }}
            >
              <span>{formData.region || 'Choose Emirate / Region'}</span>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/d02e23e7fa00922a0e85e47357792211bcbfa509b3bfdfaf7b31f8b305965eab?apiKey=ac85e0a3444a46c7baa2de216eef8e21"
                className={styles.selectArrow}
                alt=""
              />
              {openRegion && (
                <ol className={styles.selectOpt}>
                  {countriesOptions.map((code) => (
                    <li
                      key={code.value}
                      onClick={(e) => {
                        e.preventDefault();
                        setFormData({
                          ...formData,
                          region: code.label,
                          regionValue: code.value,
                        });
                        setOpenRegion(false);
                      }}
                    >
                      <span style={{ marginLeft: 5, cursor: 'pointer' }}>{code.label}</span>
                    </li>
                  ))}
                </ol>
              )}
            </div>
            <span className={styles.validationMessage}>{errors.region}</span>
          </div>
        </div>
        <div className={styles.durationWrapper}>
          <label className={styles.durationLabel} htmlFor="duration">
            Duration
          </label>
          <div
            className={styles.durationSelect}
            onClick={() => {
              setOpenDuration(!openDuration);
              setOpenPhoneCode(false);
              setOpenRegion(false);
            }}
          >
            <span>{formData.duration || 'Choose rental duration'}</span>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/d02e23e7fa00922a0e85e47357792211bcbfa509b3bfdfaf7b31f8b305965eab?apiKey=ac85e0a3444a46c7baa2de216eef8e21"
              className={styles.selectArrow}
              alt=""
            />
            {openDuration && (
              <ol className={styles.selectOpt}>
                {durationOptions.map((code) => (
                  <li
                    key={code.label}
                    onClick={(e) => {
                      e.preventDefault();
                      setFormData({
                        ...formData,
                        duration: code.label,
                        durationValue: code.value,
                      });
                      setOpenDuration(false);
                    }}
                  >
                    <span style={{ marginLeft: 5, cursor: 'pointer' }}>{code.label}</span>
                  </li>
                ))}
              </ol>
            )}
          </div>
          <span className={styles.validationMessage}>{errors.duration}</span>
        </div>
        <label className={styles.remarksLabel} htmlFor="remarks">
          Remarks
        </label>
        <textarea
          id="remarks"
          className={styles.remarksInput}
          placeholder="Your Remarks"
          name="message"
          value={formData.message}
          onChange={handleChange}
          aria-label="Your Remarks"
        ></textarea>
        <button type="submit" className={styles.submitButton}>
          Submit
        </button>
      </form>
    </section>
  );
};

export default EnquiryForm;

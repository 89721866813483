import moengage from '@moengage/web-sdk';
import hoistStatics from 'hoist-non-react-statics';
import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import validator from 'validator';
import ErrorAlert from '../Common/AlertError/ErrorAlert';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import { contentInstance } from '../axios/axiosInstance';

class FeedBack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackFeatures: [],
      selectedFeedbackFeatureId: 0,
      emailID: '',
      contactNo: '',
      transactionNumber: '',
      reservationConfirmId: '-1',
      rating: 5,
      remark: '',
      loading: false,
      errorMessage: '',
    };
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount = () => {
    const {
      match: { params },
    } = this.props;
    if (params.index != -1 && params.index != undefined) {
      this.setState({ reservationConfirmId: params.index });
      this.setState({ transactionNumber: params.index });
    }

    this.getFeedbackFeatures();
    scroll.scrollToTop();
  };

  getFeedbackFeatures = () => {
    contentInstance
      .get('feedback/getFeedbackFeature')
      .then((response) => {
        this.setState({
          feedbackFeatures: response.data.result,
        });
      })
      .catch((error) => {});
  };

  onRatingChanged = (rating) => {
    this.setState({ rating: rating });
  };

  validateFeedback = () => {
    this.setState({ errorMessage: '' });
    if (this.state.selectedFeedbackFeatureId === 0) {
      this.setState({ errorMessage: 'Please select feedback type' });
      return false;
    }
    if (this.state.remark === '') {
      this.setState({ errorMessage: 'Please enter remark' });
      return false;
    }

    if (this.state.contactNo === '' && this.state.emailID === '') {
      this.setState({ errorMessage: 'Please enter email either contact no.' });
      return false;
    }

    if (this.state.emailID != '') {
      if (!validator.isEmail(this.state.emailID)) {
        {
          this.setState({
            errorMessage: 'The email field is invalid',
          });
          return false;
        }
      }
    }

    return true;
  };

  submitNormalFeedback = () => {
    moengage.add_email(this.state.emailID);

    var data = {
      transactionID: '00000000-0000-0000-0000-000000000000',
      transactionType: this.state.selectedFeedbackFeatureId,
      transactionNo: this.state.transactionNumber,
      contactNo: this.state.contactNo,
      emailID: this.state.emailID,
      rating: this.state.rating,
      remark: this.state.remark,
      featureType: [this.state.selectedFeedbackFeatureId],
    };

    this.setState({ loading: true });
    contentInstance
      .post('feedback', data)
      .then((response) => {
        this.setState({ loading: false });
        const responseData = response.data.result;
        if (responseData != null && responseData.Message != null) {
          localStorage.setItem('thanksmessage', responseData.Message);
        }
        this.props.history.push(`/thankyou`);
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.setState({ errorMessage: 'Error while submitting the feedback' });
      });
  };

  submitReservationFeedback = () => {
    var data = {
      reservationID: this.state.transactionNumber,
      feedbackRating: this.state.rating,
      remark: this.state.remark,
      customerFeedbackFeatureType: this.state.selectedFeedbackFeatureId,
    };

    this.setState({ loading: true });
    contentInstance
      .post('feedback', data)
      .then((response) => {
        this.setState({ loading: false });
        this.props.history.push(`/thankyou`);
      })
      .catch((error) => {
        this.setState({ loading: false });
        this.setState({ errorMessage: 'Error while submitting the feedback' });
      });
  };

  onSubmitFeedBack = () => {
    if (this.validateFeedback()) {
      if (this.state.reservationConfirmId == -1) {
        this.submitNormalFeedback();
      } else {
        this.submitReservationFeedback();
      }
    }
  };
  onChangeFe = (e) => {
    this.setState({ selectedFeedbackFeatureId: e.target.value });
  };

  onMenuLinkClick = (path) => {
    this.props.history.push(path);
    scroll.scrollToTop();
  };

  render() {
    const { t } = this.props;

    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t('Feedback, Customers Travel Experiences | Dollar Car Rental.1')}</title>
          <link rel="canonical" href="www.dollaruae.com/feedback" />
          <meta
            property="og:title"
            content="Feedback, Customers Travel Experiences | Dollar Car Rental"
          ></meta>
          <meta property="og:site_name" content="Dollar Car Rental"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/feedback"></meta>
          <meta
            property="og:description"
            content="Experiences of customer feedback - Enjoy the travel experiences of long road trips, tourists and others. Give your rating on Dollar Car Rental."
          ></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta
            property="og:image"
            content="https://www.dollaruae.com/static/media/Feedback.1581c2b8.jfif"
          ></meta>
          <meta
            name="title"
            content="Feedback, Customers Travel Experiences | Dollar Car Rental"
          ></meta>
          <meta
            name="description"
            content="Experiences of customer feedback -Enjoy the travel experiences of long road trips, tourists and others. Give your rating on Dollar Car Rental."
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/feedback"></meta>
          <meta
            property="og:title"
            content="Feedback, Customers Travel Experiences | Dollar Car Rental"
          ></meta>
          <meta
            property="og:image"
            content="https://www.dollaruae.com/static/media/Feedback.1581c2b8.jfif"
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta property="twitter:url" content="https://www.dollaruae.com/feedback"></meta>
          <meta
            property="twitter:title"
            content="Feedback, Customers Travel Experiences | Dollar Car Rental"
          ></meta>
          <meta
            property="twitter:image"
            content="https://www.dollaruae.com/static/media/Feedback.1581c2b8.jfif"
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100 feedback-content-page">
          <section className="box-shadow-white-box-a grey-bg my-0">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-8 col-12 text-left">
                  <div className="text-center">
                    <h1 className="mt-3">{t('We value your Feedback.1')}</h1>
                    <h5 className="font-weight-bold pt-3">
                      {t('Share with us your Dollar RED Experience.1')}
                    </h5>
                    {/* <img src={feedbackbanner}  className="mt-4 rounded-xl" alt="Dollar Feedback" /> */}
                  </div>
                  <div className="pt-3"></div>
                  <div className="box-shadow-white-radius px-md-4 py-md-4 px-2 py-2">
                    <div className="lease-tenure-wrap mb-4 booking-exp-wrap">
                      <div className="form-group">
                        {/* <img src={dollarLogo}></img>
                                        <hr /> */}
                        <label className="product-title mb-4">
                          {t('Please share feedback about our website below.1')}{' '}
                          <b>
                            {t(
                              'To receive customer service or share feedback about a rental experience, please visit our.1',
                            )}{' '}
                            <a
                              href="#"
                              className="text-red"
                              title="Contact us"
                              onClick={() => this.onMenuLinkClick('/contactus')}
                            >
                              {t('Contact Us.1')}
                            </a>{' '}
                            {t('page.1')}
                          </b>
                        </label>
                        {/* <label className="product-title mb-3">* Required</label> <br /> */}

                        <label for="exampleInputEmail1" className="book-text  mb-1">
                          <h6 className="product-title">
                            <b>{t('Please Rate Your Experience Today.1')}*</b>
                          </h6>
                        </label>
                        <br />
                        <div className="number-rating-wrap">
                          <label>
                            <small>{t('Very Poor.1')}</small>
                            <input
                              className="number"
                              type="checkbox"
                              title="Very Poor"
                              checked={this.state.rating == 1}
                              onClick={() => this.onRatingChanged(1)}
                            />
                            <span>{t('1.1')}</span>
                          </label>
                          <label>
                            <small>{t('Poor.1')}</small>
                            <input
                              className="number"
                              type="checkbox"
                              title="Poor"
                              checked={this.state.rating == 2}
                              onClick={() => this.onRatingChanged(2)}
                            />
                            <span>{t('2.1')}</span>
                          </label>
                          <label>
                            <small>{t('Ok.1')}</small>
                            <input
                              className="number"
                              type="checkbox"
                              title="Ok"
                              checked={this.state.rating == 3}
                              onClick={() => this.onRatingChanged(3)}
                            />
                            <span>{t('3.1')}</span>
                          </label>
                          <label>
                            <small>{t('Good.1')}</small>
                            <input
                              className="number"
                              type="checkbox"
                              title="Good"
                              checked={this.state.rating == 4}
                              onClick={() => this.onRatingChanged(4)}
                            />
                            <span>{t('4.1')}</span>
                          </label>
                          <label>
                            <small>{t('Very Good.1')}</small>
                            <input
                              className="number"
                              type="checkbox"
                              title="Very Good"
                              checked={this.state.rating == 5}
                              onClick={() => this.onRatingChanged(5)}
                            />
                            <span>{t('5.1')}</span>
                          </label>
                        </div>
                      </div>
                      <div className="custom-input-type-wrap">
                        <label for="" className="product-title">
                          <b>{t('Share Feedback.1')}</b>
                        </label>{' '}
                        <br />
                        {/* <ul className="nos-vehicle-list lease-tenure-radio"  onChange={this.onChangeFe} >
                                            {
                                                this.state.feedbackFeatures.map(feature => 
                                                    
                                                        <li key={feature.Id} >
                                                            <input type="radio" id={feature.Id} name="noofveicles" value={feature.Id} checked={this.state.selectedFeedbackFeatureId.toString() === feature.Id.toString()} />
                                                            <label for={feature.Text}>
                                                                <p>{feature.Text}</p>
                                                            </label>
                                                        </li> 
                                                 )
                                            }
                                        </ul> */}
                        {/* <ul className="nos-vehicle-list" onChange={this.onChangeFe}>
                                                    {
                                                        this.state.feedbackFeatures.map((vehicleType) =>
                                                            <li key={vehicleType.Id}>
                                                                <input type="radio" id={vehicleType.Text} name="noofveicles1" value={vehicleType.Id} checked={this.state.selectedFeedbackFeatureId === vehicleType.Id}   />
                                                                <label htmlFor={vehicleType.Text} >
                                                                     
                                                                    <p>{vehicleType.Text}</p>
                                                                </label>
                                                            </li>
                                                        )
                                                    }

                                                </ul> */}
                        <select className="filter-box" defaultValue={0} onChange={this.onChangeFe}>
                          <option value={0}>{t('SELECT.1')}</option>
                          {this.state.feedbackFeatures.map((vehicleType) => (
                            <option value={vehicleType.Id}>{vehicleType.Text}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <form>
                      <div className="form-group">
                        {/* <label for="transactionNumber" className="book-text mb-1">
                                                <h6 className="product-title"><b>Reservation No or Contact No (Optional).</b></h6></label>
                                            <input type="email" className="form-control px-3" id="transactionNumber" value={this.state.transactionNumber} name="transactionNumber" aria-describedby="emailHelp" placeholder="" onChange={this.onChange} /> */}

                        <div className="row ">
                          <div className="col-6">
                            <label for="email" className="book-text mb-1">
                              <h6 className="product-title">
                                <b>{t('Email.1')}</b>
                              </h6>
                            </label>
                            <input
                              type="email"
                              className="form-control px-3"
                              id="emailID"
                              value={this.state.emailID}
                              name="emailID"
                              aria-describedby="emailHelp"
                              placeholder=""
                              onChange={this.onChange}
                            />
                          </div>
                          <div className="col-6">
                            <label for="contactNo" className="book-text mb-1">
                              <h6 className="product-title">
                                <b>{t('Contact No.1')}</b>
                              </h6>
                            </label>
                            <input
                              type="email"
                              className="form-control px-3"
                              id="contactNo"
                              value={this.state.contactNo}
                              name="contactNo"
                              aria-describedby="emailHelp"
                              placeholder=""
                              onChange={this.onChange}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group">
                        <label for="remark" className="book-text mb-1">
                          <h6 className="product-title">
                            <b>{t('Please Explain Your Feedback.1')}</b>
                          </h6>
                        </label>
                        <br />
                        <label className="product-title mb-3">
                          {t(
                            'Please provide as much information as possible to help us understand your experience.1',
                          )}
                        </label>{' '}
                        <br />
                        <label className="product-title mb-3">
                          {t(
                            'Please do not include personal information such as name, email, or phone numbers.1',
                          )}
                        </label>
                        <textarea
                          type="email"
                          className="form-control px-3"
                          id="remark"
                          name="remark"
                          value={this.state.remark}
                          aria-describedby="emailHelp"
                          placeholder={t('Feedback Message.1')}
                          onChange={this.onChange}
                          rows={5}
                        />
                      </div>
                      {this.state.errorMessage != null && this.state.errorMessage.length > 0 ? (
                        <ErrorAlert errorMessage={this.state.errorMessage} />
                      ) : null}
                      {this.state.loading == true ? (
                        <div style={{ verticalAlign: 'center' }}>
                          <Spinner animation="border" />
                          &nbsp;{t('Submiting FeedBack.1')}
                        </div>
                      ) : null}
                      <a
                        className="primary-bttn primary-invert-bttn text-center mt-3 mb-3"
                        title="book"
                        onClick={this.onSubmitFeedBack}
                      >
                        {t('Submit.1')}
                      </a>
                    </form>
                  </div>
                  <div className="pt-3"></div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default hoistStatics(withTranslation()(withRouter(FeedBack)), FeedBack);

import moengage from '@moengage/web-sdk';
import axios from 'axios';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { countriesOptions, options } from './constant';

const initialFormData = {
  name: '',
  email: '',
  phone: '',
  countryCode: '+971',
  region: '',
  regionValue: '',
  message: '',
  flag: 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/ae.svg',
  isSubmitting: false,
};

class LeaseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        ...initialFormData,
      },
      errors: {},
      openPhoneCode: false,
      openRegion: false,
    };
  }

  handleFormSubmit = async (e) => {
    e.preventDefault();

    if (this.state.isSubmitting) return;
    this.setState({ isSubmitting: true });
    const errors = this.validateFormData(this.state.formData);
    this.setState({ errors });
    const { name, email, phone, regionValue, message, countryCode } = this.state.formData;

    if (Object.keys(errors).length === 0) {
      try {
        moengage.add_email(email);
        const value = {
          name: `${name}`,
          email: `${email}`,
          phone: `${countryCode + phone}`,
          region: `${regionValue}`,
          brand: 'dollar',
          description: `${message}`,
        };

        const response = await axios.post(
          process.env.REACT_APP_LEASE_TO_OWN_URL,
          JSON.stringify(value),
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_BEARER_TOKEN}`,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data.status === 200) {
          this.props.handleThankYouPopup(true);
          this.setState({ formData: { ...initialFormData } });
        }
      } catch (error) {}
    }
    this.setState({ isSubmitting: false });
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    this.setState((prevstate) => ({
      formData: {
        ...prevstate.formData,
        [name]: value,
      },
      errors: {
        ...prevstate.errors,
        [name]: '',
      },
    }));
  };

  validateFormData = (data) => {
    let errors = {};
    const trimmedName = data.name.trim();
    if (!trimmedName) {
      errors.name = 'Name is required';
    } else if (!/^[a-zA-Z\s]+$/.test(trimmedName)) {
      errors.name = 'Name must contain only letters';
    }
    if (data.email.trim() && !/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Email is invalid';
    }
    if (!data.phone.trim()) {
      errors.phone = 'Phone is required';
    }

    if (!data.region.trim()) {
      errors.region = 'Region is required';
    }

    return errors;
  };

  render() {
    const { name, email, phone, region, message, countryCode, flag } = this.state.formData;

    return (
      <form onSubmit={this.handleFormSubmit}>
        <h6>
          Enquire Now <span>.</span>
        </h6>
        <ul>
          <li>
            <label htmlFor="">Name*</label>
            <input
              type="text"
              name="name"
              value={name}
              placeholder="Your Name"
              onChange={this.handleChange}
            />
            <span className="validation-message">{this.state.errors.name}</span>
          </li>
          <li>
            <label htmlFor="">Email*</label>
            <input
              type="email"
              placeholder="Email*"
              name="email"
              value={email}
              onChange={this.handleChange}
            />
            <span className="validation-message">{this.state.errors.email}</span>
          </li>

          {/* phone number */}
          <li>
            <label htmlFor="">Phone Number*</label>
            <div className="input-container">
              <div className="flag-container">
                <div className="oz-select">
                  <div
                    className="flag"
                    onClick={() => {
                      this.setState({
                        openPhoneCode: !this.state.openPhoneCode,
                        openRegion: false,
                      });
                    }}
                  >
                    <img src={flag} />
                  </div>
                  <span
                    onClick={() => {
                      this.setState({
                        openPhoneCode: !this.state.openPhoneCode,
                        openRegion: false,
                      });
                    }}
                  >
                    {countryCode}
                  </span>
                  <div className="arrow">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="6"
                      height="3"
                      viewBox="0 0 6 3"
                      fill="none"
                    >
                      <path d="M3 3L0 0H6L3 3Z" fill="black" />
                    </svg>
                  </div>

                  {this.state.openPhoneCode && (
                    <ol className="select-opt">
                      {options.map((code) => (
                        <li
                          key={code.value}
                          onClick={(e) => {
                            e.preventDefault();
                            this.setState({
                              formData: {
                                ...this.state.formData,
                                countryCode: code.value,
                                flag: code.flag,
                              },
                              openPhoneCode: false,
                            });
                          }}
                        >
                          <div className="flag">
                            <img src={code.flag} />
                          </div>
                          <span>{code.label}</span>
                        </li>
                      ))}
                    </ol>
                  )}
                </div>
              </div>
              <input
                type="number"
                value={phone}
                placeholder="000-000-000"
                name="phone"
                onChange={this.handleChange}
              />
            </div>
            <span className="validation-message">{this.state.errors.phone}</span>
          </li>
          <li className="region-wrapper">
            <label htmlFor="">Emirates / Region</label>
            <div
              className="input"
              onClick={() =>
                this.setState({ openRegion: !this.state.openRegion, openPhoneCode: false })
              }
            >
              <input
                type="text"
                readOnly
                placeholder="Choose Emirate / Region"
                value={region}
                style={{ marginBottom: 0 }}
              />
              <div className="arrow">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="6"
                  viewBox="0 0 12 6"
                  fill="none"
                >
                  <path d="M6 6L0 0H12L6 6Z" fill="black" />
                </svg>
              </div>
            </div>

            {this.state.openRegion && (
              <ol className="select-opt">
                {countriesOptions.map((code) => (
                  <li
                    key={code.value}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        formData: {
                          ...this.state.formData,
                          region: code.label,
                          regionValue: code.value,
                        },
                        openRegion: !this.state.openRegion,
                      });
                    }}
                  >
                    <span style={{ marginLeft: 5, cursor: 'pointer' }}>{code.label}</span>
                  </li>
                ))}
              </ol>
            )}
            <span className="validation-message">{this.state.errors.region}</span>
          </li>
          <li className="remark">
            <label htmlFor="">Remarks</label>
            <textarea
              name="message"
              id=""
              maxLength={600}
              placeholder="Write your remarks here"
              value={message}
              onChange={this.handleChange}
            ></textarea>
            <span className="validation-message"></span>
          </li>

          <li>
            <button type="submit"> SUBMIT</button>
          </li>
        </ul>
      </form>
    );
  }
}

export default withTranslation()(LeaseForm);

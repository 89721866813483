import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { animateScroll as scroll } from 'react-scroll';
import image from '../assets/images/DollarExpress1.png';
import keyIn from '../assets/images/key-50.png';
import hassleFreeOnboard from '../assets/images/onboarding-50.png';
import quickReservation from '../assets/images/reservation-50.png';
import counterService from '../assets/images/service-50.png';
import ListingFooter from '../Common/ListingFooter';
import ListingHeader from '../Common/ListingHeader';
import ContactUsBox from './ContactUsBox';
class DollarXpress extends Component {
  componentDidMount = () => {
    scroll.scrollToTop();
  };

  render() {
    const { t } = this.props;
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Choose DollarXpress for a Quick and Hassle-Free Car Rental in UAE </title>
          <link rel="canonical" href="https://www.dollaruae.com/dollar-xpress" />
          <meta
            name="description"
            content="Skip the queues and get on the road faster with DollarXpress. Enjoy a hassle-free experience and priority, best-in-class service across UAE."
          ></meta>
          <meta
            property="og:title"
            content="Choose DollarXpress for a Quick and Hassle-Free Car Rental in UAE "
          ></meta>
          <meta property="og:site_name" content="Dollar Car Rental"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/"></meta>
          <meta
            property="og:description"
            content="Skip the queues and get on the road faster with DollarXpress. Enjoy a hassle-free experience and priority, best-in-class service across UAE.."
          ></meta>
          <meta property="og:type" content="business.business"></meta>
          <meta
            property="og:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
          <meta
            name="title"
            content="Choose DollarXpress for a Quick and Hassle-Free Car Rental in UAE "
          ></meta>
          <meta
            name="description"
            content="Skip the queues and get on the road faster with DollarXpress. Enjoy a hassle-free experience and priority, best-in-class service across UAE.."
          ></meta>
          <meta property="og:type" content="website"></meta>
          <meta property="og:url" content="https://www.dollaruae.com/dollar-xpress"></meta>
          <meta
            property="og:title"
            content="Choose DollarXpress for a Quick and Hassle-Free Car Rental in UAE "
          ></meta>
          <meta
            property="og:description"
            content="Skip the queues and get on the road faster with DollarXpress. Enjoy a hassle-free experience and priority, best-in-class service across UAE.."
          ></meta>
          <meta
            property="og:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta property="twitter:url" content="https://www.dollaruae.com/dollar-xpress"></meta>
          <meta
            property="twitter:title"
            content="Choose DollarXpress for a Quick and Hassle-Free Car Rental in UAE "
          ></meta>
          <meta
            property="twitter:description"
            content="Skip the queues and get on the road faster with DollarXpress. Enjoy a hassle-free experience and priority, best-in-class service across UAE.."
          ></meta>
          <meta
            property="twitter:image"
            content="https://admin.dollaruae.com/DocumentPath/1e640ff6-b4f6-4db4-ae8f-831b6f9768e4/specialoffer/Dollar_SpecialThumbnailAttachmentbdac1a6e-3623-4ce3-86ee-08d93356c2d1.jpeg"
          ></meta>
        </Helmet>
        <ListingHeader />
        <div className="minH100 bg-grey">
          <section className="box-shadow-white-box-section grey-bg py-2" id="partner">
            <div className="container-fluid">
              <div className="box-shadow-white-box-a ">
                <div className="">
                  <div className="row flex-reversea">
                    <div className="col-sm-12 col-lg-6 col-md-12 col-12">
                      <div className="pickup-location-box">
                        <div className="pickup-location-flex">
                          <div className="pickup-location-flex-content">
                            <h1 className="border-botom">{t('DollarXpress Services.1')}</h1>
                            <h2 className="border-botom">
                              {t('Enjoy speedy reservations, pick-up and returns.1')}
                            </h2>
                            <p>{t('Skip the queues and get on the road faster.1')}</p>
                            <p className="text_p mt-4">
                              {t(
                                'The next time you find yourself at one of Dollar UAE counters, skip the queues and get on the road faster with the DollarXpress services.1',
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-lg-6  col-md-12 col-12">
                      <div className="top-margin-fifty text-center">
                        <div className="pr-lg-5 px-4">
                          <img src={image} alt="Dollar Xpress" className="img-fluid rounded-xl" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="text-center">
                      <h5 className="font-weight-bold">{t('DollarXpress Services.1')}</h5>
                      <p className="text_p mt-3">
                        {' '}
                        {t(
                          'Contact us to know more or better, visit any of our neighborhood locations to experience one today.1',
                        )}
                      </p>
                    </div>
                    <div className="express-box-wrap express-row div-text-center-align">
                      <div className="categories-box-col">
                        <div className="categories-box-pink">
                          <div className="express-box-icon rounded-xl">
                            <img src={counterService} alt="Priority Counter Service" />
                          </div>
                          <div className="categories-box-content">
                            <h6>{t('PRIORITY COUNTER SERVICE.1')}</h6>
                            <p>{t('Skip the queues and get on the road faster.1')}</p>
                          </div>
                        </div>
                      </div>
                      <div className="categories-box-col">
                        <div className="categories-box-pink">
                          <div className="express-box-icon rounded-xl">
                            <img src={quickReservation} alt="Quick Reservation" />
                          </div>
                          <div className="categories-box-content">
                            <h6>{t('QUICKER RESERVATIONS.1')}</h6>
                            <p>{t('Enjoy speedy reservations, pick-up and returns.1')}</p>
                          </div>
                        </div>
                      </div>
                      <div className="categories-box-col">
                        <div className="categories-box-pink">
                          <div className="express-box-icon rounded-xl">
                            <img src={hassleFreeOnboard} alt="Hassle Free Onboard" />
                          </div>
                          <div className="categories-box-content">
                            <h6>{t('HASSLE-FREE ONBOARDING.1')}</h6>
                            <p>{t('One-time verifications for all future rental activities.1')}</p>
                          </div>
                        </div>
                      </div>

                      <div className="categories-box-col">
                        <div className="categories-box-pink">
                          <div className="express-box-icon rounded-xl">
                            <img src={keyIn} alt="Key In and Go" />
                          </div>
                          <div className="categories-box-content">
                            <h6>{t('KEY IN & GO.1')}</h6>
                            <p>{t('Best car reserved for you or choose your own.1')}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <ContactUsBox
            subHeading={t(
              'Visit any of our neighborhood locations to experience Dollar services today.1',
            )}
            heading={t(
              'This service is currently available only in Dubai. Contact us to know more.1',
            )}
          ></ContactUsBox>
        </div>
        <ListingFooter />
      </>
    );
  }
}

export default withTranslation()(DollarXpress);
